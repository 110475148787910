import { post } from 'request'
import ticket_class from 'ticket_class'
import addition_class from 'addition_class'

class order_class {
  constructor(
    first_name = null,
    surname = null,
    email = null,
    adress = null,
    postal_code = null,
    ort = null,
    phone = null,
    swish_first_name = null,
    swish_surname = null,
    swish_phone_number = null,
    tagluffa_order_number = null,
    eurail_order_number = null,
    eurail_order_id = null,
    eurail_booking = null,
    shopping_cart_id = null
  ) {
    this.first_name = first_name
    this.surname = surname
    this.email = email
    this.adress = adress
    this.postal_code = postal_code
    this.ort = ort
    this.phone = phone
    this.swish_first_name = swish_first_name
    this.swish_surname = swish_surname
    this.swish_phone_number = swish_phone_number
    this.approved_conditions = false
    this.tagluffa_order_number = tagluffa_order_number
    this.eurail_order_number = eurail_order_number // The ordernumber used to retrieve orders and information from Eurail
    this.eurail_order_id = eurail_order_id // Not used
    this.eurail_booking = eurail_booking
    this.shopping_cart_id = shopping_cart_id
    this.order = []
  }

  get_first_name() {
    return this.first_name
  }

  get_surname() {
    return this.surname
  }

  get_email() {
    return this.email
  }

  get_adress() {
    return this.adress
  }

  get_postal_code() {
    return this.postal_code
  }

  get_ort() {
    return this.ort
  }

  get_phone() {
    return this.phone
  }

  get_swish_first_name() {
    return this.swish_first_name
  }

  get_swish_surname() {
    return this.swish_surname
  }

  get_swish_phone_number() {
    return this.swish_phone_number
  }

  get_approved_conditions() {
    return this.approved_conditions
  }

  get_tagluffa_order_number() {
    return this.tagluffa_order_number
  }

  set_tagluffa_order_number(x) {
    this.tagluffa_order_number = x
  }

  get_eurail_order_number() {
    return this.eurail_order_number
  }

  set_eurail_order_number(x) {
    this.eurail_order_number = x
  }

  get_eurail_order_id() {
    return this.eurail_order_id
  }

  set_eurail_order_id(x) {
    this.eurail_order_id = x
  }

  get_eurail_booking() {
    return this.eurail_booking
  }

  set_eurail_booking(x) {
    this.eurail_booking = x
  }

  set_shopping_cart_id(x) {
    this.shopping_cart_id = x
  }

  get_shopping_cart_id() {
    return this.shopping_cart_id
  }

  set_ticket_status(x) {
    this.order.forEach((element) => {
      element.set_status(x)
    })
  }

  is_order_invalid() {
    if (!this.first_name) {
      return 'Förnamn saknas'
    }
    if (!this.surname) {
      return 'Efternamn saknas'
    }
    if (!this.email) {
      return 'Mailadress saknas'
    }
    if (!this.adress) {
      return 'Adress saknas'
    }
    if (!this.postal_code) {
      return 'Postnummer saknas'
    }
    if (!this.ort) {
      return 'Ort saknas'
    }
    if (!this.phone) {
      return 'Telefonnummer saknas'
    }
    if (!this.swish_first_name) {
      return 'Förnamn till swishnummer saknas'
    }
    if (!this.swish_surname) {
      return 'Efternamn till swishnummer saknas'
    }
    if (!this.swish_phone_number) {
      return 'Swishnummer saknas'
    }
    if (!this.approved_conditions) {
      return 'Villkor har inte godkänts'
    }
    if (this.order === []) {
      //FIX THIS ITS FOR DEBUG ONLY
      return 'Order är tom'
    } else {
      var passengers = {
        CHD: 0,
        YTH: 0,
        ADT: 0,
        SEN: 0,
      }
      this.order.forEach((element) => {
        if (typeof element.is_ticket_valid === 'function') {
          if (
            element.get_status() != 'refunded' &&
            element.get_status() != 'reimbursed'
          ) {
            passengers[element.get_age()] = passengers[element.get_age()] + 1
          }
          if (!element.is_ticket_valid()) {
            return 'Alla biljetter är inte godkända'
          }
        }
      })
      if (passengers.CHD > 0) {
        if (passengers.ADT == 0) {
          return 'För få vuxna sett till antalet barn'
        } else if (passengers.CHD / passengers.ADT > 2) {
          return 'För få vuxna sett till antalet barn'
        }
      }
    }
    return false
  }

  what_is_missing() {
    var order_info =
      'Ordern number: ' +
      this.eurail_order_id +
      '\n' +
      'First name: ' +
      this.first_name +
      '\n' +
      'Surname: ' +
      this.surname +
      '\n' +
      'Email: ' +
      this.email +
      '\n' +
      'Adress: ' +
      this.adress +
      '\n' +
      'Postal Code: ' +
      this.postal_code +
      '\n' +
      'County: ' +
      this.ort +
      '\n' +
      'Phone: ' +
      this.phone +
      '\n' +
      'Swish first name: ' +
      this.swish_first_name +
      '\n' +
      'Swish Surname: ' +
      this.swish_surname +
      '\n' +
      'Swish phone number: ' +
      this.swish_phone_number +
      '\n' +
      'Approved conditions: ' +
      this.approved_conditions

    if (this.order !== []) {
      this.order.forEach(function (element, i) {
        order_info += '\n' + 'TICKET ' + i + '\n' + element.what_is_missing()
      })
    }

    return order_info
  }

  get_total_price() {
    var total = 0
    this.order.forEach((element) => {
      if (
        element.get_status() != 'refunded' &&
        element.get_status() != 'reimbursed'
      ) {
        total += element.get_price()
      }
    })
    return total
  }

  get_total_VAT() {
    var total = 0
    this.order.forEach((element) => {
      if (
        element.get_status() != 'refunded' &&
        element.get_status() != 'reimbursed'
      ) {
        total += element.get_VAT()
      }
    })
    return total
  }

  fromJSON(obj) {
    this.first_name = obj.first_name
    this.surname = obj.surname
    this.email = obj.email
    this.adress = obj.adress
    this.postal_code = obj.postal_code
    this.ort = obj.ort
    this.phone = obj.phone
    this.swish_first_name = obj.swish_first_name
    this.swish_surname = obj.swish_surname
    this.swish_phone_number = obj.swish_phone_number
    this.approved_conditions = obj.approved_conditions
    this.tagluffa_order_number = obj.tagluffa_order_number
    this.eurail_order_number = obj.eurail_order_number
    this.eurail_order_id = obj.eurail_order_id
    this.eurail_booking = obj.eurail_booking
    this.shopping_cart_id = obj.shopping_cart_id

    obj.order.forEach((element) => {
      let item = null
      if (element.type == 'ticket') {
        item = new ticket_class()
        item.fromJSON(element)
      } else if (element.type == 'addition') {
        item = new addition_class()
        item.fromJSON(element)
      }

      if (item) {
        this.order.push(item)
      }
    })
  }

  get_ticket(ticketId) {
    let res = null
    for (let el of this.order) {
      if (el.id === ticketId) {
        res = el
        break
      }
    }
    return res
  }
}

export default order_class

//module.exports = order_class;
