import React from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import { styles } from "styles";
import Fader from "./effects/Fader";

function DeliveryInfo(props) {
  const classes = styles();

  const [value, setValue] = React.useState("fast");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Fader in={true}>
      <FormControl component="fieldset" fullWidth={true}>
        <RadioGroup
          aria-label="delivery"
          name="delivery1"
          value={value}
          onChange={handleChange}
        >
          <Grid container>
            <Grid item>
              <FormControlLabel
                value="fast"
                control={<Radio />}
                label="1-3 dagar"
              />
            </Grid>
            <Grid item>
              <Typography className={classes.deliveryText} variant="overline">
                SEK 149
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <FormControlLabel
                value="slow"
                control={<Radio />}
                label="2-5 dagar"
              />
            </Grid>
            <Grid item>
              <Typography className={classes.deliveryText} variant="overline">
                SEK 0
              </Typography>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </Fader>
  );
}

export default DeliveryInfo;
