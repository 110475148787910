import React from 'react'
import { styles } from 'styles'
import { Button, Grid, Box } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'

function NextBackButtons(props) {
  const classes = styles()
  const history = useHistory()

  function handleNextClick() {
    if (props.gtag) {
      props.gtag('event', props.gtag_event)
    }
    if (props.checkerFunktion) {
      if (props.checkerFunktion()) {
        history.push(props.nextPath)
      }
    } else {
      history.push(props.nextPath)
    }
  }

  return (
    <Grid container alignItems="stretch" className={classes.nextBackButtons}>
      <Box boxShadow={3} className={classes.boxForShadow}>
        <Grid container alignItems="center" className={classes.nextBackButtons}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item>
              {!props.removeBack ? (
                <Button
                  disabled={props.disableBack}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={props.backPath}
                  className={classes.button}
                >
                  Tillbaka
                </Button>
              ) : (
                <div></div>
              )}
            </Grid>
            <Grid item>
              {!props.removeNext ? (
                <Button
                  disabled={props.disableNext}
                  variant="contained"
                  color="primary"
                  onClick={() => handleNextClick()}
                  className={classes.button}
                >
                  Nästa steg
                </Button>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default NextBackButtons
