const PackingObject = [
	{
		title: "Resehandlingar",
		items: [
			{
				item: "Bankkort",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "EU-kort från Försäkringskassan",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Reseförsäkringshandlingar",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hotellbokningar",
				tags: {
					enviornment: ["mix", "landsbygd", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hyrbilsbokningar",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Offlinekarta",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Pass",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Utländsk valuta",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Hygienartiklar",
		items: [
			{
				item: "Deoderant",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Parfym",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Handduk",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hårstyling",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Kam/Borste",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Lypsyl",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hudkräm",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Nagelsax",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Pincett",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Rakningstillbehör",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Schampo & Tvål",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Smink",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Solskydd",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tandborste",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tandkräm",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tandtråd",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Toalettpapper",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Våtservetter",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Handsprit",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Underhållning",
		items: [
			{
				item: "Böcker",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Kortlek",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Korsordstidning",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hörlurar (gärna med brusreducering)",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Elektronik",
		items: [
			{
				item: "Reseadapter",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tejp, förslagsvis silvertejp",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Mobiltelefon",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Mobilladdare",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Powerbank",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", null],
				},
			},
		],
	},
	{
		title: "Kläder",
		items: [
			{
				item: "Underkläder",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "T-shirts",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Shorts",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Badkläder",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tjocktröja",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "En findress",
				tags: {
					enviornment: ["mix", "storstad", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Långbyxor",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Lämpliga skor",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Vandringskängor",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Sandaler",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Regnkläder",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	{
		title: "Övrigt",
		items: [
			{
				item: "Adresslista",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Glasögon",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Klocka",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Kniv",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["3", null],
					stopDuration: ["long", null],
				},
			},
			{
				item: "Korkskruv & Kapsylöppnare",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Liten ryggsäck",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Värktabletter",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Första hjälpen kit",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Allergimedicin",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Multiverktyg",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["long", null],
				},
			},
			{
				item: "Myggmedel",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Nål & tråd",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Papper & penna",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Påsar i olika storlekar",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Reseguider",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Skavsårsplåster",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tofs",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tändare/tändstickor",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Vattenflaska",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Vattentät säck/påse",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Hänglås",
				tags: {
					enviornment: ["mix", "storstad", "landsbygd", null],
					duration: ["1", "2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tvättlina",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
			{
				item: "Tvättmedel",
				tags: {
					enviornment: ["mix", "landsbygd", null],
					duration: ["2", "3", null],
					stopDuration: ["short", "long", null],
				},
			},
		],
	},
	,
];

export default PackingObject;
