import React from "react";
import calculateAge from "./calculateAge";

const xml2js = require("xml2js");

////////////////////////////////////////////////////////////////////
// Function takes RailPassAvailRS json struct as input parameters
// The input information is transformed into a RailPassBookRQ json struct which is returned
////////////////////////////////////////////////////////////////////

export default function refineRailBookAvailResponse(
	response,
	order,
	shopping_cart,
	find_item_in_shopping_cart,
	RailPassAvailRQ
) {
	var builder = new xml2js.Builder();
	var jsonResponse;

	//Initializing testing variables to be changed for the state values of the InterrailPass properties of interest
	var comfort = RailPassAvailRQ.comfort;
	var duration = RailPassAvailRQ.duration;
	var passModel = RailPassAvailRQ.passModel;
	var IDs = RailPassAvailRQ.IDs;

	//Shell to add CountryList, Fare & PassOption to apart from all passanger information.
	var RailPassBookRQ = {
		ACP_RailBookRQ: {
			$: {
				xmlns: "http://www.acprailinternational.com/API/R2",
				PrimaryLangID: "en",
				SequenceNmbr: "1",
				Version: "1.002",
				EchoToken: "7656",
			},
			POS: {
				RequestorID: "", //Added in backend
			},
			RailBookInfo: {
				$: {
					TicketQueue: "",
					ContactEmail: "kundservice@tagluffaieuropa.se", // perhaps add another email account for this
					POSReference: "",
				},
				SelectedOptions: {
					SelectedOption: {
						$: {
							TicketOption: "Paper",
							IsCreditSale: "true",
							IsSubcomponent: "false",
							ID: "1", //Update in order to be unique.
						},
						PassFare: {},
						PassOption: {},
						PassengerIndex: {
							Passenger: [],
						},
						PaymentIndex: [""],
						CountryLists: {},
					},
				},
				Payments: [""],
				Passengers: { Passenger: [] },
				Remarks: [""],
			},
		},
	};

	//Passenger previously in Passengers:
	/*Passenger: {
						$: {
							IsLeader: "false", //Main passanger (to contact if problems occur). Not sure if it's mandatory. (Make sure to set the first one as this) Frederic will check.
							PhoneNumber: order.get_phone(),
							Email: order.get_email(),
							YearOfBirth: order.get_personal_number().slice(0, 4),
							MonthOfBirth: order.get_personal_number().slice(5, 7),
							DayOfBirth: order.get_personal_number().slice(8, 10),
							PassportNumber: "passport number",
							PlaceOfBirthCountry: "CA",
							PlaceOfBirthCity: "oqwdqwopk",
							Nationality: "CA",
							CountryResidence: "HR",
							Age: "-1",
							Surname: order.get_surname(),
							GivenName: order.get_first_name(),
							NamePrefix: "", //Remove if possible
							ID: "1", //Supposed to correspond to the SelectedOptionID
						},
					},*/

	var passenger_template = {
		$: {
			IsLeader: "false", //Main passanger (to contact if problems occur). Not sure if it's mandatory. (Make sure to set the first one as this) Frederic will check.
			PhoneNumber: "",
			Email: "",
			YearOfBirth: "",
			MonthOfBirth: "",
			DayOfBirth: "",
			PassportNumber: "",
			PlaceOfBirthCountry: "",
			PlaceOfBirthCity: "",
			Nationality: "",
			CountryResidence: "",
			Age: "",
			Surname: "",
			GivenName: "",
			NamePrefix: "", //Remove if possible
			ID: "", //Supposed to correspond to the SelectedOptionID
		},
	};

	var passenger_index_template = {
		$: {
			SliceID: "",
			PassengerID: "",
		},
	};

	//Modify shell of RailBookRQ

	//Add PassOption
	var faresOfDesiredPassModel = [];
	for (
		var i = 0;
		i < response.ACP_RailPassAvailRS.PassOptions[0].PassOption.length;
		i++
	) {
		if (
			response.ACP_RailPassAvailRS.PassOptions[0].PassOption[i].$.PassModel ==
			passModel
		) {
			RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.SelectedOptions.SelectedOption.PassOption =
				response.ACP_RailPassAvailRS.PassOptions[0].PassOption[i];
			faresOfDesiredPassModel =
				response.ACP_RailPassAvailRS.PassOptions[0].PassOption[i].FareRPHs[0]
					.FareRPH;
			// Indexation in FareRPH is 1 above ticket indexation. Therefore we subtract every number in the "faresOfDesiredPassModel" array.
			faresOfDesiredPassModel = faresOfDesiredPassModel.map(function (value) {
				return value - 1;
			});
		}
	}

	//Add PassFare of the desired attributes
	var index;
	for (var i in faresOfDesiredPassModel) {
		index = faresOfDesiredPassModel[i];
		if (
			response.ACP_RailPassAvailRS.Fares[0].Fare[index].$.Class == comfort &&
			response.ACP_RailPassAvailRS.Fares[0].Fare[index].Validity[0].Duration[0]
				.$.Length == duration
		) {
			RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.SelectedOptions.SelectedOption.PassFare =
				response.ACP_RailPassAvailRS.Fares[0].Fare[index];
			// Modify Magic parameter to remove enter signs
			/*
			var magic =
				RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.SelectedOptions
					.SelectedOption.PassFare.$.Magic;
			console.log("magic string:");
			console.log(magic);
			var newMagic = magic.replace(/[^A-Za-z0-9]/g, "");
			console.log(newMagic);
			RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.SelectedOptions.SelectedOption.PassFare.$.Magic = newMagic;*/

			//Break loop when desired ticket has been found
			break;
		}
	}

	//Add passengers
	// Check what passenger IDs to add from the interrailAvailRQ
	// And then add all passanger information and appropriate ID
	for (var i = 0; i < IDs.length; i++) {
		var new_passenger = JSON.parse(JSON.stringify(passenger_template));
		var current_passenger = shopping_cart[find_item_in_shopping_cart(IDs[i])];
		var passenger_age = calculateAge(current_passenger);
		new_passenger.$.YearOfBirth = current_passenger.date_of_birth.getFullYear();
		new_passenger.$.MonthOfBirth =
			current_passenger.date_of_birth.getMonth() + 1;
		new_passenger.$.DayOfBirth = current_passenger.date_of_birth.getDate();
		new_passenger.$.PassportNumber = current_passenger.get_passport_number();
		new_passenger.$.Nationality = current_passenger.get_nationality();
		new_passenger.$.CountryResidence = current_passenger.get_nationality();
		new_passenger.$.Age = passenger_age; //current_passenger.get_age();
		new_passenger.$.Surname = current_passenger.get_surname();
		new_passenger.$.GivenName = current_passenger.get_first_name();
		new_passenger.$.ID = current_passenger.get_id();
		RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.Passengers.Passenger.push(
			new_passenger
		);

		var new_passenger_index = JSON.parse(
			JSON.stringify(passenger_index_template)
		);
		new_passenger_index.$.PassengerID = current_passenger.get_id();
		var passenger_mix =
			RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.SelectedOptions.SelectedOption
				.PassFare.IntegratedPassengerTypePrices[0].PassengerMixSlice;
		for (var j = 0; j < passenger_mix.length; j++) {
			if (passenger_mix[j].$.Code == current_passenger.get_age()) {
				new_passenger_index.$.SliceID = passenger_mix[j].$.SliceID;
				break;
			}
		}
		RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.SelectedOptions.SelectedOption.PassengerIndex.Passenger.push(
			new_passenger_index
		);
	}

	//Add CountryList
	RailPassBookRQ.ACP_RailBookRQ.RailBookInfo.SelectedOptions.SelectedOption.CountryLists =
		response.ACP_RailPassAvailRS.CountryLists;

	//Done with adding tickets

	try {
		var xmlAgain = builder.buildObject(response);
	} catch (error) {
		console.log("Received error:" + error);
	}
	//console.log(xmlAgain);

	xml2js.parseString(xmlAgain, function (err, result) {
		jsonResponse = result;
	});
	//console.log(jsonResponse);

	return RailPassBookRQ;
}
