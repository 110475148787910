import React from 'react'
import {
  Card,
  Typography,
  CardMedia,
  CardContent,
  Collapse,
  TextField,
  MenuItem,
  Grid,
  Button,
  IconButton,
  Modal,
  Box,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import WarningIcon from '@material-ui/icons/Warning'
import CloseIcon from '@material-ui/icons/Close'
import ticket_class from '../ticket_class'
import Fader from './effects/Fader'

import { styles } from 'styles'

function Ticket(props) {
  const ages = [
    {
      value: 'CHD',
      label: 'Barn 0-11 år',
    },
    {
      value: 'YTH',
      label: 'Ungdom 12-27 år',
    },
    {
      value: 'ADT',
      label: 'Vuxen 28-59 år',
    },
    {
      value: 'SEN',
      label: 'Senior 60+ år',
    },
  ]

  const [age, setAge] = React.useState('')

  const comforts = [
    {
      value: '1stclass',
      label: 'Första klass',
    },
    {
      value: '2ndclass',
      label: 'Andra klass',
    },
  ]

  const [comfort, setComfort] = React.useState('')

  function set_price() {
    if (!age && !comfort) {
      return props.ticket.price['ADT']['2ndclass']['price'] //["STANDARD"];
    }
    if (!age && comfort) {
      return props.ticket.price['ADT'][comfort]['price']
    }
    if (age && !comfort) {
      return props.ticket.price[age]['2ndclass']['price'] //["STANDARD"];
    }
    if (age && comfort) {
      return props.ticket.price[age][comfort]['price']
    }
  }

  function get_additional_prices() {
    if (!age && !comfort) {
      return [
        props.ticket.price['ADT']['2ndclass']['EURprice'],
        props.ticket.price['ADT']['2ndclass']['VAT'],
      ]
    }
    if (!age && comfort) {
      return [
        props.ticket.price['ADT'][comfort]['EURprice'],
        props.ticket.price['ADT'][comfort]['VAT'],
      ]
    }
    if (age && !comfort) {
      return [
        props.ticket.price[age]['2ndclass']['EURprice'],
        props.ticket.price[age]['2ndclass']['VAT'],
      ]
    }
    if (age && comfort) {
      return [
        props.ticket.price[age][comfort]['EURprice'],
        props.ticket.price[age][comfort]['VAT'],
      ]
    }
  }

  function get_sku() {
    if (!age && !comfort) {
      return props.ticket.price['ADT']['2ndclass']['sku'] //["STANDARD"];
    }
    if (!age && comfort) {
      return props.ticket.price['ADT'][comfort]['sku']
    }
    if (age && !comfort) {
      return props.ticket.price[age]['2ndclass']['sku'] //["STANDARD"];
    }
    if (age && comfort) {
      return props.ticket.price[age][comfort]['sku']
    }
  }

  function check_choises() {
    if (age && comfort) {
      return true
    } else {
      return false
    }
  }

  function handle_ticket_booking() {
    var price = set_price()
    var [EURprice, VAT] = get_additional_prices()
    var sku = get_sku()
    var tkt = new ticket_class(
      props.current_new_ticket_index,
      props.ticket.name,
      age,
      comfort,
      price,
      EURprice,
      VAT,
      props.ticket.duration,
      props.ticket.ACPPassModel,
      sku,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      props.ticket.promotion ? price : null
    )
    console.log(tkt)
    props.add_to_shopping_cart(tkt)
    props.modify_ticket_index(props.current_new_ticket_index + 1)
    setTimeout(function () {
      props.handleCollapse(props.index)
    }, 200)
  }

  const [picLoaded, setpicLoaded] = React.useState(false)

  const [modalOpen, setModalOpen] = React.useState(false)

  const daysInMonthText = (travelDays, traveltime) =>
    `Denna biljett ger dig tillgång till ${travelDays} resdagar att använda
      under ${traveltime}s tid. Under en resdag kan du åka med hur många tåg du vill
      och resedagarna går att aktivera när du vill under ${traveltime} från ditt
      första resedatum. \n Perfekt när du vill besöka ${
        travelDays - 2
      } - ${travelDays} destinationer.`

  const daysConsecutiveText = (travelDays) =>
    `Denna biljett ger dig tillgång till ${travelDays} att använda i följd från första resedatum. Du kan åka med hur många tåg du vill under dessa dagar. \n Perfekt när du vill resa mycket under en kortare tid eller bara ha full frihet i ditt resande.`

  const activationText = () =>
    'Passet aktiveras i RailPlanner appen. Kunddetaljer anges i nästa steg. Ha en trevlig resa!'

  const descriptions = {
    '4 dagar på 1 månad': (
      <div>
        {daysInMonthText(4, '1 månad')}
        <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '5 dagar på 1 månad': (
      <div>
        {daysInMonthText(5, '1 månad')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '7 dagar på 1 månad': (
      <div>
        {daysInMonthText(7, '1 månad')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '10 dagar på 2 månader': (
      <div>
        {daysInMonthText(10, '2 månader')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '15 dagar på 2 månader': (
      <div>
        {daysInMonthText(15, '2 månader')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '15 dagar i följd': (
      <div>
        {daysConsecutiveText('15 resdagar')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '22 dagar i följd': (
      <div>
        {daysConsecutiveText('22 resdagar')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '1 månad i följd': (
      <div>
        {daysConsecutiveText('1 månads resdagar')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '2 månad i följd': (
      <div>
        {daysConsecutiveText('2 månader resdagar')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
    '3 månad i följd': (
      <div>
        {daysConsecutiveText('3 månader resdagar')} <br></br>
        <br></br>
        {activationText()}
      </div>
    ),
  }

  const classes = styles()
  return (
    <Fader in={picLoaded}>
      <div style={{ width: '100%' }}>
        <IconButton
          style={{
            position: 'absolute',
            top: '4px',
            right: '4px',
            zIndex: '1',
          }}
          onClick={(event) => {
            setModalOpen(true)
          }}
        >
          <HelpIcon style={{ color: 'white' }} />
        </IconButton>
        <Card
          className={classes.ticket}
          onClick={(event) => {
            if (!props.expanded) {
              props.handleExpansion(props.index)
            }
          }}
        >
          <Collapse
            in={!props.expanded}
            timeout="auto"
            collapsedHeight="60px"
            onClick={(event) => {
              if (props.expanded) {
                props.handleCollapse(props.index)
              }
            }}
          >
            <div style={{ position: 'relative', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                alt="Bussiness in action"
                height={190}
                className={classes.ticketImg}
                image={require('../' + props.ticket.picPath)}
                title="Bussiness in action"
                onLoad={() => setpicLoaded(true)}
              ></CardMedia>
              {props.ticket.promotion ? (
                <>
                  <Typography className={classes.SaleBanner}>
                    <div style={{ fontSize: '1.5rem' }}> RABATTERAD</div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '1rem',
                      }}
                    >
                      <WarningIcon />
                      <div>
                        Första resedag tidigast <b>1/9/24</b>
                      </div>
                    </div>
                  </Typography>
                </>
              ) : (
                <div></div>
              )}
            </div>
          </Collapse>
          <Typography className={classes.ticketTitle} component="p">
            {props.ticket.name}
          </Typography>
          <Collapse in={props.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="filled-select-age"
                    select
                    label={'Åldersgrupp'}
                    value={age}
                    onChange={(event) => setAge(event.target.value)}
                    variant="filled"
                    fullWidth
                    style={{ textAlign: 'start' }}
                  >
                    {ages.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="filled-select-comfort"
                    select
                    label={'Comfort'}
                    value={comfort}
                    onChange={(event) => setComfort(event.target.value)}
                    variant="filled"
                    fullWidth
                  >
                    {comforts.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        align={'left'}
                      >
                        {set_price() + ' KR'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        onClick={() => handle_ticket_booking()}
                        disabled={!check_choises()}
                        style={{ textAlign: 'center' }}
                      >
                        Lägg i korg
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
          <Collapse in={!props.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="caption" component="h1" align={'left'}>
                PRIS FRÅN:
              </Typography>
              <Typography variant="h5" component="p" align={'left'}>
                {props.ticket.price['YTH']['2ndclass']['price'] + ' KR'}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{ width: 400 }}
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              left: '0',
              right: '0',
              top: '0',
              bottom: '0',
              margin: 'auto',
              height: '400px',
              padding: '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '1rem',
                borderBottom: '1px solid grey',
              }}
            >
              <h2 id="parent-modal-title" style={{ margin: '0' }}>
                {props.ticket.name}
              </h2>
              <IconButton onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <p id="parent-modal-description">
              {descriptions[props.ticket.name]}
            </p>
          </Box>
        </Modal>
      </div>
    </Fader>
  )
}

export default Ticket
