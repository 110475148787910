import { Grid, Typography, Button, Box, Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { styles } from 'styles'
import React, { useEffect } from 'react'

function ReturnConfirmation(props) {
  const classes = styles()
  return (
    <div style={{ minHeight: '100vh' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Hidden smUp>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Typography variant="h6">
                  <Box fontWeight="fontWeightLight">{props.activeStep}</Box>
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography align={'center'} variant="h5">
                Din retur är bekräftad!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'} variant="h1">
                <CheckCircleIcon fontSize={'inherit'} color={'primary'} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'} variant="h5">
                Återbetalningsinformation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'}>
                Summan motsvarande de returnerade biljetterna kommer vara
                tillbaka, på samma konto som swish är kopplat till, inom några
                dagar. Återstår frågor, var vänlig eftersök dessa i vår FAQ
                eller kontakta kundtjänst.
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/"
              >
                Till startsidan
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
    </div>
  )
}

export default ReturnConfirmation
