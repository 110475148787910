export default async function priceChecker(
	response,
	RailPassAvailRQ,
	total_price_from_tlie
) {
	var faresOfDesiredPassModel = [];
	var fares = [];

	RailPassAvailRQ.forEach((element, index) => {
		var comfort = element.comfort;
		var duration = element.duration;
		var passModel = element.passModel;
		var IDs = element.IDs;
		var ResponseElement = response[index];

		for (
			var i = 0;
			i < ResponseElement.ACP_RailPassAvailRS.PassOptions[0].PassOption.length;
			i++
		) {
			if (
				ResponseElement.ACP_RailPassAvailRS.PassOptions[0].PassOption[i].$
					.PassModel == passModel
			) {
				faresOfDesiredPassModel =
					ResponseElement.ACP_RailPassAvailRS.PassOptions[0].PassOption[i]
						.FareRPHs[0].FareRPH;
				// Indexation in FareRPH is 1 above ticket indexation. Therefore we subtract every number in the "faresOfDesiredPassModel" array.
				faresOfDesiredPassModel = faresOfDesiredPassModel.map(function (value) {
					return value - 1;
				});
			}
		}

		//Add PassFare of the desired attributes
		var index;
		for (var i in faresOfDesiredPassModel) {
			index = faresOfDesiredPassModel[i];
			if (
				ResponseElement.ACP_RailPassAvailRS.Fares[0].Fare[index].$.Class ==
					comfort &&
				ResponseElement.ACP_RailPassAvailRS.Fares[0].Fare[index].Validity[0]
					.Duration[0].$.Length == duration
			) {
				fares.push(
					ResponseElement.ACP_RailPassAvailRS.Fares[0].Fare[index]
						.IntegratedTotalPrice[0].$.Amount
				);

				//Break loop when desired ticket has been found
				break;
			}
		}
	});

	var exchange_rate = await get_exchange_rate();
	var total_price_from_acp = 0;
	fares.forEach((element) => {
		total_price_from_acp += calculate_ticket_price(element, exchange_rate);
	});

	if (total_price_from_acp > total_price_from_tlie) {
		console.log("Acp prices are higher then ours! We are losing money");
		console.log("ACP price: " + total_price_from_acp);
		console.log("Tågluffaieuropa price: " + total_price_from_tlie);
		return false;
	} else {
		console.log("Acp prices are lower. All is fine!");
		console.log("ACP price: " + total_price_from_acp);
		console.log("Tågluffaieuropa price: " + total_price_from_tlie);
		return true;
	}
}

async function get_exchange_rate() {
	let valuta = await fetch(
		"http://api.exchangeratesapi.io/latest?access_key=d1adac716246511aa47e95ef84ccb208&symbols=SEK&base=EUR"
	).then((response) => {
		return response.json();
	});
	valuta = valuta.rates.SEK;
	return valuta;
}

function calculate_ticket_price(ACP_price, exchange_rate) {
	// Converting price from EUR to SEK and adding marginals
	let tagluffa_price = exchange_rate * ACP_price;
	//tagluffa_price = Math.ceil(tagluffa_price / 50) * 50 - 1; // Round up to closest 50 and make it 1 SEK cheaper.
	if (tagluffa_price < 0) {
		tagluffa_price = 0;
	}
	return tagluffa_price;
}
