import React from 'react'
import TableComponent from '../../Components/tableComponent'
import { Helmet } from 'react-helmet'

var paris_frankrike = {
  content: [
    {
      title: 'Storstaden Paris',
      subtitle: '',
      picPath: 'paris-eiffeltower.jpg',
      body: '',
    },
    /*{
      title: '',
      subtitle: '',
      picPath: '',
      body: (
        <TableComponent
          title={[
            'Eiffeltornets biljettpriser',
            'Vuxen',
            'Ungdom 12-24 år',
            'Barn 4-11',
            'Funktionsnedsatta',
            'Spädbarn 0-3 år',
          ]}
          listitems={[
            ['Hiss 2:a vån', '16,70 €', '8,40 €', '4,20 €', '4,20 €', 'Gratis'],
            [
              'Hiss till topp',
              '26,10 €',
              '13,10 €',
              '6,60 €',
              '6,60 €',
              'Gratis',
            ],
            [
              'Trappa 2:a vån',
              '10,50 €',
              '5,20 €',
              '2,60 €',
              '2,60 €',
              'Gratis',
            ],
            [
              'Trappa + hiss till topp',
              '19,90 €',
              '9,90 €',
              '5,00 €',
              '5,00 €',
              'Gratis',
            ],
          ]}
        />
      ),
    },*/
    {
      title: '',
      subtitle: '',
      picPath: '',
      body: (
        <>
          <p>
            Paris, staden av kärlek och ljus. Utforska dess ikoniska
            sevärdheter, njut av gourmetmat och upplev den romantiska atmosfären
            som endast Paris kan erbjuda. En resa som kommer fylla ditt hjärta
            och skapa minnen för livet.
          </p>
          <h2 style={{ marginLeft: '0px' }}>
            Matnyttig Information om Frankrike
          </h2>
          <p>
            <strong>Valuta & Betalning:</strong> Euro (EUR). Kort accepteras i
            de flesta affärer och restauranger, men det är alltid bra att ha
            lite kontanter för små inköp.
          </p>
          <p>
            <strong>Språk och Engelska:</strong> Franska är det officiella
            språket. Medan många i turistområden talar engelska, är det
            uppskattat att kunna några grundläggande fraser på franska. Säg
            "Bonjour" för hej, "Merci" för tack, och "Excusez-moi" för ursäkta
            mig.
          </p>
          <p>
            <strong>Kulturella Egenheter:</strong> Respektera lokal sed och
            vett. En lätt nigning eller skakning på huvudet anses artig. "S'il
            vous plaît" är snälla och "Merci" är tack.
          </p>
          <h2 style={{ marginLeft: '0px' }}>Introduktion till Staden</h2>
          <p>
            Paris, stadens stad, erbjuder en unik mix av konst, kultur och
            historia. Utforska dess olika stadsdelar och upplev det bästa av det
            franska arvet.
          </p>
          <p>
            <strong>Transport:</strong> <br />
            Ankomst via tågstationen Gare du Nord. Det effektiva tunnelbanenätet
            gör det enkelt att ta sig runt staden.
          </p>
          <p>
            <strong>Boende:</strong> <br />
            Från charmiga boutiquehotell i Marais till trendiga lägenheter i
            Saint-Germain, Paris har boendealternativ för alla smaker.
          </p>
          <h2 style={{ marginLeft: '0px' }}>Stadsdelar</h2>
          <p>
            <strong>Le Marais:</strong> <br />
            Känt för sina smala gränder och trendiga butiker. Perfekt för
            konstälskare och modeentusiaster.
          </p>
          <p>
            <strong>Saint-Germain-des-Prés:</strong> <br />
            En bohemisk stadsdel med kaféer, konstgallerier och antikaffärer.
            Idealisk för avslappnad promenad.
          </p>
          <p>
            <strong>Montmartre:</strong>
            <br />
            En bohemisk stadsdel med en rik konstnärlig historia. Kullens
            charmiga gränder och torg har lockat konstnärer som Picasso och Van
            Gogh. Här finner man också den ikoniska Basilique du Sacré-Cœur, en
            imponerande kyrka som dominerar stadsbilden och erbjuder
            panoramautsikt över Paris.
          </p>
        </>
      ),
    },
    {
      title: '',
      subtitle: '',
      picPath: 'champs_elysees.jpg',
      body: (
        <>
          <h2 style={{ marginLeft: '0px' }}>Sevärdheter</h2>
          <ul>
            <li>
              <strong>Eiffeltornet:</strong> <br />
              Paris mest ikoniska landmärke konstruerat av Gustave Eiffel.
              Tornet, över 300 meter högt, restes för att fira den franska
              revolutionens hundraårsjubileum under världsutställningen 1889. Ta
              en hiss till toppen för en hisnande utsikt över staden. För priser
              och biljetter, besök{' '}
              <a
                href="https://www.toureiffel.paris/en/rates-opening-times"
                target="_blank"
                rel="noopener noreferrer"
              >
                den officiella hemsidan för Eiffeltornet
              </a>
              .
            </li>
            <li>
              <strong>Louvren:</strong> <br />
              Ett konstmuseum av världsklass. Utforska Mona Lisa och andra
              konstverk från olika epoker. Detta är även platsen där delar av
              filmen ”Da Vinci-koden” var inspelad. Alltså lockar den utöver
              konstkännaren även filmfantasten, men förmodligen många fler.
              Passa på att åka tunnelbana hit eller från. Hållplatsen vid
              Louvren, går i museets anda och är förmodligen det mest
              välsmyckade i staden.
            </li>
            <li>
              <strong>Notre-Dame-katedralen:</strong> <br />
              En mästerverk av gotisk arkitektur och känt från inte minst
              Disneys uppskattade film ”Ringaren i Notre Dame”, var länge ett
              måste att besöka. Katedralen låg på en ö i Seine och var byggd i
              gotisk stil. Dessvärre drabbades byggnaden av en omfattande brand
              år 2019 och restaureras sedan dess. När väl katedralen öppnar upp
              igen vill vi rekommendera en visit till söndagsmässan, vilken är
              något riktigt spektakulärt.
            </li>
            <li>
              <strong>Triumfbågen:</strong>
              <br />
              Ett stor monument i form av en cirka 50 meter hög valvbåge i ena
              änden av Champs-Élysées. Den byggdes för att hedra Frankrikes armé
              och till minne av segern vid Austerlitz 1805, men nu finns där
              även ett monument känt som "den okände soldatens grav" vilken
              visar aktning för de alla stupade under första världskriget.
            </li>
          </ul>
          <h2 style={{ marginLeft: '0px' }}>Mat och Dryck</h2>
          <p>
            Frankrike är känt för sin gastronomi och delikata rätter. Utforska
            kulinariska läckerheter och njut av en smakresa i Paris.
          </p>
          <p>
            <strong>Fransk Kökets Delikatesser:</strong>
            <br />
            Fransk matkultur är känd för sin kvalitet och variation. Prova dessa
            klassiska rätter.
          </p>
          <ul>
            <li>
              <strong>Coq au Vin:</strong>
              <br /> En mustig gryta med kyckling, vin, och örter. En fransk
              delikatess.
            </li>
            <li>
              <strong>Ratatouille:</strong>
              <br /> En färgglad grönsaksgryta som representerar Provence i
              varje tugga.
            </li>
            <li>
              <strong>Crème Brûlée:</strong>
              <br /> En len och krämig vaniljkräm med ett karamelliserat
              sockeröverdrag.
            </li>
          </ul>
          I Frankrike är en buljong eller "Bouillon" en traditionell, rymlig
          restaurang som vanligtvis serverar traditionell fransk mat, i
          synnerhet en buljong, som har gett namnet för denna klass av
          restauranger. När det uppfanns var konceptet att servera mat av god
          kvalitet snabbt, till ett överkomligt pris och vi vill påstå att det
          bestått. Se till att besöka en buljong när du är i staden. Vi vill
          rekommendera "Chartier Bouillon”, inredd i ett gammalt apotek. Maten
          är billig, men framför allt god och klassiskt fransk.
          <p>
            <strong>Bageriets Sötsaker:</strong>
            <br />
            Avsluta din måltid med några av Frankrikes läckra bakverk och
            desserter.
          </p>
          <ul>
            <li>
              <strong>Macarons:</strong>
              <br /> Färgglada små kakor med krämig fyllning. En fransk favorit.
            </li>
            <li>
              <strong>Éclair:</strong>
              <br /> En lång, spröd bakelse fylld med kräm. Ett perfekt
              mellanmål.
            </li>
            <li>
              <strong>Tarte Tatin:</strong>
              <br /> En upp-och-ner-kaka med karamelliserade äpplen. Serveras
              oftast med en klick grädde.
            </li>
          </ul>
          <p>
            <strong>Vin och Ost:</strong>
            <br />
            Utforska de många vingårdarna och ostar som Frankrike har att
            erbjuda.
          </p>
          <ul>
            <li>
              <strong>Brie:</strong>
              <br /> En mild och krämig ost som passar utmärkt med färskt bröd.
            </li>
            <li>
              <strong>Chardonnay:</strong>
              <br /> En populär vitvinsort som kompletterar många franska
              rätter.
            </li>
            <li>
              <strong>Baguette och Croissant:</strong>
              <br /> Klassiskt franskt bröd som är oumbärligt vid varje måltid.
            </li>
          </ul>
          <h2 style={{ marginLeft: '0px' }}>Nattliv</h2>
          <p>
            Paris nattliv är lika varierat som dess dagliv. Från pittoreska
            vinbarer till trendiga nattklubbar, staden har något för varje smak.
            Utforska det pulserande nattlivet och dansa natten bort.
          </p>
          <p>
            <strong>Charmiga Vinbarer för En Avslappnad Kväll</strong>
          </p>
          <ul>
            <li>
              <strong>Le Baron Rouge:</strong>
              <br /> En autentisk vinbar med ett brett urval av franska viner.
            </li>
            <li>
              <strong>La Cremerie:</strong> <br />
              En intim vinbar i hjärtat av Saint-Germain-des-Prés.
            </li>
            <li>
              <strong>Chez Georges:</strong> <br />
              En klassisk parisisk bar som har behållit sin charm sedan 1954.
            </li>
          </ul>
          <p>
            <strong>Livliga Nattklubbar för de Som Vill Dansa</strong>
          </p>
          <ul>
            <li>
              <strong>Rex Club:</strong> <br />
              En av Paris äldsta och mest kända nattklubbar. Perfekt för
              elektronisk musik och dans.
            </li>
            <li>
              <strong>Le Titty Twister:</strong> <br />
              En ikonisk rockklubb med en eklektisk atmosfär och live-musik.
            </li>
            <li>
              <strong>Moulin Rouge:</strong> <br />
              För den ultimata kabaretupplevelsen i Paris.
            </li>
          </ul>
          <h2 style={{ marginLeft: '0px' }}>Upplevelser i Paris</h2>
          <p>
            Upptäck Paris skönhet bortom de berömda sevärdheterna. Nedan finns
            några unika upplevelser för att göra din vistelse i staden
            oförglömlig.
          </p>
          <ul>
            <li>
              <strong>Montmartre Konstvandring:</strong> <br />
              Utforska konstnärskvarteret Montmartre och besök kända platser som
              Place du Tertre.
            </li>
            <li>
              <strong>Seine River Night Cruise:</strong> <br />
              Njut av en romantisk kvällskryssning på Seine-floden med utsikt
              över upplysta monument.
            </li>
            <li>
              <strong>Versailles Dagsutflykt:</strong> <br />
              Besök det praktfulla slottet och dess trädgårdar för en dag fylld
              av historia och elegans. Slottet går att nå per pendeltåg på bara
              40 minuter från centrala Paris.
            </li>
            <li>
              <strong>Disneyland Paris:</strong>
              <br />
              Utforska den magiska världen av Disneyland Paris, där sagor blir
              verklighet. Upplev spännande åkattraktioner, träffa dina
              favoritdisneyfigurer och njut av en dag fylld med lekfulla äventyr
              och magi. För biljetter och öppettider, besök{' '}
              <a
                href="https://www.disneylandparis.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Disneyland Paris officiella hemsida
              </a>
              .
            </li>
          </ul>
        </>
      ),
    },
  ],
  img_title: 'Louvren i Paris',
  img_path: 'eiffeltornet-paris-frankrike.jpeg',
  title: 'Paris',
  header:
    'Vill du uppleva den franska kulturen i Paris? Vi har satt samman en guide genom huvudstadens många hörn och vrår. Följ med på en fantastiskt resa!',
  sitePath: '/paris-frankrike',
  helmet: (
    <Helmet>
      <meta
        name="description"
        content="Vill du uppleva den franska kulturen i Paris? Vi har satt samman en guide genom huvudstadens många hörn och vrår. Följ med på en fantastiskt resa!"
      />
      <meta
        name="keywords"
        content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, paris, frankrike, Louvren, Eiffeltornet, Moulin Rouge, Versailles, Montmartre, Disneyland, Seine, Notre Dame, Triumfbågen"
      />
      <title>Storstaden Paris</title>
      <link
        rel="canonical"
        href="https://tagluffaieuropa.se/guider/paris-frankrike"
      />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Article',
          author: {
            '@type': 'Organization',
            name: 'Tågluffa i Europa',
            url: 'https://www.tagluffaieuropa.se',
          },
          image:
            'https://www.tagluffaieuropa.se/static/media/eiffeltornet-paris-frankrike.jpeg',
          headline: 'Storstaden Paris',
          datePublished: '2024-02-05T08:00:00+08:00',
          dateModified: '2024-02-05T09:20:00+08:00',
          text: 'Vill du uppleva den franska kulturen i Paris? Vi har satt samman en guide genom huvudstadens många hörn och vrår. Följ med på en fantastiskt resa!',
        })}
      </script>
    </Helmet>
  ),
}

export default paris_frankrike
