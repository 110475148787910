import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  TableHead,
  useTheme,
  useMediaQuery,
  Hidden,
  Box,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { styles } from 'styles'
import React, { useEffect } from 'react'
import EndSummary from './endsummary'
import tableComponent from './tableComponent'
import FieldWrapper from './fieldWrapper'
import TableComponent from './tableComponent'

function EndInfo(props) {
  props.change_stepper('Betalning')

  useEffect(() => {
    props.flush_shopping_cart()
  }, [])

  const classes = styles()

  const theme = useTheme()
  const screenIsNotSmall = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Hidden smUp>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Typography variant="h6">
                  <Box fontWeight="fontWeightLight">{props.activeStep}</Box>
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12}>
          <Typography align={'center'} variant="h5">
            Din order med ordernummer:
          </Typography>
          <Typography align={'center'} variant="h5">
            {props.order.eurail_order_number}
          </Typography>
          <Typography align={'center'} variant="h5">
            har skapats!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align={'center'} variant="h1">
            <CheckCircleIcon fontSize={'inherit'} color={'primary'} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Orderinfo</Typography>
          {screenIsNotSmall ? (
            <TableComponent
              title={['Nr', 'Namn', 'Email', 'Telefon', 'Adress']}
              listitems={[
                [
                  1,
                  String(props.order.first_name + ' ' + props.order.surname),
                  String(props.order.email),
                  String(props.order.phone),

                  String(
                    props.order.adress +
                      ', ' +
                      props.order.postal_code +
                      ', ' +
                      props.order.ort
                  ),
                ],
              ]}
            />
          ) : (
            <TableContainer component={Paper}>
              <Table
                aria-label="summary of interrail passes in shopping cart"
                style={{ minWidth: '0px' }}
              >
                <TableHead className={classes.table}>
                  <TableRow>
                    <TableCell>Beställare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {props.order.first_name + ' ' + props.order.surname}
                      <br />
                      {props.order.phone}
                      <br />
                      {props.order.email}
                      <br />
                      {props.order.adress +
                        ', ' +
                        props.order.postal_code +
                        ', ' +
                        props.order.ort}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item xs={12}>
          <EndSummary
            change_stepper={function (dummy_value) {
              return
            }}
            ticket={props.order.order}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default EndInfo
