import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

var orderErrorPage = [
	{
		title: "Något blev fel",
		picPath: "",
		body: (
			<Box textAlign="center">
				<ErrorIcon style={{ fontSize: 80 }} />
			</Box>
		),
	},
	{
		title: "",
		picPath: "",
		body: "Tyvärr gick någonting fel i din beställning. Vi har meddelat vår supportavdelning som kommer att hantera ditt ärende med högsta prioritet. Vi hör av oss inom kort.",
	},
	{
		title: "",
		picPath: "",
		body: (
			<Box textAlign="center">
				<Button variant="contained" color="primary" component={Link} to="./">
					Till startsidan
				</Button>
			</Box>
		),
	},
];

export default orderErrorPage;
