const fetch = require("node-fetch");
var url =
	"https://api-test.distribution.eurail.com/distribution/api/v2.0/eurail/product-projections?limit=1&offset=0";
var url2 = "https://api-test.distribution.eurail.com/distribution";

var createEmptyCartBody = JSON.stringify({
	currency: "EUR",
	custom: {
		type: {
			typeId: "type",
			key: "cart-custom-fields",
		},
		fields: {
			businessChannel: "IN",
			fulfilmentMethod: "paper",
		},
	},
});

var myBody2 = {
	local: "en-GB",
	currency: "EUR",
	customerEmail: "test@test.se",
	custom: {
		type: {
			key: "cart-custom-fields",
		},
		fields: {
			contactPhone: "1111",
			fulfilmentMethod: "mobile",
			businessChanel: "IN",
		},
	},
	lineItems: [
		{
			custom: {
				type: {
					key: "line-item-pass-custom-fields",
				},
				fields: {
					fulfilmentMethod: "mobile",
					passCategory: "",
					travellerFirstName: "Carl",
					travellerLastName: "Bengtsson",
					dateOfBirth: "2020-01-01",
					countryOfResidence: "NL",
					gender: "m",
				},
			},
			quantity: 1,
			sku: "30452000000111",
		},
	],
	shippingMethod: {
		key: "no-shipping",
		typeId: "shipping-method",
	},
	shippingAdress: {
		country: "NL",
	},
};

var myBody3 = {
	locale: "en-GB", //language tag
	currency: "EUR",
	customerEmail: "test@test.se",
	custom: {
		type: {
			key: "cart-custom-fields",
		},
		fields: {
			contactPhone: "1111",
			fulfilmentMethod: "mobile",
			businessChannel: "IN",
		},
	},
	lineItems: [
		{
			custom: {
				type: {
					key: "line-item-pass-custom-fields",
				},
				fields: {
					fulfilmentMethod: "mobile",
					passCategory: "regular",
					travellerFirstName: "Carl",
					travellerLastName: "Bengtsson",
					dateOfBirth: "2020-01-01",
					countryOfResidence: "NL",
					gender: "m",
				},
			},
			quantity: 1,
			sku: "30410000000218",
		},
	],
	shippingMethod: {
		key: "no-shipping",
		typeId: "shipping-method",
	},
	shippingAddress: {
		country: "NL",
	},
};

var myHeaders = new fetch.Headers();
myHeaders.append(
	"Authorization",
	"Bearer pHlCLuAeF4dlH9RpfpPdgSA5bhOI0vgCIgtShpRLXNU0JmIvfYb7PqWbpwkOjJiecZW7yPhrvphIrOP5"
);
myHeaders.append("Content-Type", "application/json");

async function getTickets() {
	var requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};

	let res;

	await fetch(
		"https://api-test.distribution.eurail.com/distribution/api/v2.0/eurail/product-projections/search?limit=10&offset=0",
		requestOptions
	)
		.then((response) => {
			console.log(response);
			return response.text();
		})
		.then(function (result) {
			console.log("Tickets: " + JSON.parse(result));
			res = JSON.parse(result);
		})
		.catch((error) => console.log("error", error));

	return res;
}

async function getFunc() {
	var requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};

	let res;

	await fetch(
		"https://api-acc.distribution.eurail.com/distribution/api/v2.0/eurail/product-projections/search?limit=10&offset=0",
		requestOptions
	)
		.then((response) => response.text())
		.then((result) => {
			res = JSON.parse(result);
		})
		.catch((error) => console.log("error", error));
	return res;
}

//getFunc().then((res) => console.log(res));

async function createCart() {
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		redirect: "follow",
		body: createEmptyCartBody,
	};

	let res;

	await fetch(
		"https://api-acc.distribution.eurail.com/distribution/api/v2.0/eurail/carts",
		requestOptions
	)
		.then((response) => response.text())
		.then(function (result) {
			console.log("Cart created with ID: " + JSON.parse(result).id);
			res = JSON.parse(result).id;
		})
		.catch((error) => console.log("error", error));

	return res;
}

async function getCart(cartId) {
	var requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};

	await fetch(
		`https://api-acc.distribution.eurail.com/distribution/api/v2.0/eurail/carts/${cartId}`,
		requestOptions
	)
		.then((response) => response.text())
		.then((result) => console.log("Cart with matching ID: " + result))
		.catch((error) => console.log("error", error));
}

async function createCartWithContent(cart) {
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		redirect: "follow",
		body: JSON.stringify(cart),
	};

	let res, message;

	await fetch(
		"https://api-acc.distribution.eurail.com/distribution/api/v2.0/eurail/carts",
		requestOptions
	)
		.then((response) => response.text())
		.then(function (result) {
			message = JSON.parse(result);
			//console.log(message);
			if ("errors" in message) {
				throw message.message;
			}
			res = message.id;
		})
		.catch((error) => {
			console.log("Error: ", error);
			throw error;
		});

	return res;
}

async function validateCart(cartId) {
	var requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};
	let valid;

	await fetch(
		`https://api-acc.distribution.eurail.com/distribution/api/v2.0/eurail/carts/${cartId}/validate`,
		requestOptions
	)
		.then((response) => {
			if ("status" in response) {
				if (response.status != 204) {
					console.log("status är: " + response.status);
					console.log("statusText är: " + response.statusText);
					console.log("body är: " + response.body);
					console.log(Object.keys(response));
					throw response.text();
				} else {
					console.log("Validation approved");
					return true;
				}
			}
			return response.text();
		})
		.then((result) => (valid = result))
		.catch((error) => {
			throw error;
		});

	return valid;
}

async function createOrder(cartId) {
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		redirect: "follow",
		body: JSON.stringify({
			id: cartId,
			version: 1,
			paymentInterface: "nonepayment",
		}),
	};
	var order;

	await fetch(
		`https://api-acc.distribution.eurail.com/distribution/api/v2.0/eurail/orders`,
		requestOptions
	)
		.then((response) => {
			console.log("Order response:");
			console.log(response.statusText);
			console.log(response.status);
			if (response.status > 202) {
				throw response.text();
			}
			return response.text();
		})
		.then((result) => {
			order = JSON.parse(result).orderNumber;
		})
		.catch((error) => console.log("Error: ", error));
	return order;
}

async function createCartWithContent_test() {
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		redirect: "follow",
		body: createEmptyCartBody, //JSON.stringify(myBody3),
	};

	console.log("Entering cart creations");
	console.log(myBody2);

	let res, message;

	await fetch(
		"https://api-acc.distribution.eurail.com/distribution/api/v2.0/eurail/carts",
		requestOptions
	)
		.then((response) => response.text())
		.then(function (result) {
			message = JSON.parse(result);
			console.log(message.id);
			if ("errors" in message) {
				throw message.message;
			}
			res = message.id;
		})
		.catch((error) => {
			console.log("Error: ", error);
			throw error;
		});

	return res;
}

//getTickets().then((tickets) => console.log(tickets));
//createCart().then((id) => getCart(id));
//createCartWithContent(myBody3).then((id) => validateCart(id));
//createCartWithContent().then((id) => createOrder(id));
/*createCartWithContent_test()
	.then((id) => validateCart(id))
	.then((value) => console.log(value));
*/

/*
createCartWithContent_test()
	.then((id) => validateCart(id))
	.then((id) => createOrder(id))
	.then((id) => console.log(id));
*/

export { createCartWithContent, validateCart, createOrder };
