import React from 'react'
import { Divider, Typography, IconButton, Grid } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { styles } from 'styles'

function RubbishBin(props) {
  const classes = styles()
  if (props.bin === true) {
    return (
      <IconButton
        aria-label="delete"
        align="right"
        onClick={() => props.remove_from_shopping_cart(props.ticket.get_id())}
      >
        <DeleteForeverIcon className={classes.wrapperIcons} />
      </IconButton>
    )
  } else {
    return <div />
  }
}

function print_price(price, priceSize) {
  const classes = styles()
  if (price != null) {
    return (
      <Typography variant={priceSize} align="right">
        {price} SEK
      </Typography>
    )
  } else {
    return <div className={classes.priceDistance} />
  }
}

function FieldWrapper(props) {
  const classes = styles()
  var priceSize = ''

  if (props.bigPrice === true) {
    priceSize = 'h4'
  } else {
    priceSize = 'h6'
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" component="h1" gutterBottom align={'left'}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          {RubbishBin(props)}
        </Grid>
      </Grid>
      <Divider
        style={{
          height: '2px',
          color: 'black',
          backgroundColor: 'black',
          marginBottom: '12px',
        }}
      />
      <props.content_function
        ticket={props.ticket}
        update_ticket={props.update_ticket}
        totalpris={props.totalpris}
        are_tickets_valid={props.are_tickets_valid}
        set_tickets_valid_to_false={props.set_tickets_valid_to_false}
        change_stepper={props.change_stepper}
        order={props.order}
        update_order={props.update_order}
        shopping_cart={props.shopping_cart}
        find_item_in_shopping_cart={props.find_item_in_shopping_cart}
        backend_adress={props.backend_adress}
        gtag={props.gtag}
      />
      {print_price(props.price, priceSize)}
    </div>
  )
}

export default FieldWrapper
