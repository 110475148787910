import React from 'react'
import { styles } from 'styles'
import {
  Grid,
  Typography,
  Box,
  Fade,
  duration,
  Collapse,
} from '@material-ui/core'
import Fader from './effects/Fader'

/**
 * This is the rather wierdly named component that works as a basis for more informational pages of the site.
 * As it works right now it takes a list of info and loops over it in order to create the page.
 * The list needs a specific strucutre but all components are optional. If one does not want a specific aspects
 * leave that as an empty string "" and it will not be rendered.
 *
 * The list needs to be named "content".
 *
 * Example of list:
 *
 * [
 *  {
 *   title: "Placeholder Title",
 *   picPath: "placeholder_pic.jpeg",
 *   body:
 *    "Placeholder text",
 *  },
 *  {
 *   title: "",
 *   picPath: "",
 *   body:
 *    "Placeholder text",
 *  },
 * ]
 */

function StaticPage(props) {
  const classes = styles()
  const [picLoaded, setpicLoaded] = React.useState(false)

  function isThereAnImage(picPath) {
    if (picPath != '') {
      return (
        <Grid container>
          <Grid item md={3}></Grid>

          <Grid item xs={12} md={6}>
            <Fader in={picLoaded}>
              <img
                className={classes.blogImg}
                src={require('assets/' + picPath + '')}
                alt={'Img'}
                onLoad={() => setpicLoaded(true)}
              />
            </Fader>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      )
    } else {
      return <div></div>
    }
  }

  function StaticPageGenerator() {
    return (
      <div style={{ minHeight: '100vh' }}>
        {props.content.map((element, index) => (
          <Grid container direction="column" key={index}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={10} md={6}>
                {element.title && (
                  <h1 style={{ fontWeight: '300', textAlign: 'center' }}>
                    {element.title}
                  </h1>
                )}
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
            {!element.picAfter && isThereAnImage(element.picPath)}
            <Grid container>
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={10} md={6}>
                <h2 style={{ marginLeft: '0px' }}>{element.subtitle}</h2>
                <div
                  style={{
                    fontWeight: '300',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em',
                  }}
                >
                  {element.body}
                </div>
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
            {element.picAfter && isThereAnImage(element.picPath)}
          </Grid>
        ))}
      </div>
    )
  }

  return <div>{StaticPageGenerator()}</div>
}

export default StaticPage
