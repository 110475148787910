//////////////////////////////////////////////////////////////////////////
//
// Function loop through shopping cart in search of tickets, categorizing
// passengers and creating Request message based on it.
//
//////////////////////////////////////////////////////////////////////////

export default function generateinterrailCart(shopping_cart, order) {
	var interrailTicketTemplate = {
		custom: {
			type: {
				key: "line-item-pass-custom-fields",
			},
			fields: {
				fulfilmentMethod: "mobile",
				passCategory: "regular", //[regluar, ...]
				travellerFirstName: "",
				travellerLastName: "",
				dateOfBirth: "", // YYYY-MM-DD
				countryOfResidence: "", // Country code
				gender: "u", // [m,f,u]
			},
		},
		quantity: 1,
		sku: "",
	};

	var interrailCart = {
		locale: "en-GB", // Language tag
		currency: "EUR",
		customerEmail: order.get_email(),
		custom: {
			type: {
				key: "cart-custom-fields",
			},
			fields: {
				contactPhone: order.get_swish_phone_number(),
				fulfilmentMethod: "mobile",
				businessChannel: "IN", // [EU, IN] - Probably Eurail & Interrail
			},
		},
		lineItems: [],
		shippingMethod: {
			key: "no-shipping",
			typeId: "shipping-method",
		},
		shippingAddress: {
			// TODO: Check if the below variable may be set to SE for all orders
			country: "NL", // Country code, e.g. "NL"
		},
	};

	var protectionplan = false,
		customer;
	// Loop through shopping cart to search for tickets
	shopping_cart.map((ticket) => {
		// Check if avbeställningsskydd
		if (ticket.get_type() == "addition") {
			if (ticket.get_name() == "Avbeställningskydd") {
				protectionplan = true;
			}
		} else if (ticket.get_type() == "ticket") {
			// Otherwise ticket and then add info to cart structure
			customer = JSON.parse(JSON.stringify(interrailTicketTemplate)); // Create a deep copy of the template
			/* customer.custom.fields.passCategory = ticket.get_comfort(); */
			customer.custom.fields.travellerFirstName = ticket.get_first_name();
			customer.custom.fields.travellerLastName = ticket.get_surname();
			customer.custom.fields.dateOfBirth = ticket.date_of_birth
				.toISOString()
				.split("T")[0];
			customer.custom.fields.countryOfResidence = ticket.get_nationality();
			//customer.custom.fields.gender = ticket.get_gender();  // Uncomment if gender is included in ticket.
			customer.sku = ticket.get_sku().toString();

			//Add customer information for every ticket
			interrailCart.lineItems.push(customer);
		}
	});
	return interrailCart;
}
