import React from 'react'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from 'material-ui/Checkbox'
import { styles } from 'styles'

const ages = {
  CHD: 'Barn',
  YTH: 'Ungdom',
  ADT: 'Vuxen',
  SEN: 'Senior',
}

const comforts = {
  FIRST: 'Första klass',
  STANDARD: 'Andra klass',
  third: 'Plankning',
  '1stclass': 'Första klass',
  '2ndclass': 'Andra klass',
}

function ReturnTicketList(props) {
  const classes = styles()

  function handleCheck(event, ticket) {
    props.handleCheckFunction(event.target.checked, ticket)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          aria-label="summary of interrail passes in shopping cart"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Pass</TableCell>
              <TableCell>Komfort</TableCell>
              <TableCell>Resenär</TableCell>
              <TableCell>Namn</TableCell>
              <TableCell align="right">Pris</TableCell>
              <TableCell align="right">Återköp?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tickets.map((ticket, index) =>
              ticket.refundable ? (
                <TableRow key={index}>
                  <TableCell>{ticket.name}</TableCell>
                  <TableCell>{comforts[ticket.comfort]}</TableCell>
                  <TableCell>{ages[ticket.age]}</TableCell>
                  <TableCell>
                    {ticket.first_name + ' ' + ticket.surname}
                  </TableCell>
                  <TableCell align="right">{ticket.price}</TableCell>
                  <TableCell align="right">
                    <Checkbox
                      color="grey"
                      onChange={(e) => handleCheck(e, ticket)}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell className={classes.disabledTableCell}>
                    {ticket.name}
                  </TableCell>
                  <TableCell className={classes.disabledTableCell}>
                    {comforts[ticket.comfort]}
                  </TableCell>
                  <TableCell className={classes.disabledTableCell}>
                    {ages[ticket.age]}
                  </TableCell>
                  <TableCell className={classes.disabledTableCell}>
                    {ticket.first_name + ' ' + ticket.surname}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.disabledTableCell}
                  >
                    {ticket.price}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.disabledTableCell}
                  >
                    <Checkbox
                      disabled
                      onChange={(e) => handleCheck(e, ticket)}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ReturnTicketList
