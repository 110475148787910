import React from "react";

const xml2js = require("xml2js");

////////////////////////////////////////////////////////////////////
// Function takes xmlMessage and address as input parameters
// The information is sent to the ACPRail API
// A Response is achieved, converted into a jsonResponse which is finally returned
////////////////////////////////////////////////////////////////////

export default async function postACPRailRequest(
	xmlMessage,
	address,
	backend_adress
) {
	var response, jsonResponse;

	var baseURL = backend_adress + "/";
	var url = baseURL + "acpRequest";

	await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			url: address,
			message: xmlMessage,
		}),
	})
		.then(function (response) {
			if (!response.ok) {
				const message = `An error has occured: ${response.status}`;
				throw new Error(message);
			}
			return response.json();
		})
		.then(function (json) {
			if (json) {
				jsonResponse = json.ans;
			}
		})
		.catch(function (error) {
			console.log("Request failure: ", error);
		});

	return jsonResponse;
}
