import React, { useEffect, useState, Suspense, lazy } from 'react'
import Helmet from 'react-helmet'
import { styles } from 'styles'
import StepperBar from './StepperBar'
import TicketBuy from './Ticket_buy'
import { Switch, Route, Redirect } from 'react-router-dom'
//import StaticPage from './StaticPage.js'
//import Homepage from './Homepage.js'
import GuideList from './guideList.js'
import interail_kort_text from '../assets/interrail_kort_text'
import support_text from '../assets/support_text'
import Login from './Login'
import NotFoundPage from '../assets/NotFoundPage'
import OrderErrorPage from '../assets/OrderErrorPage'
import Fader from './effects/Fader'
import ReturnView from './returns/returnView'
import ReturnConfirmation from './returns/returnConfirmation'
import ThinkAbout from './thinkAbout'
import { termsandconditions } from './TermsAndConditons'
const Homepage = lazy(() => import('./Homepage.js'))
const StaticPage = lazy(() => import('./StaticPage.js'))
const Artiklar = lazy(() => import('./artiklar'))

function Content(props) {
  const classes = styles()

  return (
    <div className={classes.content}>
      <Suspense fallback={<div>Laddar...</div>}>
        <Switch>
          <Route path="/buy">
            <Helmet>
              <meta
                name="description"
                content="Ska du ut och tågluffa? Då har du hamnat helt rätt. Köp ditt interrailkort hos oss redan idag och förbered dig inför 2024 års stora äventyr."
              />
              <meta
                name="keywords"
                content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, interrail-kort, biljett, köp, köpa, inhandla, införskaffa, beställ, boka"
              />
              <title>Köp ditt interrail-kort</title>
              <link rel="canonical" href="https://tagluffaieuropa.se/buy" />
            </Helmet>
            <TicketBuy
              order={props.order}
              update_order={props.update_order}
              add_to_shopping_cart={props.add_to_shopping_cart}
              shopping_cart={props.shopping_cart}
              summarize_price={props.summarize_price}
              update_ticket={props.update_ticket}
              remove_from_shopping_cart={props.remove_from_shopping_cart}
              are_tickets_valid={props.are_tickets_valid}
              tickets_valid={props.tickets_valid}
              set_tickets_valid_to_false={props.set_tickets_valid_to_false}
              find_item_in_shopping_cart={props.find_item_in_shopping_cart}
              current_new_ticket_index={props.current_new_ticket_index}
              modify_ticket_index={props.modify_ticket_index}
              flush_shopping_cart={props.flush_shopping_cart}
              backend_adress={props.backend_adress}
              gtag={props.gtag}
            />
          </Route>
          <Route path="/tank-pa">
            <Redirect to="/guider/tagluffning-for-nyborjare" />
            <ThinkAbout />
          </Route>
          <Route path="/guider">
            <GuideList />
          </Route>
          <Route path="/artiklar">
            <Artiklar />
          </Route>
          <Route path="/interrail-kort">
            <Redirect to="/interrailkort" />
          </Route>
          {/*<Redirect to={`${match.path}/ticketView`} />*/}
          <Route exact path="/interrailkort">
            <Helmet>
              <meta
                name="description"
                content="Om du har bestämt dig för att du vill ta dig fram i Europa med hjälp av tåg är det bästa alternativet att inhandla interrailkort. Interrailkort erbjuds i många varianter och hos oss finner du all värdefull information kring interrailkort och dess utformning."
              />
              <meta
                name="keywords"
                content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, interrail-kort"
              />
              <title>Interrailkort</title>
              <link
                rel="canonical"
                href="https://tagluffaieuropa.se/interrailkort"
              />
              <script type="application/ld+json">{`
            {
              "@context": "http://schema.org",
              "@type": "Guide",
              "about": "Interrailkort",
              "name": "Interrailkort",
              "text": "Om du har bestämt dig för att du vill ta dig fram i Europa med hjälp av tåg är det bästa alternativet att inhandla interrailkort. Interrailkort erbjuds i många varianter och hos oss finner du all värdefull information kring interrailkort och dess utformning.",
              "reviewAspect": ["Priser,
              Ålderskategori,
              Komfort,
              Giltighet"]
            }
          `}</script>
            </Helmet>
            <StaticPage content={interail_kort_text} />
          </Route>
          <Route exact path="/support">
            <Helmet>
              <meta
                name="description"
                content="Har du stött på problem med din bokning? Ta hjälp utav vår supportavdelning. Tillsammans kan vi möjliggöra din resa."
              />
              <meta
                name="keywords"
                content="tågluffa, interrail, support, kundservice"
              />
              <title>Support</title>
              <link rel="canonical" href="https://tagluffaieuropa.se/support" />
            </Helmet>
            <StaticPage content={support_text} />
          </Route>
          <Route exact path="/villkor">
            <Helmet>
              <meta
                name="description"
                content="Tågluffa i Europas allmänna villkor."
              />
              <meta
                name="keywords"
                content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff"
              />
              <link rel="canonical" href="https://tagluffaieuropa.se/villkor" />
            </Helmet>
            <StaticPage content={termsandconditions} />
          </Route>
          <Route path="/order-error">
            <Helmet>
              <meta
                name="description"
                content="Det blev någonting fel i din beställning."
              />
              <meta
                name="keywords"
                content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, interrail-kort, error, fel"
              />
              <title>Någonting gick fel</title>
            </Helmet>
            <StaticPage content={OrderErrorPage} />
          </Route>
          <Route path="/återköp">
            <Helmet>
              <meta
                name="description"
                content="Hos Tågluffa i Europa kan du själv hantera din order och de biljetter du införskaffat. Här har du möjlighet att returnera vissa eller alla dina biljetter."
              />
              <meta
                name="keywords"
                content="Retur, Återköp, Interrailkort, Biljett, Avboka"
              />
              <link rel="canonical" href="https://tagluffaieuropa.se/återköp" />
            </Helmet>
            <ReturnView gtag={props.gtag} />
          </Route>
          <Route path="/return-confirmation">
            <Helmet>
              <meta
                name="description"
                content="Hos Tågluffa i Europa kan du själv hantera din order och de biljetter du införskaffat. Visas denna sida är din retur bekräftad."
              />
              <meta
                name="keywords"
                content="Retur, Återköp, Interrailkort, Biljett, Avboka"
              />
              <link
                rel="canonical"
                href="https://tagluffaieuropa.se/return-confirmation"
              />
            </Helmet>
            <ReturnConfirmation />
          </Route>
          {'Home page has to be last. Dont ever put anything after this path.'}
          <Route exact path="/">
            <Helmet>
              <meta
                name="description"
                content="Ska du ut och tågluffa? Då har du hamnat helt rätt. Köp ditt interrailkort redan idag och förbered dig inför 2024 års stora äventyr. Vi hjälper dig till en perfekt resa!"
              />
              <meta
                name="keywords"
                content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff"
              />
              <link rel="canonical" href="https://tagluffaieuropa.se/" />
            </Helmet>
            <Homepage gtag={props.gtag} />
          </Route>
          <Route path="*">
            <Helmet>
              <meta
                name="description"
                content="Denna sida verkar inte finnas."
              />
              <meta
                name="keywords"
                content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, interrail-kort, error, 404"
              />
              <title>Error 404</title>
            </Helmet>
            <StaticPage content={NotFoundPage} />
          </Route>
        </Switch>
      </Suspense>
    </div>
  )
}

export default Content
