import React from "react";
import { styles } from "styles";
import { Fade } from "@material-ui/core";

function Fader(props) {
  const classes = styles();

  return (
    <>
      <Fade in={props.in} timeout={300}>
        {props.children}
      </Fade>
    </>
  );
}

export default Fader;
