import React from 'react'
import { Item, Span } from 'react-html-email'

export default function EmailFooter() {
  const TextDivider = () => <>&nbsp;·&nbsp;</>
  return (
    <>
      <Item style={{ height: 20 }} />
      <Item align="center">
        <Span
          color="rgb(117, 128, 140)"
          fontSize={14}
          style={{ lineHeight: 1.4 }}
        >
          Skapat av Tågluffa i Europa
          <TextDivider />
          Göteborg,&nbsp;Sweden&nbsp;
        </Span>
      </Item>
      {/*
      <Item style={{ height: 5 }} />
      <Item align="center">
        <Span
          color="rgb(117, 128, 140)"
          fontSize={14}
          style={{ lineHeight: 1.4 }}
        >
          <EmailLink href="https://www.ovrsea.com/cgv">
            Privacy and General Terms & Conditions
          </EmailLink>
          <TextDivider />
          <EmailLink href="https://support.ovrsea.com/hc/fr">
            Help center
          </EmailLink>
        </Span>
      </Item>*/}
      <Item style={{ height: 45 }} />
    </>
  )
}
