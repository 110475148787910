import React from 'react'
import ReturnEmailTemplate from './returnEmailTemplate'
import { renderEmail } from 'react-html-email'

export default async function sendReturnMail(
  order,
  returning_tickets,
  payback,
  backend_adress
) {
  var response

  var baseURL = backend_adress + '/'
  var url = baseURL + 'sendConfirmationMail'

  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      from: '"Tågluffa i Europa" kundservice@tagluffaieuropa.se',
      email: order.email,
      subject: 'Bokningsretur',
      messageHtml: returnMail(
        order.first_name,
        order.eurail_order_id,
        returning_tickets,
        payback
      ), // HTML code
    }),
  })
    .then(function (response) {
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`
        throw new Error(message)
      }
      return response.json()
    })
    .then(function (json) {
      if (json) {
        response = json
        if (response.msg == 'fail') {
          throw new Error('Error when sending returning mail.')
        } else if (response.msg == 'success') {
          console.log('Successfully sent returning mail!')
        }
      }
    })
    .catch(function (error) {
      console.log('Returning mail failure: ', error)
      return 'fail'
    })

  return 'success'
}

function returnMail(name, ordernummer, biljetter, payback) {
  const emailBody = `
  <html>
  <div style="width: 100%; min-height: 100vh; background-color: #F5F8FA;">
  <div style="max-width: 640px; margin-left:auto; margin-right:auto;">
    <!-- Header -->
    <table width="100%" bgcolor="#F5F8FA">
        <tr>
            <td align="center">
                <img src="https://tagluffaieuropa.se/pictures_for_external_use/tågluffaieuropa_logo.png" alt="Logo" width="280">
            </td>
        </tr>
    </table>
<div style="height: 1rem"></div>
    <!-- Body -->
<div style="background-color: #FFFFFF;padding: 2rem">
    <table width="100%">
        <tr>
            <td>
                <h2>Din retur är inlagd!</h2>
            </td>
        </tr>
        <tr>
            <td>
                <p>Hej ${name}, vi kan meddela att din retur är oss tillhanda!</p>
                <p>Din bokning med ordernummer ${ordernummer} har uppdaterats.
                Följande biljetter har lagts in för returnering:</p>
                
            </td>
            </tr>
        </table>
                ${biljetter.map(
                  (biljett) =>
                    `<table width="100%">
                    <tr>
                        <td>
                        <h3>${biljett.get_first_name()} ${biljett.get_surname()}</h3></td>
                        <td><h3 style="text-align: right;">${biljett.get_name()}</h3></td>
                    </tr>
                  </table>`
                )}

                <table width="100%">
                  <tr>
                      <td>
                Nu kommer vi att processera din retur och när den är behandlad
              kommer dina pengar att återbetalas. Återbetalningen kommer att
              omfatta ${payback} kr. Det kan ta några arbetsdagar tills dess
              pengarna syns på ditt konto.
              </td>
                  </tr>
                </table>

                <table width="100%">
                  <tr>
                      <td>
                          <p>Välkommen åter,</p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p>Tågluffa i Europa</p>
                      </td>
                  </tr>
                </table>
</div>
    <!-- Footer -->
    <table style="width: 100%; background-color:#F5F8FA; padding-top:1rem">
        <tr>
            <td align="center">
                <p>Tack för att du använder dig av oss.</p>
                <p>&copy; 2023 Tågluffa i Europa</p>
                <p>I samarbete med</p>
                <img src="https://tagluffaieuropa.se/pictures_for_external_use/eurail_logo.png" alt="Eurail logo" width="140">
            </td>
        </tr>
    </table>
    </div>
    </div>
    </html>
`
  return emailBody
}
