import React from 'react'
import Helmet from 'react-helmet'
import { Box } from '@material-ui/core'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import GuideCard from './guideCard.js'
import StaticPage from './StaticPage.js'
import { funktionsvariation } from 'assets/artiklar/resa-med-funktionsnedsattning'

function Artiklar() {
  let match = useRouteMatch()
  var list_of_articles = [funktionsvariation] //TODO: Put all content in a folder and import all files of that folder, put in this array and fill the page.

  return (
    <div>
      <Switch>
        {list_of_articles.map((article, index) => (
          <Route exact path={`${match.path}${article.sitePath}`} key={index}>
            {article.helmet ? article.helmet : <></>}
            <StaticPage content={article.content} />
          </Route>
        ))}
        <Route exact path={`${match.path}/`}>
          <Helmet>
            <meta
              name="description"
              content="För att gå in på djupet kring vissa ämnen kopplat till Tågluffning har vi publicerat ett otal artiklar. Dessa artiklar kan visa sig mycket värdefulla i din förberedelse för en stundande resa genom Europa."
            />
            <meta
              name="keywords"
              content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, artikel, artiklar, information, hjälp, resmål, destination, tips, råd, tricks"
            />
            <title>Artiklar</title>
            <link rel="canonical" href="https://tagluffaieuropa.se/artiklar/" />
            <script type="application/ld+json">{`
            {
              "@context": "http://schema.org",
              "@type": "Artiklar",
              "about": "Artiklar kring tågluffning"
              "name": "Artiklar"
              "text": "För att gå in på djupet kring vissa ämnen kopplat till Tågluffning har vi publicerat ett otal artiklar. Dessa artiklar kan visa sig mycket värdefulla i din förberedelse för en stundande resa genom Europa."
            }
          `}</script>
          </Helmet>
          <StaticPage
            content={[
              {
                title: 'Artiklar',
                subtitle: '',
                picPath: '',
                body: list_of_articles.map((article, index) => (
                  <Box marginBottom={1} key={index}>
                    <GuideCard content={article} path={match.path} />
                  </Box>
                )),
              },
            ]}
          />
        </Route>
        <Route path={match.path}>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  )
}

export default Artiklar
