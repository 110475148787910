//////////////////////////////////////////////////////////////////////////
//
// Function calculates the age for the passenger of a ticket.
// Takes a ticket_class object "ticket" as input.
// Returns a number corresponding to the age.
//
//////////////////////////////////////////////////////////////////////////

export default function calculateAge(ticket) {
	// Calculate age either right now or at travel date
	var age_at_travel_date = true;

	var age_reference;
	if (age_at_travel_date) {
		age_reference = ticket.get_first_travel_date();
	} else {
		age_reference = Date.now();
	}

	// Set the time of day for the reference age to 23:59:59 to make sure passenger
	// is considered to be of the "new"/"older" age if the travel date equals the
	// date of birth.
	age_reference.setHours(23);
	age_reference.setMinutes(59);
	age_reference.setSeconds(59);

	var date_of_birth = ticket.get_date_of_birth();
	var diff_ms = age_reference - date_of_birth.getTime();
	var age_dt = new Date(diff_ms);

	return Math.abs(age_dt.getUTCFullYear() - 1970);
}
