import React from 'react'
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core'

function ConsentContent() {
  const handleChange = (event) => {
    //setPaymentOption(event.target.value)
  }

  return (
    <>
      <h1 style={{ marginLeft: '0px' }}>Vi använder cookies</h1>
      <p>
        För att tagluffaieuriopa.se ska fungera behövs nödvändiga cookies. Vi
        använder också andra cookies för att anpassa och förbättra din
        upplevelse.
      </p>
    </>
  )
}

export default ConsentContent
