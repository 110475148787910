import backendAdress from './../../backendAdress'

export default async function priceCheckerEurail(
  response,
  shopping_cart,
  total_price
) {
  var faresOfDesiredPassModel = []
  var fares = []

  if (response.body.totalPrice.currencyCode != 'EUR') {
    // Throw unknown currency error
  }
  var eurail_total_price = response.body.totalPrice.centAmount / 100

  var response = await get_exchange_rate(response)

  if (response.error) {
    console.log('Got error in priceChecker')
    response.concatenatedError.push(
      'Getting exchange rate did not execute properly. '
    )
    return response
  }
  var exchange_rate = response.body
  eurail_total_price = calculate_ticket_price(eurail_total_price, exchange_rate)

  if (eurail_total_price > total_price) {
    console.log('Eurail prices are higher then ours! We are losing money')
    console.log('Eurail price: ' + eurail_total_price)
    console.log('Tågluffaieuropa price: ' + total_price)
    response.body = false
    return response
  } else {
    console.log('Eurail prices are lower. All is fine!')
    console.log('Eurail price: ' + eurail_total_price)
    console.log('Tågluffaieuropa price: ' + total_price)
    response.body = true
    return response
  }
}

async function get_exchange_rate(message) {
  let valuta = await fetch(backendAdress + '/getExchangeRate')
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      let margin = 0.115

      message.body = response.data.data.SEK + margin
      return message
    })
    .catch((error) => {
      console.log('Caught an error!', error)
      message.error = error
      message.concatenatedError.push(error)
      message.concatenatedError.push(
        ' An error has occured in communication with exchange rate API. '
      )
      return message
    })
  return valuta
}

function calculate_ticket_price(eurail_price, exchange_rate) {
  // Converting price from EUR to SEK and adding marginals
  let tagluffa_price = exchange_rate * eurail_price
  //tagluffa_price = Math.ceil(tagluffa_price / 50) * 50 - 1; // Round up to closest 50 and make it 1 SEK cheaper.
  if (tagluffa_price < 0) {
    tagluffa_price = 0
  }
  return tagluffa_price
}
