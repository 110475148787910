import React from "react";
import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Fader from "./effects/Fader";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

const ages = {
	CHD: "Barn",
	YTH: "Ungdom",
	ADT: "Vuxen",
	SEN: "Senior",
	99: "Superold Jensboy",
};

const comforts = {
	FIRST: "Första klass",
	STANDARD: "Andra klass",
	third: "Plankning",
	"1stclass": "Första klass",
	"2ndclass": "Andra klass",
};

function Summary(props) {
	const classes = useStyles();

	props.change_stepper("Summering");

	var tickets = [];
	var ticketstruct = {};
	var additions = [];
	var products = [];

	var templateStruct = {
		quantity: 1,
		price: [],
	};

	var name = null;
	var price = null;
	var age = null;
	var comfort = null;
	var quantity = null;

	function item_sorter(ticket) {
		// Group the shopping cart items based on category (tickets, additions, products)
		if (ticket.get_type() === "ticket") {
			// If ticket then check name, age and comfort for current ticket
			name = ticket.get_name();
			age = ages[ticket.get_age()];
			comfort = comforts[ticket.get_comfort()];

			// Add info to ticket struct
			if (!ticketstruct.hasOwnProperty(name)) {
				ticketstruct[name] = {};
			}
			if (!ticketstruct[name].hasOwnProperty(age)) {
				ticketstruct[name][age] = {};
			}
			if (!ticketstruct[name][age].hasOwnProperty(comfort)) {
				ticketstruct[name][age][comfort] = {
					quantity: 1,
					price: [],
				};
			} else {
				ticketstruct[name][age][comfort].quantity =
					ticketstruct[name][age][comfort].quantity + 1;
			}
			ticketstruct[name][age][comfort].price.push(ticket.get_price());
		} else if (ticket.get_type() === "addition") {
			// If addition then check name for current addition
			name = ticket.get_name();
			var search = true;
			for (var i = 0; i < additions.length; i++) {
				if (additions[i].name === name) {
					additions[i].quantity = additions[i].quantity + 1;
					search = false;
				}
			}
			if (search) {
				price = ticket.get_price();
				quantity = 1;
				additions.push({ name, price, quantity });
			}
		} else if (ticket.get_type() === "product") {
			// If product then check name for current product
			name = ticket.get_name();
			price = ticket.get_price();
			products.push({ name, price });
		}
	}

	function createTicketlist() {
		// create array of grouped tickets based on name, age & comfort from struct of tickets
		var namelist = Object.keys(ticketstruct);
		for (var i = 0; i < namelist.length; i++) {
			var agelist = Object.keys(ticketstruct[namelist[i]]);
			for (var j = 0; j < agelist.length; j++) {
				var comfortlist = Object.keys(ticketstruct[namelist[i]][agelist[j]]);
				for (var k = 0; k < comfortlist.length; k++) {
					tickets.push({
						name: namelist[i],
						comfort: comfortlist[k],
						age: agelist[j],
						price: ticketstruct[namelist[i]][agelist[j]][
							comfortlist[k]
						].price.reduce((a, b) => a + b, 0), // Summarize all pricetags for all tickets of this category
						quantity:
							ticketstruct[namelist[i]][agelist[j]][comfortlist[k]].quantity,
					});
				}
			}
		}
	}

	// Group based on category
	props.ticket.map((actual_ticket) => item_sorter(actual_ticket));
	// Group similar tickets
	createTicketlist();

	function addition_printer() {
		if (additions.length > 0) {
			return (
				<div>
					<Typography>Tillval</Typography>
					<TableContainer component={Paper}>
						<Table
							className={classes.table}
							aria-label="summary of additions in shopping cart"
						>
							<TableBody>
								{additions.map((addition) => (
									<TableRow key={addition.id}>
										<TableCell component="th" scope="row">
											{addition.name}
										</TableCell>
										<TableCell align="right">{addition.price}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			);
		} else {
			return;
		}
	}

	function product_printer() {
		if (products.length > 0) {
			return (
				<div>
					<Typography>Produkter</Typography>
					<TableContainer component={Paper}>
						<Table
							className={classes.table}
							aria-label="summary of products in shopping cart"
						>
							<TableBody>
								{products.map((product) => (
									<TableRow key={product.id}>
										<TableCell component="th" scope="row">
											{product.name}
										</TableCell>
										<TableCell align="right">{product.price}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			);
		} else {
			return;
		}
	}

	function ticket_printer() {
		return (
			<div>
				<Typography>Interrailkort</Typography>
				<TableContainer component={Paper}>
					<Table
						className={classes.table}
						aria-label="summary of interrail passes in shopping cart"
					>
						<TableHead>
							<TableRow>
								<TableCell>Pass</TableCell>
								<TableCell>Resenär</TableCell>
								<TableCell>Komfort</TableCell>
								<TableCell>Antal</TableCell>
								<TableCell align="right">Pris</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tickets.map((ticket, index) => (
								<TableRow key={index}>
									<TableCell>{ticket.name}</TableCell>
									<TableCell>{ticket.age}</TableCell>
									<TableCell>{ticket.comfort}</TableCell>
									<TableCell>{ticket.quantity}</TableCell>
									<TableCell align="right">{ticket.price}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}

	function condensed_ticket_printer() {
		return (
			<div>
				<Typography>Interrailkort</Typography>
				<TableContainer component={Paper}>
					<Table
						className={classes.table}
						aria-label="summary of interrail passes in shopping cart"
						style={{ minWidth: "0px" }}
					>
						<TableHead>
							<TableRow>
								<TableCell>Antal</TableCell>
								<TableCell>Pass</TableCell>
								<TableCell align="right">Pris</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tickets.map((ticket, index) => (
								<TableRow key={index}>
									<TableCell>{ticket.quantity}</TableCell>
									<TableCell>
										{ticket.name}
										<br />
										{"(" + ticket.age + ", " + ticket.comfort + ")"}
									</TableCell>
									<TableCell align="right">{ticket.price}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}

	const theme = useTheme();
	const screenIsNotSmall = useMediaQuery(theme.breakpoints.up("sm"));
	return (
		<Fader in={true}>
			<div>
				{screenIsNotSmall ? ticket_printer() : condensed_ticket_printer()}
				{addition_printer()}
				{product_printer()}
			</div>
		</Fader>
	);
}

export default Summary;
