import React from 'react'
import {
  BrowserRouter as Router,
  useHistory,
  HashRouter,
} from 'react-router-dom'
import Helmet from 'react-helmet'

import Header from './Components/header'
import Content from './Components/content'
import TravellerInfo from './Components/traveller'
import ShoppingCartDrawer from './Components/shopping_cart'
import CookieBanner from './Components/CookieBanner'
import { MuiThemeProvider, ThemeProvider } from '@material-ui/core/styles'
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'
import { THEME } from 'styles'
import ticket_class from './ticket_class'
import order_class from './order_class'
import TagManager from 'react-gtm-module'
import backendAdress from './backendAdress'
import { el } from 'date-fns/locale'
import Footer from 'Components/Footer'
import ScrollToTop from 'Components/functions/ScrollToTop'
import FullPageHypeTimer from 'Components/fullpageHypeTimer'
import CookieConsentPopUp from 'Components/cookieConsentPopUp'
import { generate_GA4_event_parameters_item } from 'Components/functions/googleAnalyticsFunctions'

const generateClassName = createGenerateClassName({
  productionPrefix: `generated_class-${Math.random()}`,
  disableGlobal: false,
})

var dev = false
var backend_adress = backendAdress

// Google Analytics implementation
const tagManagerArgs = {
  gtmId: 'GTM-TWH54QJ',
  dataLayer: {
    event:
      window.location.origin +
      window.location.pathname +
      window.location.search,
  },
}

window.dataLayer = window.dataLayer || []
function gtag() {
  window.dataLayer.push(arguments)
}

gtag('consent', 'default', {
  ad_storage: 'denied',
  analytics_storage: 'denied',
})

TagManager.initialize(tagManagerArgs)

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      shopping_cart: [],
      order: new order_class(),
      current_tab: false,
      drawer_open: false,
      tickets_valid: false,
      current_new_ticket_index: 1,
      just_added_to_shopping_cart: false,
    }

    this.add_to_shopping_cart = this.add_to_shopping_cart.bind(this)
    this.update_ticket = this.update_ticket.bind(this)
    this.summarize_price = this.summarize_price.bind(this)
    this.change_tab = this.change_tab.bind(this)
    this.toggle_drawer = this.toggle_drawer.bind(this)
    this.remove_from_shopping_cart = this.remove_from_shopping_cart.bind(this)
    this.find_item_in_shopping_cart = this.find_item_in_shopping_cart.bind(this)
    this.setValidTicketsToFalse = this.setValidTicketsToFalse.bind(this)
    this.count_types = this.count_types.bind(this)
    this.areTicketsValid = this.areTicketsValid.bind(this)
    this.modify_ticket_index = this.modify_ticket_index.bind(this)
    this.addOrder = this.addOrder.bind(this)
    this.update_order = this.update_order.bind(this)
    this.flush_shopping_cart = this.flush_shopping_cart.bind(this)
    this.update_shopping_cart = this.update_shopping_cart.bind(this)
  }

  addOrder(order) {
    this.setState({
      order: order,
    })
  }

  setValidTicketsToFalse() {
    this.setState({ tickets_valid: false })
  }

  areTicketsValid() {
    var valid = true
    var atributes = []
    var error_msg
    for (var element of this.state.shopping_cart) {
      if (element.get_type() === 'ticket') {
        if (!element.is_ticket_valid()) {
          console.log(element.what_is_missing())
          valid = false
          break
        }
      }
    }

    //You shouldn't be able to buy too many tickets at a time.
    for (var element of this.state.shopping_cart) {
      if (element.get_type() === 'ticket') {
        atributes.push(element.get_age())
      }
    }
    if (atributes.length > 30) {
      valid = false
      error_msg = 'Du kan inte beställa fler än 30 biljetter åt gången.'
    }

    // Update child ticket prices.
    var ticketStruct = createTicketStruct(this.state.shopping_cart)
    if (ticketStruct) {
      // Call for function to change all children prices to some value if no adults are added to the same class and date as the children.
      updateChildPrices(
        ticketStruct,
        this.state.shopping_cart,
        this.find_item_in_shopping_cart,
        this.update_shopping_cart
      )
    }

    this.setState({ tickets_valid: valid })
    return error_msg
  }

  toggle_drawer() {
    this.setState({ drawer_open: !this.state.drawer_open })
  }

  add_to_shopping_cart(item) {
    const cart = this.state.shopping_cart
    cart.push(item)
    gtag('event', 'add_to_cart', generate_GA4_event_parameters_item(item))
    this.setState({
      shopping_cart: cart,
    })
    this.setState({
      just_added_to_shopping_cart: true,
    })
    setTimeout(
      function () {
        this.setState({ just_added_to_shopping_cart: false })
      }.bind(this),
      1000
    )
  }

  modify_ticket_index(new_ticket_index) {
    this.setState({ current_new_ticket_index: new_ticket_index })
  }

  find_item_in_shopping_cart(id) {
    const cart = this.state.shopping_cart
    var index = null
    cart.forEach(function (value, i) {
      if (value.get_id() === id) {
        index = i
      }
    })
    return index
  }

  remove_from_shopping_cart(id) {
    console.log('Got id: ' + id)
    const cart = this.state.shopping_cart
    const index = this.find_item_in_shopping_cart(id)
    console.log('Removing index: ' + index)
    cart.splice(index, 1)
    this.setState({
      shopping_cart: cart,
    })
  }

  flush_shopping_cart() {
    if (!(this.state.shopping_cart == [])) {
      this.setState({
        shopping_cart: [],
      })
    }
  }

  update_order(new_order) {
    this.setState({ order: new_order })
  }

  update_shopping_cart(new_cart) {
    this.setState({
      shopping_cart: new_cart,
    })
  }

  update_ticket(
    id,
    type = null,
    age = null,
    comfort = null,
    first_name = null,
    surname = null,
    passport_number = null,
    nationality = null,
    date_of_birth = null,
    first_travel_date = null
  ) {
    const index = this.find_item_in_shopping_cart(id)
    const cart = this.state.shopping_cart
    var priceChange = false
    if (type) {
      cart[index].set_type(type)
      priceChange = true
    }
    if (age) {
      cart[index].set_age(age)
      priceChange = true
    }
    if (comfort) {
      cart[index].set_comfort(comfort)
      priceChange = true
    }
    if (first_name || first_name === '') {
      cart[index].set_first_name(first_name)
    }
    if (surname || surname === '') {
      cart[index].set_surname(surname)
    }
    if (passport_number || passport_number === '') {
      cart[index].set_passport_number(passport_number)
    }
    if (nationality) {
      cart[index].set_nationality(nationality)
    }
    if (date_of_birth || date_of_birth == 'Invalid') {
      cart[index].set_date_of_birth(date_of_birth)
    }
    if (first_travel_date) {
      cart[index].set_first_travel_date(first_travel_date)
    }
    if (priceChange) {
      fetch(
        backend_adress + '/ticketprice/?duration=' + cart[index].get_duration()
      )
        .then((resp) => resp.json())
        .then((data) => {
          cart[index].set_price(
            JSON.parse(data.ans[0].price)[age][comfort].price
          )
          cart[index].set_sku(JSON.parse(data.ans[0].price)[age][comfort].sku)
          return cart
        })
        .then((data) => this.update_shopping_cart(data))
        .catch(function (error) {
          console.log('Following error occurred when updating prices: ', error)
        })
    } else {
      this.setState({
        shopping_cart: cart,
      })
    }
  }

  summarize_price() {
    const cart = this.state.shopping_cart
    var price = 0
    cart.map((ticket) => (price += ticket.get_price()))
    return price
  }

  change_tab(value) {
    window.scrollTo(0, 0)
    this.setState({
      current_tab: value,
    })
  }

  count_types() {
    var cart = Object.create(this.state.shopping_cart)
    var compare_cart = Object.create(this.state.shopping_cart)
    var output = []
    cart.forEach(function (value, i) {
      var count = 0
      var ids = []
      var same = []
      compare_cart.forEach(function (ticket, index) {
        if (value.get_type() === 'ticket') {
          if (
            value.get_name() === ticket.get_name() &&
            value.get_age() === ticket.get_age() &&
            value.get_comfort() === ticket.get_comfort()
          ) {
            count += 1
            ids.push(ticket.get_id())
            same.push(index)
          }
        } else if (value.get_type() === 'addition') {
          if (value.get_name() === ticket.get_name()) {
            count += 1
            ids.push(ticket.get_id())
            same.push(index)
          }
        }
      })
      for (var x = same.length - 1; x >= 0; x--) {
        compare_cart.splice(same[x], 1)
      }
      if (count !== 0) {
        output.push({ ticket: value, count: count, id: ids })
      }
    })
    return output
  }

  componentDidMount() {
    this.dev_mode()
  }

  dev_mode() {
    if (dev) {
      var dev_tickets = []
      var date1 = new Date(1994, 9, 21)
      var date2 = new Date()
      var dateADT = new Date(1990, 9, 21)
      var dateCHD = new Date(2011, 9, 21)
      for (var i = 0; i < 1; i++) {
        dev_tickets.push(
          new ticket_class(
            99,
            '10 dagar på 2 månader',
            'ADT',
            '2ndclass',
            8000,
            'P10D',
            'Flexible',
            30410000000211,
            'Sven',
            'Svensson',
            12345,
            dateADT,
            date2,
            'DK',
            3000
          )
        )
        this.setState({
          current_new_ticket_index: this.state.current_new_ticket_index + 1, //Setting state is malfunctional
        })
      }
      dev_tickets.push(
        new ticket_class(
          100,
          '10 dagar på 1 månad',
          'CHD',
          '2ndclass',
          6000,
          'P10D',
          'Flexible',
          30410000000218,
          'Mia',
          'Svensson',
          12345,
          dateCHD,
          date2,
          'DK'
        )
      )
      this.setState({
        current_new_ticket_index: this.state.current_new_ticket_index + 1, //Setting state is malfunctional
      })
      for (var j = 0; j < 0; j++) {
        dev_tickets.push(
          new ticket_class(
            101,
            '22 dagar i följd',
            'YTH',
            '2ndclass',
            3793,
            'P22D',
            'Consecutive',
            30410000000218,
            'Erik',
            'Svensson',
            12345,
            date1,
            date2,
            'SE'
          )
        )
        this.setState({
          current_new_ticket_index: this.state.current_new_ticket_index + 1, //Setting state is malfunctional
        })
      }
      this.setState({ shopping_cart: dev_tickets })
      var new_order = new order_class()
      new_order.first_name = 'Emil'
      new_order.surname = 'Andersson'
      new_order.email = 'tagluffaieuropa@gmail.com'
      new_order.adress = 'Mölndag'
      new_order.postal_code = '41163'
      new_order.ort = 'GBG'
      new_order.phone = '46738471761'
      new_order.swish_first_name = 'Emil'
      new_order.swish_surname = 'Andersson'
      new_order.swish_phone_number = '46738471761'
      this.setState({ order: new_order })
    }
  }

  contentStuff() {
    return <TravellerInfo />
  }

  render() {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <div className="App">
          <Helmet
            titleTemplate="%s | Tågluffa i Europa"
            defaultTitle="Tågluffa i Europa"
          >
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="Ska du ut och tågluffa? Då har du hamnat helt rätt. Köp ditt interrailkort redan idag och förbered dig inför 2024 års stora äventyr. Vi hjälper dig till en perfekt resa ut i Europa!"
            />
            <meta
              name="keywords"
              content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort"
            />
            <link rel="canonical" href="https://tagluffaieuropa.se" />
          </Helmet>

          <MuiThemeProvider theme={THEME}>
            <Router history={useHistory}>
              <ScrollToTop />
              <Header
                toggle_drawer={this.toggle_drawer}
                shopping_cart={this.state.shopping_cart}
                current_tab={this.state.current_tab}
                change_tab={this.change_tab}
                just_added_to_shopping_cart={
                  this.state.just_added_to_shopping_cart
                }
                gtag={gtag}
              />

              <ShoppingCartDrawer
                open={this.state.drawer_open}
                shopping_cart={this.state.shopping_cart}
                toggle_drawer={this.toggle_drawer}
                remove_from_shopping_cart={this.remove_from_shopping_cart}
                add_to_shopping_cart={this.add_to_shopping_cart}
                summarize_price={this.summarize_price}
                count_types={this.count_types}
                change_tab={this.change_tab}
                are_tickets_valid={this.areTicketsValid}
                tickets_valid={this.state.tickets_valid}
              />
              <CookieConsentPopUp gtag={gtag} />
              <Content
                shopping_cart={this.state.shopping_cart}
                tickets_valid={this.state.tickets_valid}
                current_new_ticket_index={this.state.current_new_ticket_index}
                order={this.state.order}
                update_order={this.update_order}
                add_to_shopping_cart={this.add_to_shopping_cart}
                update_ticket={this.update_ticket}
                remove_from_shopping_cart={this.remove_from_shopping_cart}
                summarize_price={this.summarize_price}
                are_tickets_valid={this.areTicketsValid}
                set_tickets_valid_to_false={this.setValidTicketsToFalse}
                find_item_in_shopping_cart={this.find_item_in_shopping_cart}
                modify_ticket_index={this.modify_ticket_index}
                flush_shopping_cart={this.flush_shopping_cart}
                backend_adress={backend_adress}
                gtag={gtag}
              />
              <Footer />
            </Router>
          </MuiThemeProvider>
          {/*<CookieBanner />*/}
        </div>
      </StylesProvider>
    )
  }
}

export default App

function createTicketStruct(cart) {
  // Otherwise ticket and then check age and date for current ticket
  var dates = {}
  var age_category, date, age, passModel, duration, comfort

  try {
    for (var ticket of cart) {
      if (ticket.get_type() === 'ticket') {
        age_category = ticket.get_age()
        date = ticket
          .get_first_travel_date()
          .toISOString()
          .split('T')[0]
          .concat('T00:00:00')
        passModel = ticket.get_pass_model()
        duration = ticket.get_duration()
        comfort = ticket.get_comfort()
        // Add info to request struct
        if (!dates.hasOwnProperty(date)) {
          dates[date] = {}
        }
        if (!dates[date].hasOwnProperty(passModel)) {
          dates[date][passModel] = {}
        }
        if (!dates[date][passModel].hasOwnProperty(duration)) {
          dates[date][passModel][duration] = {}
        }
        if (!dates[date][passModel][duration].hasOwnProperty(comfort)) {
          dates[date][passModel][duration][comfort] = {
            UniquePassangers: {},
            UniqueIDs: [],
          }
        }
        if (
          dates[date][passModel][duration][
            comfort
          ].UniquePassangers.hasOwnProperty(age_category)
        ) {
          dates[date][passModel][duration][comfort].UniquePassangers[
            age_category
          ] =
            dates[date][passModel][duration][comfort].UniquePassangers[
              age_category
            ] + 1
        } else {
          dates[date][passModel][duration][comfort].UniquePassangers[
            age_category
          ] = 1
        }
        dates[date][passModel][duration][comfort].UniqueIDs.push(
          ticket.get_id()
        )
      }
    }
  } catch (error) {
    dates = null
  }
  return dates
}

function updateChildPrices(
  ticketStruct,
  cart,
  find_item,
  update_shopping_cart
) {
  // Detect every separate order that will occur
  var separateOrders = []
  for (var date in ticketStruct) {
    for (var type in ticketStruct[date]) {
      for (var duration in ticketStruct[date][type]) {
        for (var comfort in ticketStruct[date][type][duration]) {
          separateOrders.push(ticketStruct[date][type][duration][comfort])
        }
      }
    }
  }

  // Find child orders
  var lonelyChild = []
  var accompaniedChild = []
  for (var i = separateOrders.length - 1; i >= 0; i--) {
    // Loop from end of array to start
    if (!separateOrders[i].UniquePassangers.hasOwnProperty('CHD')) {
      separateOrders.splice(i, 1)
    } else {
      if (separateOrders[i].UniquePassangers.hasOwnProperty('ADT')) {
        // Child only travels free with adult company
        accompaniedChild = accompaniedChild.concat(
          extractChildID(separateOrders[i].UniqueIDs, cart, find_item)
        )
      } else {
        // Child doesn't travel for free without presence of adult passenger
        lonelyChild = lonelyChild.concat(
          extractChildID(separateOrders[i].UniqueIDs, cart, find_item)
        )
      }
    }
  }

  // Update prices for all children based on their company
  var new_price
  var index
  for (var id of lonelyChild) {
    index = find_item(id)
    fetch(backend_adress + '/lonelychildprice/?name=' + cart[index].get_name())
      .then((resp) => resp.json())
      .then((data) => {
        new_price = JSON.parse(data.ans[0].lonelychild)[
          cart[index].get_comfort()
        ]
        if (cart[index].get_price() != new_price) {
          cart[index].set_price(new_price)
        } else {
          return null
        }
        return cart
      })
      .then((data) => {
        if (data) {
          update_shopping_cart(data)
        }
      })
      .catch(function (error) {
        console.log('Following error occurred when updating prices: ', error)
      })
  }

  for (var id of accompaniedChild) {
    index = find_item(id)
    fetch(backend_adress + '/ticketprice/?sku=' + cart[index].get_sku())
      .then((resp) => resp.json())
      .then((data) => {
        new_price = JSON.parse(data.ans[0].price)['CHD'][
          cart[index].get_comfort()
        ]
        if (cart[index].get_price() != new_price) {
          cart[index].set_price(new_price)
        } else {
          return null
        }
        return cart
      })
      .then((data) => {
        if (data) {
          update_shopping_cart(data)
        }
      })
      .catch(function (error) {
        console.log('Following error occurred when updating prices: ', error)
      })
  }

  return null
}

function extractChildID(IDs, shopping_cart, find_item) {
  var childIDs = []
  const cart = shopping_cart
  for (var id of IDs) {
    const index = find_item(id)
    if (cart[index].get_age() == 'CHD') {
      childIDs.push(id)
    }
  }
  return childIDs
}
