import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { act } from "react-dom/test-utils";

import order_class from "./../order_class";

import refineRailBookAvailResponse from "./functions/refineRailBookAvailResponse";

const xml2js = require("xml2js");

let container = null;
beforeEach(() => {
	// setup a DOM element as a render target
	container = document.createElement("div");
	document.body.appendChild(container);
});

afterEach(() => {
	// cleanup on exiting
	unmountComponentAtNode(container);
	container.remove();
	container = null;
});

/*
it("Running interrail transformation function - BASIC", () => {
	act(() => {
		var rs = {
			ACP_RailPassAvailRS: {
				$: {
					Target: "Production",
					TimeStamp: "2020-11-22T06:47:59",
					Varsion: "1.001",
					xmlns: "http://www.acprailinternational.com/API/R2",
					"xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
					"xsi:schemaLocation":
						"http://www.acprailinternational.com/API/R2 ACP_RailPassAvailRS.xsd",
				},
				CountryLists: [""],
				Fares: [""],
				PassOptions: [""],
				Success: [""],
			},
		};
		//console.log(rs);

		var rq = refineRailBookAvailResponse(rs);
		//console.log(rq);
		expect(rq).toStrictEqual(rs);
	});
});
*/

it("Running interrail transformation function - VALID", () => {
	act(() => {
		var rs = {
			ACP_RailPassAvailRS: {
				$: {
					Target: "Production",
					TimeStamp: "2020-12-04T06:47:59",
					Varsion: "1.001",
					xmlns: "http://www.acprailinternational.com/API/R2",
					"xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
					"xsi:schemaLocation":
						"http://www.acprailinternational.com/API/R2 ACP_RailPassAvailRS.xsd",
				},
				CountryLists: [
					{
						CountryList: [
							{
								$: {
									CountryListReference: "1",
									ListType: "Permitted",
								},
								CountryISO: [
									"AL",
									"AS",
									"AT",
									"BA",
									"BE",
									"BG",
									"BY",
									"CH",
									"CY",
									"CZ",
									"DE",
									"DK",
									"EE",
									"ES",
									"FI",
									"FR",
									"GB",
									"GR",
									"HR",
									"HU",
									"IE",
									"IS",
									"IT",
									"LI",
									"LT",
									"LU",
									"LV",
									"MC",
									"MD",
									"ME",
									"MK",
									"MT",
									"NL",
									"NO",
									"PL",
									"PT",
									"RO",
									"RS",
									"RU",
									"SE",
									"SI",
									"SK",
									"SM",
									"TR",
									"UA",
									"VA",
								],
							},
						],
					},
				],
				Fares: [
					{
						Fare: [
							{
								$: {
									FareReference: "1",
									Class: "FIRST",
									TicketingTimeLimit: "2020-12-12T23:59:59.0Z",
									TicketCount: "1",
									DateOfBirthRequired: "true",
									EmailRequired: "false",
									FareReference: "1",
									FareType: "Regular",
									IncludesProtectionPlan: "false",
									IsEstimated: "false",
									IsRoundTrip: "false",
									Magic:
										"649FB6A7A7E7375B0314B271B57AAF6A↵789C6D4FBB0E823014DDFB1515E71231EAD08D48481C3498E244180A36A6A6B4↵865E8CFEBDA53E026A72977BCEC9794C0B539D450D135C207B5112C8A595B5A0↵783A2072035C65BF78CA5B87AC96B3708672B6A6781E2E50DC984E430FF7DFDA↵348DB4561A4D712FDB890FC5447B7596A9104FAA2C51C66F89002ED5388729A7↵DB241447CEEFE8E4419CE401E227DF471B2D4AB4EFB80609F75E74D0125E75BF↵CAF925DE6E5C93A5CF5CEBBC03B495B7CF5B90A844A43616DE66A4E276104C8E↵95553E2BFA37C9DF03086669F966010000↵",
									NationalityRequired: "false",
									PassportRequired: "true",
									PaxNameRequested: "true",
									PaxPhoneRequired: "false",
									PaxUnits: "1",
									PlaceOfBirthRequired: "false",
									PricingOnRequest: "false",
									TicketCount: "1",
									TicketOption: "Paper",
									TicketingTimeLimit: "2020-12-12T23:59:59.0Z",
									TravelDate: "2020-12-14",
									UseAfterTL: "2020-12-14",
									UseByTL: "2020-12-28",
								},
								IntegratedPassengerTypePrices: [
									{
										PassengerMixSlice: [
											{
												$: {
													Code: "ADT",
													Quantity: "1",
													SFDetails: "",
													SliceID: "1",
													TSC: "2.40",
													TotalSliceAmount: "652.40",
													UnitPrice: "650.00",
												},
												MixDetails: [
													{ PassengerPlaceholder: [{ $: { Age: "-1" } }] },
												],
											},
										],
									},
								],
								IntegratedTotalPrice: [
									{
										$: {
											Amount: "652.40",
											Commission: "0.00",
											Fare: "650.00",
											Net: "652.40",
											ServiceFee: "0.0",
											TSC: "2.40",
										},
									},
								],
								PassengerTypePrices: [
									{
										PassengerMixSlice: [
											{
												$: {
													Code: "ADT",
													Quantity: "1",
													SFDetails: "",
													SliceID: "1",
													TSC: "2.40",
													TotalSliceAmount: "652.40",
													UnitPrice: "650.00",
												},
												MixDetails: [
													{ PassengerPlaceholder: [{ $: { Age: "-1" } }] },
												],
											},
										],
									},
								],
								SalesConditions: [
									{
										$: {
											RefundPolicy:
												"Refundable up to the day before the start date pri…nvalidated passes, tickets and exchange vouchers.",
										},
										RefundRules: [
											{
												RefundRule: [
													({
														$: {
															Sequence: "1",
															DateBasis: "Travel",
															WithinDateBasis: "-P5Y",
															PenaltyRate: "15.0",
															MinimumPenalty: "0.0",
														},
													},
													{
														$: {
															Sequence: "2",
															DateBasis: "Travel",
															WithinDateBasis: "P0D",
															PenaltyRate: "100.0",
															MinimumPenalty: "0.0",
														},
													}),
												],
											},
										],
										TermsAndConditions: [
											{ $: { MustAcknowledge: "false", URL: "" } },
										],
									},
								],
								SubComponents: [""],
								TotalPrice: [
									{
										$: {
											Amount: "652.40",
											Commission: "0.00",
											Fare: "650.00",
											Net: "652.40",
											ServiceFee: "0.0",
											TSC: "2.40",
										},
									},
								],
								Validity: [
									{
										Duration: [{ $: { Length: "P15D" } }],
									},
								],
							},
							{
								foo: "Fare2",
							},
							{
								foo: "Fare3",
							},
							{
								foo: "Fare4",
							},
							{
								foo: "Fare5",
							},
							{
								foo: "Fare6",
							},
						],
					},
				],
				PassOptions: [
					{
						PassOption: [
							{
								$: {
									IsSubComponent: "false",
									OperatorName: "SJ-InterRail",
									OptionReference: "1",
									PassModel: "Consecutive",
									ProductName: "INTERRAIL GLOBAL CONSECUTIVE PASS",
									ServiceType: "Transport",
								},
								FareRPHs: [
									{
										FareRPH: ["1", "2", "3", "4", "5", "6"],
									},
								],
								ProdMarketingName: [
									'<div id="DisplayName">INTERRAIL GLOBAL CONSECUTIVE PASS</div>',
								],
							},
							{
								$: {
									IsSubComponent: "false",
									OperatorName: "SJ-InterRail",
									OptionReference: "2",
									PassModel: "Flexible",
									ProductName: "INTERRAIL GLOBAL FLEXI PASS",
									ServiceType: "Transport",
								},
								FareRPHs: [
									{
										FareRPH: ["7", "8", "9", "10", "11", "12", "13", "14"],
									},
								],
								ProdMarketingName: [
									'<div id="DisplayName">INTERRAIL GLOBAL FLEXI PASS</div>',
								],
							},
						],
					},
				],
				Success: [""],
			},
		};

		var desiredOutput = {
			ACP_RailBookRQ: {
				$: {
					xmlns: "http://www.acprailinternational.com/API/R2",
					PrimaryLangID: "en",
					SequenceNmbr: "1",
					Version: "1.002",
					EchoToken: "7656",
				},
				POS: {
					RequestorID: "emil-xml",
				},
				RailBookInfo: {
					$: {
						TicketQueue: "",
						ContactEmail: "emilkjandersson@gmail.com",
						POSReference: "",
					},
					SelectedOptions: {
						SelectedOption: {
							$: {
								TicketOption: "Paper",
								IsCreditSale: "true",
								IsSubcomponent: "false",
								ID: "1",
							},
							PassFare: {
								$: {
									FareReference: "1",
									Class: "FIRST",
									TicketingTimeLimit: "2020-12-12T23:59:59.0Z",
									TicketCount: "1",
									DateOfBirthRequired: "true",
									EmailRequired: "false",
									FareReference: "1",
									FareType: "Regular",
									IncludesProtectionPlan: "false",
									IsEstimated: "false",
									IsRoundTrip: "false",
									Magic:
										"649FB6A7A7E7375B0314B271B57AAF6A↵789C6D4FBB0E823014DDFB1515E71231EAD08D48481C3498E244180A36A6A6B4↵865E8CFEBDA53E026A72977BCEC9794C0B539D450D135C207B5112C8A595B5A0↵783A2072035C65BF78CA5B87AC96B3708672B6A6781E2E50DC984E430FF7DFDA↵348DB4561A4D712FDB890FC5447B7596A9104FAA2C51C66F89002ED5388729A7↵DB241447CEEFE8E4419CE401E227DF471B2D4AB4EFB80609F75E74D0125E75BF↵CAF925DE6E5C93A5CF5CEBBC03B495B7CF5B90A844A43616DE66A4E276104C8E↵95553E2BFA37C9DF03086669F966010000↵",
									NationalityRequired: "false",
									PassportRequired: "true",
									PaxNameRequested: "true",
									PaxPhoneRequired: "false",
									PaxUnits: "1",
									PlaceOfBirthRequired: "false",
									PricingOnRequest: "false",
									TicketCount: "1",
									TicketOption: "Paper",
									TicketingTimeLimit: "2020-12-12T23:59:59.0Z",
									TravelDate: "2020-12-14",
									UseAfterTL: "2020-12-14",
									UseByTL: "2020-12-28",
								},
								IntegratedPassengerTypePrices: [
									{
										PassengerMixSlice: [
											{
												$: {
													Code: "ADT",
													Quantity: "1",
													SFDetails: "",
													SliceID: "1",
													TSC: "2.40",
													TotalSliceAmount: "652.40",
													UnitPrice: "650.00",
												},
												MixDetails: [
													{ PassengerPlaceholder: [{ $: { Age: "-1" } }] },
												],
											},
										],
									},
								],
								IntegratedTotalPrice: [
									{
										$: {
											Amount: "652.40",
											Commission: "0.00",
											Fare: "650.00",
											Net: "652.40",
											ServiceFee: "0.0",
											TSC: "2.40",
										},
									},
								],
								PassengerTypePrices: [
									{
										PassengerMixSlice: [
											{
												$: {
													Code: "ADT",
													Quantity: "1",
													SFDetails: "",
													SliceID: "1",
													TSC: "2.40",
													TotalSliceAmount: "652.40",
													UnitPrice: "650.00",
												},
												MixDetails: [
													{ PassengerPlaceholder: [{ $: { Age: "-1" } }] },
												],
											},
										],
									},
								],
								SalesConditions: [
									{
										$: {
											RefundPolicy:
												"Refundable up to the day before the start date pri…nvalidated passes, tickets and exchange vouchers.",
										},
										RefundRules: [
											{
												RefundRule: [
													({
														$: {
															Sequence: "1",
															DateBasis: "Travel",
															WithinDateBasis: "-P5Y",
															PenaltyRate: "15.0",
															MinimumPenalty: "0.0",
														},
													},
													{
														$: {
															Sequence: "2",
															DateBasis: "Travel",
															WithinDateBasis: "P0D",
															PenaltyRate: "100.0",
															MinimumPenalty: "0.0",
														},
													}),
												],
											},
										],
										TermsAndConditions: [
											{ $: { MustAcknowledge: "false", URL: "" } },
										],
									},
								],
								SubComponents: [""],
								TotalPrice: [
									{
										$: {
											Amount: "652.40",
											Commission: "0.00",
											Fare: "650.00",
											Net: "652.40",
											ServiceFee: "0.0",
											TSC: "2.40",
										},
									},
								],
								Validity: [
									{
										Duration: [{ $: { Length: "P15D" } }],
									},
								],
							},
							PassOption: {
								$: {
									IsSubComponent: "false",
									OperatorName: "SJ-InterRail",
									OptionReference: "1",
									PassModel: "Consecutive",
									ProductName: "INTERRAIL GLOBAL CONSECUTIVE PASS",
									ServiceType: "Transport",
								},
								FareRPHs: [
									{
										FareRPH: ["1", "2", "3", "4", "5", "6"],
									},
								],
								ProdMarketingName: [
									'<div id="DisplayName">INTERRAIL GLOBAL CONSECUTIVE PASS</div>',
								],
							},
							PassengerIndex: {
								Passenger: {
									$: {
										SliceID: "1",
										PassengerID: "1",
									},
								},
							},
							PaymentIndex: [""],
							CountryLists: [
								{
									CountryList: [
										{
											$: {
												CountryListReference: "1",
												ListType: "Permitted",
											},
											CountryISO: [
												"AL",
												"AS",
												"AT",
												"BA",
												"BE",
												"BG",
												"BY",
												"CH",
												"CY",
												"CZ",
												"DE",
												"DK",
												"EE",
												"ES",
												"FI",
												"FR",
												"GB",
												"GR",
												"HR",
												"HU",
												"IE",
												"IS",
												"IT",
												"LI",
												"LT",
												"LU",
												"LV",
												"MC",
												"MD",
												"ME",
												"MK",
												"MT",
												"NL",
												"NO",
												"PL",
												"PT",
												"RO",
												"RS",
												"RU",
												"SE",
												"SI",
												"SK",
												"SM",
												"TR",
												"UA",
												"VA",
											],
										},
									],
								},
							],
						},
					},
					Remarks: [""],
					Payments: [""],
					Passengers: {
						Passenger: {
							$: {
								IsLeader: "false",
								PhoneNumber: null,
								Email: "email@gmail.com",
								YearOfBirth: "1994",
								MonthOfBirth: "10",
								DayOfBirth: "21",
								PassportNumber: "passport number",
								PlaceOfBirthCountry: "CA",
								PlaceOfBirthCity: "oqwdqwopk",
								Nationality: "CA",
								CountryResidence: "HR",
								Age: "-1",
								Surname: null,
								GivenName: null,
								NamePrefix: "",
								ID: "1",
							},
						},
					},
				},
			},
		};
		var dummy_order = new order_class();
		dummy_order.email = "email@gmail.com";
		var dummy_shopping_cart = [];
		var dummy_RailPassAvailRQ = {
			comfort: "FIRST",
			duration: "P15D",
			passModel: "Consecutive",
		};
		var rq = refineRailBookAvailResponse(
			rs,
			dummy_order,
			dummy_shopping_cart,
			dummy_RailPassAvailRQ
		);
		expect(rq).toStrictEqual(desiredOutput);
	});
});
