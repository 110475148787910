import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import mainLogo from '../tagluffaieuropa_logo.svg'
import interrailLogo from '../assets/SVG/interrail_logo.svg'
import { primary, secondary } from 'styles'
//import { useMediaQuery } from 'react-responsive'
import { Grid } from '@material-ui/core'

function FullPageHypeTimer() {
  var endTime = new Date('2023-10-26T23:59:59') / 1000

  var elapsed = new Date() / 1000
  var totalSec = endTime - elapsed
  var h = parseInt(totalSec / 3600)
  var m = parseInt(totalSec / 60) % 60
  var s = parseInt(totalSec % 60, 10)

  const [rHour, setRHour] = useState(h)
  const [rMin, setRMin] = useState(m)
  const [rSec, setRSec] = useState(s)

  function getTime() {
    var elapsed = new Date() / 1000
    var totalSec = endTime - elapsed
    var h = parseInt(totalSec / 3600)
    var m = parseInt(totalSec / 60) % 60
    var s = parseInt(totalSec % 60, 10)

    setRHour(h)
    setRMin(m)
    setRSec(s)
    console.log('count')
  }

  function end() {
    console.log('End')
  }

  useEffect(() => {
    const i = setInterval(getTime, 1000)
    return () => clearInterval(i)
  }, [end])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <div
        style={{
          width: 'calc(100% - 4rem)',
          height: 'calc(100% - 4rem)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '2rem',
          border: '1px solid black',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            alignItems: 'center'
            
          }}
        >
          <img src={mainLogo} width="80%" alt="LOGO" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
              fontSize: '14vw',
              color: '#474F3E',
            }}
          >
            <span className="num-span">{rHour}</span>
            <span className="segment">:</span>
            <span className="num-span">{('0' + rMin).slice(-2)}</span>
            <span className="segment">:</span>
            <span className="num-span">{('0' + rSec).slice(-2)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageHypeTimer
