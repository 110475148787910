import React from 'react'
import { styles } from 'styles'
import {
  Grid,
  Box,
  Divider,
  Typography,
  Button,
  Dialog,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import backendAdress from '../../backendAdress'
import Alert from '@material-ui/lab/Alert'
import postEurailAPI from './../functions/postEurailAPI'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import order_class from '../../order_class'
import ReturnTicketList from './returnTicketList'
import FieldWrapper from 'Components/fieldWrapper'
import { useHistory } from 'react-router-dom'
import sendReturnMail from './sendReturnMail'

function ReturnView(props) {
  const classes = styles()

  const history = useHistory()

  const [errorAlert, setErrorAlert] = React.useState(null)
  const [refundErrorAlert, setRefundErrorAlert] = React.useState(null)
  const [idError, setIdError] = React.useState(null)
  const [id, setId] = React.useState(null)
  const [order, setOrder] = React.useState(null)
  const [ticketsToReturn, setTicketsToReturn] = React.useState([]) // Actual tickets to return
  const [ticketsIDsToReturn, setTicketIDsToReturn] = React.useState([]) // Eurial ticket id's to return
  const [waiting, setWaiting] = React.useState(null)
  const [returnDialogOpen, setReturnDialogOpen] = React.useState(false)

  const backend_adress = backendAdress

  const handleId = (event) => {
    setId(event.target.value)
    if (event.target.value == '') {
      setIdError('Skriv in ditt ordernummer')
    } else {
      setIdError(null)
    }
  }

  async function returnTickets(tickets) {
    // Return the tickets chosen by the customer to be returned. NOT all of the returnable.
    // Firstly, check whether the new order would be valid.
    if (order.is_order_invalid()) {
      setRefundErrorAlert(
        order.is_order_invalid() +
          '. Åtgärda det och fortsätt sedan till returnering.'
      )
      return
    }

    props.gtag('event', 'refund')

    // Proceed with refund.
    setWaiting(true)
    setReturnDialogOpen(true)
    var message = {
      status: 400,
      statusMessage: 'Unspecified error',
      body: '',
      concatenatedError: [],
      error: '',
    }

    //await tickets.map((ticket) => {
    await returnATicket(order.eurail_order_id, tickets, message).then(
      //ticket.get_eurail_ticket_id, message).then(
      (result) => {
        if (result.error) {
          var errorMessage
          switch (
            result.concatenatedError[result.concatenatedError.length - 1]
          ) {
            case 'Not Found':
              errorMessage =
                'Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.'
              break
            case 'Preparing swish payment did not succeed':
              if (result.concatenatedError[0] == 'Payer not Enrolled') {
                errorMessage = 'Felaktigt swishnummer.'
              }
              break
            case 'Swish payment failed.':
              errorMessage =
                'Swishbetalning genomfördes inte. Var god försök igen. Om det inte fungerar då, var vänlig kontakta kundservice.'
              break
            case 'Create order did not execute properly':
              //history.push("/order-error");
              break
            default:
              console.log('The error:', result.error)
              errorMessage = result.error.error
              break
          }
          setRefundErrorAlert(errorMessage)
          console.log(errorMessage)
          setReturnDialogOpen(false)
        } else {
          sendReturnMail(
            order,
            ticketsToReturn,
            getPriceOfTickets(ticketsIDsToReturn) - getFee(ticketsIDsToReturn),
            backend_adress
          ).then((response) => {
            if (response == 'success') {
              console.log('Confirmation mail successfully sent.')
            } else {
              console.log('Failed to send confirmation mail.')
            }
          })
          setWaiting(false)
          // Send to confirmation page
          history.push('/return-confirmation')
        }
      }
    )
    return
  }

  async function updateOrderVersion(tempOrder, message) {
    // Get order from Eurail API
    await getEurailOrder(tempOrder.get_eurail_order_id(), message).then(
      (result) => {
        if (result.error) {
          var errorMessage
          switch (
            result.concatenatedError[result.concatenatedError.length - 1]
          ) {
            case 'Not Found':
              errorMessage =
                'Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.'
              break
          }
          setErrorAlert(errorMessage)
          return
        }
        if ('body' in result) {
          tempOrder.set_eurail_booking(result.body)
        }
      }
    )
    // Update database with Eurail order info
    return tempOrder
  }

  async function getRefundableTickets() {
    var message = {
      status: 400,
      statusMessage: 'Unspecified error',
      body: '',
      concatenatedError: [],
      error: '',
    }

    // Start by calling our dB and receive the order connected to the entered id
    // - Create an instance of that order
    /*     var testOrder = new order_class();
    	console.log("Setting testOrder");
    	testOrder.fromJSON(JSON.parse(JSON.stringify(new_order)));
   		console.log("Set testOrder to:", testOrder); */
    let tempOrder = await getOrder(id)
    if (!tempOrder) {
      setErrorAlert(
        'Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.'
      )
      setWaiting(false)
      return
    }
    tempOrder = await updateOrderVersion(tempOrder, message)
    // - Achieve its eurail order id and pass it into the getTicketStatus(id) function.

    await getTicketStatus(tempOrder.get_eurail_order_id(), message).then(
      (result) => {
        console.log('result:', result)
        if (result.error) {
          var errorMessage
          switch (
            result.concatenatedError[result.concatenatedError.length - 1]
          ) {
            case 'Not Found':
              errorMessage =
                'Ordern gick inte att hitta. Säkerställ att ditt ordernummer är korrekt.'
              break
          }
          setErrorAlert(errorMessage)
          return
        }
        if ('body' in result) {
          var index = 0
          tempOrder.order.map((ticket) => {
            if (ticket.get_type() === 'ticket') {
              for (let i = 0; i < result.body.lineItems.length; i++) {
                console.log(result.body.lineItems[i].id)
                console.log(ticket.get_eurail_ticket_id())
                if (
                  result.body.lineItems[i].id == ticket.get_eurail_ticket_id()
                ) {
                  ticket.set_refundable(true)
                }
              }

              index += 1
            }
          })
          setOrder(tempOrder)
        }
        if (result == true) {
          //history.push("/buy/sendof");
        }
      }
    )
    console.log('setting order to:', tempOrder)
    setWaiting(false)
  }

  function showAlert(errorMessage) {
    if (errorMessage) {
      console.log(errorMessage)
      return <Alert severity="error">{errorMessage}</Alert>
    } else {
      return null
    }
  }

  async function getTicketStatus(id, message) {
    message.body = id
    var result = await postEurailAPI(message, 'refundable', backend_adress)

    return result
  }

  async function getEurailOrder(id, message) {
    message.body = id
    var result = await postEurailAPI(message, 'get_order', backend_adress)

    return result
  }

  async function returnATicket(orderId, ticketIds, message) {
    // This is returning an entire order at this stage

    let lineItems = []

    ticketIds.forEach((element) => {
      lineItems.push({ id: element, quantity: 1 })
    })

    console.log('version' + order.eurail_booking.version)

    message.body = {
      message: {
        refundPaymentType: 'auto',
        lineItems: lineItems,
        refundReason: {
          reason: 'unknown',
          replaceOption: false,
          explanation: 'Unknown',
        },
        refundState: 'line-item-mobile-refund-requested',
      },
      order: orderId,
      version: order.eurail_booking.version,
    }
    var result = await postEurailAPI(message, 'refund', backend_adress)
      .then((result) => {
        console.log(result)
        /*if ("status" in result && "body" in result) {
					if (result.status < 400) {
						order.set_eurail_booking(result.body);
						const url = backend_adress + "/update_order";
						fetch(url, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								order: order,
							}),
						});
					}
				}*/
        return result
      })
      .catch(function (error) {
        console.log('Request failure: ', error)
      })
    console.log(result)

    // Update order in dB with new Eurail order dump existing in result variable
    if ('status' in result && 'body' in result) {
      console.log('here')
      if (result.status < 400) {
        console.log('now')
        order.set_eurail_booking(result.body)
        const url = backend_adress + '/update_order'
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order: order,
            id: id,
          }),
        }).catch(function (error) {
          console.log('Request failure: ', error)
        })
      }
    }

    return result
  }

  async function getOrder(id) {
    const url = backend_adress + '/getOrderFromDB'
    let res = fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (1 > data.ans.length) {
          return
        }
        let order = new order_class()
        order.fromJSON(JSON.parse(data.ans[0].order))
        return order
      })
    return res
  }

  function handleGetOrderClick() {
    setWaiting(true)
    setOrder(null)
    setErrorAlert(null)
    setRefundErrorAlert(null)
    setTicketIDsToReturn([])
    getRefundableTickets()
    /* getOrder(id); */
  }

  function handleChangeOrderClick() {
    setWaiting(false)
    setOrder(null)
    setErrorAlert(null)
    setRefundErrorAlert(null)
    setTicketIDsToReturn([])
    setId('')
  }

  function handleCheckReturn(checked, ticket) {
    setRefundErrorAlert(null)
    if (checked) {
      var temp = [...ticketsIDsToReturn]
      temp.push(ticket.eurail_ticket_id)
      setTicketIDsToReturn(temp)
      ticket.set_status('refunded')
    } else {
      const index = ticketsIDsToReturn.indexOf(ticket.eurail_ticket_id)
      if (index > -1) {
        var temp = [...ticketsIDsToReturn]
        temp.splice(index, 1)
        setTicketIDsToReturn(temp)
        ticket.set_status('purchased')
      }
    }
  }

  function getPriceOfTickets(tickets) {
    var total = 0
    var ticket_list = getTicketsFromEurailID(tickets)
    ticket_list.forEach((element) => {
      total += element.get_price()
    })
    return total
  }

  function getTicketsFromEurailID(id) {
    var ticket_list = []
    id.forEach((uniqueID) => {
      order.order.forEach((ticket) => {
        if (ticket.get_eurail_ticket_id() == uniqueID) {
          ticket_list.push(ticket)
        }
      })
    })
    if (ticket_list.length != ticketsToReturn.length) {
      setTicketsToReturn(ticket_list)
    }
    return ticket_list
  }

  function getFee(tickets) {
    var price = getPriceOfTickets(tickets)
    var free_return = freeReturn()
    var fee = free_return ? 0 : Math.ceil(price * 0.05)
    return fee
  }

  function freeReturn() {
    //Placeholder to determine if an order is being returned within 14 days and thus is covered by ångerrätten.
    //Then return True
    var order_time = new Date(order.get_eurail_booking().createdAt)
    var time_now = new Date()

    // To calculate the time difference of two dates
    var Difference_In_Time = time_now.getTime() - order_time.getTime()

    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24))

    // You have the right to return if it has not been more than 14 days since the order was created
    var right_to_return = Difference_In_Days <= 14 ? true : false

    console.log('The right to return is: ', right_to_return)

    return right_to_return
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h1" align="center">
                <Box fontWeight="fontWeightLight">
                  <p>Returnering av order</p>
                </Box>
              </Typography>
              <Typography variant="body">
                <div>
                  Vill du returnera en eller flera av dina biljetter? Ange ditt
                  bokningsnummer nedan så hämtar vi din bokning.<br></br>
                  <br></br>
                  Det är möjligt att returnera delar av en bokning förutsatt att
                  den kvarvarande bokningen uppfyller kraven på en godkänd
                  bokning.
                  <br></br>
                  <br></br>
                  Det går tyvärr inte att returnera en bokning som redan
                  aktiverat.
                </div>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {showAlert(errorAlert)}
            </Grid>
            <Grid item xs={8}>
              {order ? (
                <TextField
                  disabled
                  error={idError}
                  id="ordernumber"
                  label="Ordernummer"
                  type="text"
                  autoComplete="off"
                  variant="filled"
                  value={id}
                  onChange={handleId}
                  fullWidth
                  helperText={idError}
                />
              ) : (
                <TextField
                  error={idError}
                  id="ordernumber"
                  label="Ordernummer"
                  type="text"
                  autoComplete="off"
                  variant="filled"
                  value={id}
                  onChange={handleId}
                  fullWidth
                  helperText={idError}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {id ? (
                waiting && !order ? (
                  <Button
                    variant="contained"
                    className={classes.showOrderButton}
                    color="primary"
                    onClick={() => handleGetOrderClick()}
                  >
                    <CircularProgress color="inherit" size={24} />
                  </Button>
                ) : !order ? (
                  <Button
                    variant="contained"
                    className={classes.showOrderButton}
                    color="primary"
                    onClick={() => handleGetOrderClick()}
                  >
                    Visa min order
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.showOrderButton}
                    color="primary"
                    onClick={() => handleChangeOrderClick()}
                  >
                    Ändra ordernummer
                  </Button>
                )
              ) : (
                <Button
                  disabled
                  variant="contained"
                  className={classes.showOrderButton}
                  color="primary"
                  onClick={() => handleGetOrderClick()}
                >
                  Visa min order
                </Button>
              )}
            </Grid>
            {order ? (
              <>
                <Grid item xs={12}>
                  <Typography align={'left'}>Interrailkort</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ReturnTicketList
                    tickets={order.order}
                    handleCheckFunction={handleCheckReturn}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      height: '2px',
                      color: 'black',
                      backgroundColor: 'black',
                      marginBottom: '12px',
                    }}
                  />
                  <Grid container>
                    <Grid item xs={6}>
                      {<Typography>Returbiljettsvärde</Typography>}
                    </Grid>
                    <Grid item xs={6} align="right">
                      {
                        <Typography>
                          {getPriceOfTickets(ticketsIDsToReturn)}
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6}>
                      {
                        <Typography>
                          Handläggningsavgift {freeReturn() ? '0' : '5'}%
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={6} align="right">
                      {<Typography>{getFee(ticketsIDsToReturn)}</Typography>}
                    </Grid>
                  </Grid>
                  <Divider
                    style={{
                      height: '2px',
                      color: 'black',
                      backgroundColor: 'black',
                      marginBottom: '12px',
                    }}
                  />
                  <Grid container>
                    <Grid item xs={6}>
                      {<Typography>Återbetalas</Typography>}
                    </Grid>
                    <Grid item xs={6} align="right">
                      {
                        <Typography>
                          {getPriceOfTickets(ticketsIDsToReturn) -
                            getFee(ticketsIDsToReturn)}{' '}
                          SEK
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {showAlert(refundErrorAlert)}
                </Grid>
                <Grid item xs={12} align="center">
                  <Grid item xs={6} lg={4}>
                    {waiting && order ? (
                      <Button
                        variant="contained"
                        className={classes.swishButton}
                        color="primary"
                        onClick={() => returnTickets(ticketsIDsToReturn)}
                      >
                        <CircularProgress color="inherit" size={24} />
                      </Button>
                    ) : ticketsIDsToReturn.length > 0 ? (
                      <Button
                        variant="contained"
                        className={classes.swishButton}
                        color="primary"
                        onClick={() => returnTickets(ticketsIDsToReturn)}
                      >
                        Returnera
                      </Button>
                    ) : (
                      <Button
                        disabled
                        variant="contained"
                        className={classes.swishButton}
                        color="primary"
                        onClick={() => returnTickets(ticketsIDsToReturn)}
                      >
                        Returnera
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item></Grid>
            )}
          </Grid>
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={returnDialogOpen}
          >
            <MuiDialogTitle disableTypography className={classes.swishDialog}>
              <Grid
                container
                className={classes.swishDialogBox}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <div align="center">
                  <Box m={2}>
                    <img
                      style={{ width: '50%' }}
                      src={require('../../assets/tågluffaieuropa_favicon2.png')}
                      alt="Logo"
                    ></img>
                  </Box>
                  <Box m={2}>
                    <Typography> Retur genomförs... </Typography>
                  </Box>
                  <CircularProgress />
                </div>
              </Grid>
            </MuiDialogTitle>
          </Dialog>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      {/*showOrder(order)*/}
    </div>
  )
}

export default ReturnView
