import React from 'react'
import { Grid } from '@material-ui/core'
import TableComponent from '../../Components/tableComponent'
import BagSelector from './../../Components/bagselector'
import { Helmet } from 'react-helmet'

var packning = {
  content: [
    {
      title: 'Packning',
      subtitle: '',
      picPath: 'backpack.jpg',
      body: 'Något utav de besvärligare momenteten inför en resa är packningen där man behöver bestämma sig för vad som ska med och vad som skall lämnas hemma. Man vill å ena sidan inte packa för mycket, men å andra sidan inte heller skära ner på nödvändigheter. I denna guide hjälper vi dig med din packning.',
    },
    {
      title: '',
      subtitle: 'Vad ska man packa i?',
      picPath: '',
      body: 'Något som är väldigt svårt att veta innan man genomfört en resa är vad man ska packa i. Vad det står mellan är väl resväska, eller ryggsäck. Sedan kommer man dessutom till frågeställningen om hur stor denna skall vara. Att svara på dessa frågor är inte enkelt och svaret varierar naturligtvis beroende på resa, men även mellan olika personer. För att göra det enkelt skiljer vi olika resor åt beroende på terräng, resemängd och reselängd.',
    },
    {
      title: '',
      subtitle: 'Vad ska man ta med?',
      picPath: '',
      body: 'Packningen beror förstås mycket på hur länge du planerar vara borta. Det är en stor en stor skillnad på en vecka och en månad, men vi anser inte att du bör packa mera kläder för det. Kläder väger mycket och tar dessutom stor plats. Istället bör du packa kläder för en vecka och ta med tvättmedel för en månad. En mycket bra lösning.',
    },
    {
      title: '',
      subtitle: '',
      picPath: '',
      body: 'Plastpåsar får du inte glömma. De är jättelätta, små och kan vara hur nödvändiga som helst. Planerar du att utforska naturen med din packning, eller om regnet öser ner är det riktigt bra att ha plockat ner all packning i plastpåsar inuti väskan för att minimera risken att packningen blir blöt. Dessutom håller packningen sig flytande om den skulle tappas i vatten och kan det vara praktiskt att förvara smutstvätt i en plastpåse.',
    },
    {
      title: '',
      subtitle: '',
      picPath: '',
      body: <BagSelector />,
    },
    /*{
			title: "",
			subtitle: "Packlista",
			picPath: "",
			body: (
				<div>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TableComponent
										title="Resehandlingar"
										listitems={[
											"Bankomatkort",
											"Biljetter",
											"EU-kort från Försäkringskassan",
											"Försäkringspapper",
											"Hotellbokningar",
											"Hyrbilsbokningar",
											"Karta",
											"Lexikon",
											"Pass",
											"Spärrnummer",
											"Utländsk valuta",
										]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TableComponent
										title="Hygienartiklar"
										listitems={[
											"Necessär",
											"Deoderant",
											"Parfym",
											"Handduk",
											"Hårstyling",
											"Kam/Borste",
											"Lypsyl",
											"Hudkräm",
											"Nagelsax",
											"Pincett",
											"Rakningstillbehör",
											"Schampo & Tvål",
											"Smink",
											"Sminkborttagning",
											"Spegel",
											"Solskydd",
											"Tandborste",
											"Tandkräm",
											"Toalettpapper",
											"Tvättlina",
											"Tvättmedel",
											"Våtservetter",
										]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TableComponent
										title="Underhållning"
										listitems={[
											"Böcker",
											"Kortlek",
											"Hörlurar (gärna med brusreducering)",
										]}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TableComponent
										title="Elektronik"
										listitems={[
											"Eladapter",
											"Tejp, förslagsvis silvertejp",
											"Kamera",
											"Mobiltelefon",
											"Mobilladdare",
											"Extra batteri",
										]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TableComponent
										title="Kläder"
										listitems={[
											"Underkläder – strumpor, kalsonger, trosor",
											"T-shirts",
											"Shorts",
											"Badkläder",
											"Tjocktröja",
											"En findress – såsom klänning, eller kostym/skjorta & finbyxa till möjlig finmiddag",
											"Långbyxor",
											"Lämpliga skor",
											"Regnställ",
											"Skärp",
										]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TableComponent
										title="Övrigt"
										listitems={[
											"Adresser",
											"Glasögon om det behövs",
											"Klocka",
											"Kniv",
											"Korkskruv & Kapsylöppnare",
											"Liten ryggsäck",
											"Mediciner",
											"Multiverktyg",
											"Myggmedel",
											"Nål & tråd",
											"Papper & penna",
											"Plastpåsar i olika storlekar",
											"Reseguider",
											"Resväskor",
											"Skavsårsplåster",
											"Solglasögon",
											"Tofs",
											"Tändare/tändstickor",
											"Vattenflaska",
											"Vattentät säck",
										]}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			),
		},*/
  ],
  img_title: 'image title',
  img_path: 'backpack.jpg',
  title: 'Hur ska man packa?',
  header:
    'Något utav de besvärligare momenteten inför en resa är packningen där man behöver bestämma sig för vad som ska med och vad som skall lämnas hemma. Man vill å ena sidan inte packa för mycket, men å andra sidan inte heller skära ner på nödvändigheter. I denna guide hjälper vi dig med din packning.',
  sitePath: '/packning',
  helmet: (
    <Helmet>
      <meta
        name="description"
        content="Något utav de besvärligare momenteten inför en resa är packningen där man behöver bestämma sig för vad som ska med och vad som skall lämnas hemma. Man vill å ena sidan inte packa för mycket, men å andra sidan inte heller skära ner på nödvändigheter. I denna guide hjälper vi dig med din packning."
      />
      <meta
        name="keywords"
        content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, packlista, packning, resväska, backpack, packa"
      />
      <title>Packning</title>
      <link rel="canonical" href="https://tagluffaieuropa.se/guider/packning" />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Article',
          author: {
            '@type': 'Organization',
            name: 'Tågluffa i Europa',
            url: 'https://www.tagluffaieuropa.se',
          },
          image: 'https://www.tagluffaieuropa.se/static/media/backpack.jpg',
          headline: 'Packning för tågluffning',
          datePublished: '2024-02-12T08:00:00+08:00',
          dateModified: '2024-02-12T09:20:00+08:00',
          text: 'Något utav de besvärligare momenteten inför en resa är packningen där man behöver bestämma sig för vad som ska med och vad som skall lämnas hemma. Man vill å ena sidan inte packa för mycket, men å andra sidan inte heller skära ner på nödvändigheter. I denna guide hjälper vi dig med din packning.',
        })}
      </script>
    </Helmet>
  ),
}

export default packning
