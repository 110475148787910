import React from 'react'
import { Typography } from '@material-ui/core'
import { cookie_information } from './CookieInformation'

function TermsAndConditions() {
  return termsandconditions[0].body
}

var termsandconditions = [
  {
    title: 'Allmänna villkor',
    picPath: '',
    body: (
      <>
        <Typography variant="h6" gutterBottom>
          1. Om Tågluffa i Europa AB
        </Typography>
        <Typography gutterBottom>
          Företaget har sitt säte i Göteborg. Företagets postadress är
          Mölndalsvägen 39. Önskar du komma i kontakt med oss, skicka då ett
          mail till kundservice@tagluffaieuropa.se med ditt ärende och dina
          kontaktuppgifter så hör vi av oss inom kort.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Beställning
        </Typography>
        <Typography gutterBottom>
          När du slutfört din beställning skickas en orderbekräftelse till din
          e-postadress. I bekräftelsen finner du alla uppgifter om produkter,
          pris, faktureringsaddress. Är något fel i orderbekräftelsen ska du
          omedelbart kontakta oss via e-post till
          kundservice@tagluffaieuropa.se.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. Leverans
        </Typography>
        <Typography gutterBottom>
          Distributionssätt och leveranstid varierar beroende på vald produkt,
          kanal och marknad. Digitala produkter skickas i samband med slutförd
          bokning, alternativt i anslutning till avresa. När digitala produkter
          är skickade anses de vara levererade om ni inte meddelar oss att de
          saknas i så god tid att vi kan distribuera produkterna på nytt.
          Fysiska produkter skickas efter slutförd bokning med 2-5 dagars
          leveranstid inom Sverige som varubrev eller rekommenderat brev. När
          fysiska produkter har tagits emot av er, eller finns tillgängliga för
          uthämtning hos ett ombud, anses de vara levererade. Notera att
          produkterna är värdehandlingar och måste tas emot, eller hämtas ut,
          personligen av er och ni måste kunna styrka er identitet med giltig
          fotolegitimation. Fraktavgift för distribution av fysiska produkter
          debiteras enligt aktuell prislista. Om ni underlåter att hämta ut, ta
          emot, eller på annat vis förhindrar att leverans kan genomföras,
          debiterar vi en returavgift per tillfälle. Om biljetterna inte kan
          omlevereras avbokas och återköps biljetterna om möjligt. Vid
          omleverans debiterar vi ny fraktavgift för att täcka våra kostnader.
          Om förseningar i leveransen skulle uppstå (utan att vi har meddelat
          dig om längre leveranstid) ska du kontakta oss på e-postadress:
          kundservice@tagluffaieuropa.se.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Priser
        </Typography>
        <Typography gutterBottom>
          Alla priser i butiken anges i SEK och alla priser är inklusive moms.
          Vi reserverar oss för prisändringar orsakat av prisändring från
          leverantör, feltryck i prislistan samt felaktigheter i priser beroende
          på felaktig information och förbehåller oss rätten att justera priset.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Ångerrätt
        </Typography>
        <Typography gutterBottom>
          Vid köp av varor på webbplatsen har du som kund en lagstiftad 14
          dagars ångerrätt som gäller från det att du har tagit emot en vara som
          du har beställt.
          <br />
          <br />
          <b>5.1 Vid nyttjande av din ångerrätt:</b>
          <br />
          Du måste meddela att du ångrar dig. Meddelandet ska skickas till oss
          kundservice@tagluffaieuropa.se. I ditt meddelande ska ditt namn, din
          adress, e-postadress, ordernumret samt vilka varor som returneringen
          gäller framgå klart och tydligt. Du bör omedelbart och senast inom
          lagstiftad 14 dagar efter ångermeddelandet returnera produkterna till
          oss. Du står för returfrakt, leverans och skick på produkterna vid
          retur, produkterna bör därför skickas välpaketerade och i ursprunglig
          förpackning. På återbetalningsbeloppet förbehåller vi oss rätten att
          dra av en summa motsvarande värdeminskningen jämfört med varans
          ursprungliga värde vid använd eller skadad produkt.
          <br />
          <br />
          <b>5.2 Ångerrätten gäller inte vid:</b>
          <br />
          Produkter som på grund av hälso- eller hygienskäl har förseglats
          (plomberats) och där förseglingen (plomberingen) har brutits av dig.
          Produkter som har karaktär av förseglad ljud- eller bildupptagning och
          där förseglingen har brutits av dig. Specialtillverkad produkt, som
          har skräddarsytts särskilt åt dig eller har en tydlig personlig prägel
          efter dina önskemål. Tjänster som har fullgjorts och där du
          uttryckligen har samtyckt till att tjänsten påbörjas utan ångerrätt.
          Varor som snabbt kan försämras, exempelvis livsmedel. Lösnummer av
          tidningar eller tidskrifter.
        </Typography>
        <Typography variant="h6" gutterBottom>
          6. Retur
        </Typography>
        <Typography gutterBottom>
          Vid returnernering av produkter debiteras ni en returavgift för att
          täcka våra kostnader i och med handläggningen. Avgiften dras av från
          den summa som är föremål för återbetalning och motsvarar 5% av
          biljettpriset. Vid retur återbetalas alltså 95% av biljettpriset.
          Eventuellt porto ingår ej i returavgiften. Returavgiften debiteras
          inte om er retur omfattas av ångerrätten.
        </Typography>
        <Typography variant="h6" gutterBottom>
          7. Avbeställningskydd
        </Typography>
        <Typography gutterBottom>
          Avbeställningsskyddet är ett tillval till en order och innebär att du
          får pengarna tillbaka om du behöver ställa in din resa. Då återbetalas
          det fulla biljettpriset utan att behöva bekosta någon returavgift.
          Avbeställningsskyddet gäller för samtliga biljetter i beställningen.
          Biljetterna måste dock avbokas både innan dess de aktiverats och innan
          sista aktiveringsdatumet utgått.
        </Typography>
        <Typography variant="h6" gutterBottom>
          8. Reklamation och klagomål
        </Typography>
        <Typography gutterBottom>
          Vi besiktigar alla produkter innan dessa skickas till dig. Skulle
          produkten ändå vara skadad eller felexpedierad när den anländer åtar
          vi oss i enlighet med gällande konsumentskyddslagstiftning att
          kostnadsfritt åtgärda felet. Du måste alltid kontakta oss för ett
          godkännande innan du returnerar en defekt vara. Klagomålet ska skickas
          omedelbart efter att defekten har upptäckts.
          <br />
          <br />
          <b>8.1 Hur går du tillväga vid reklamation?</b>
          <br />
          Eventuella fel och defekter ska alltid reklameras till
          kundservice@tagluffaieuropa.se där du anger ditt namn, din adress,
          e-postadress, ordernummer och en beskrivning av felet. Om vi inte
          lyckas åtgärda felet eller leverera en liknande produkt, återbetalar
          vi dig för den defekta produkten i enlighet med gällande
          konsumentskyddslagstiftning. Vi står för returfrakt vid godkända
          reklamationer. Vi förbehåller oss rätten att neka en reklamation om
          det visar sig att varan inte är felaktig i enlighet med gällande
          konsumentskyddslagstiftning. Vid reklamationer följer vi riktlinjer
          från Allmänna Reklamationsnämnden, se arn.se.
        </Typography>
        <Typography variant="h6" gutterBottom>
          9. Ansvarsbegränsning
        </Typography>
        <Typography gutterBottom>
          Vi tar inget ansvar för indirekta skador som kan uppstå på grund av
          produkten. Vi accepterar inget ansvar för förseningar/fel till följd
          av omständigheter utanför företagets rådande (Force Majeure). Dessa
          omständigheter kan exempelvis vara arbetskonflikt, eldsvåda, krig,
          myndighetsbeslut, förminskad eller utebliven leverans från leverantör.
          Vidare tas inget ansvar för eventuella förändringar på
          produkter/produktegenskaper som ändrats av respektive leverantör och
          andra faktorer utanför vår kontroll.
        </Typography>
        <Typography gutterBottom>
          Vi reserverar oss för eventuella tryckfel på denna webbplats samt
          slutförsäljning av produkter. Vi garanterar inte att bilderna återger
          produkternas exakta utseende då en viss färgskillnad kan förekomma
          beroende på bildskärm, fotokvalitet samt upplösning. Vi försöker
          alltid på bästa sätt att exponera produkterna så korrekt som möjligt.
          Även om vårt mål är att presentera dig med den mest exakta
          informationen, bör du observera att informationen och innehållet på
          vår hemsida kan vara föremål för ändringar och olikheter kan uppstå
          över tid. Resenärer uppmanas att verifiera och korsreferera
          informationen med exempelvis järnvägsoperatörerna innan de gör några
          reseplaner. Tågluffa i Europa kan inte garantera absolut noggrannhet
          vid alla tidpunkter på grund av beroendet av partners och operatörer
          som äger denna information. Vi försöker alltid hålla informationen så
          uppdaterad och korrekt som möjligt.
        </Typography>
        <Typography gutterBottom>
          I och med att Tågluffa i Europa är återförsäljare av Interrail Pass så
          gäller villkoren som stadgas av Eurail. Du måste följa dessa villkor
          för att säkerställa att ditt Interrail Pass anses giltigt vid
          inspektion. Du hittar denna information här:
          <a
            href="https://www.interrail.eu/en/terms-conditions/interrail-pass-conditions-of-use"
            target="_blank"
          >
            www.interrail.eu/en/terms-conditions/interrail-pass-conditions-of-use
          </a>
          . <br />
          Genom att godkänna dessa villkor så godkänner du också Eurail's
          användningsvillkor.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Information om Cookies
        </Typography>
        {cookie_information[0].body}

        <Typography variant="h6" gutterBottom>
          11. Personuppgifter
        </Typography>
        <Typography gutterBottom>
          Genom att handla hos Tågluffa i Europa AB accepterar du vår
          dataskyddspolicy och vår behandling av dina personuppgifter. Vi värnar
          om din personliga integritet och samlar inte in fler uppgifter än
          nödvändigt för att behandla din beställning. Vi säljer eller vidareger
          aldrig dina uppgifter till tredjepart utan rättslig grund. Tågluffa i
          Europa AB är ansvariga för behandlingen av personuppgifter som du
          lämnat till oss som kund. Dina personuppgifter behandlas av oss för
          att kunna hantera din beställning samt i de tillfällen då du har
          önskat nyhetsbrev eller kampanjerbjudanden - för att kunna anpassa
          marknadsföringen åt dina individuella behov. Nedanstående information
          är en summering av hur vi i enlighet med dataskyddsförordningen (GDPR)
          lagrar och behandlar dina uppgifter.
          <br />
          <br />
          <b>11.1 Vad är en personuppgift?</b>
          <br />
          En personuppgift är all information som direkt eller indirekt kan
          hänföras till en fysisk person.
          <br />
          <br />
          <b>11.2 Vilka uppgifter lagrar vi?</b>
          <br />
          För att kunna hantera din beställning samt svara på frågor relaterat
          till din order (kundtjänst) lagrar vi ditt förnamn- och efternamn,
          adress, telefonnummer, e-postadress, ip-adress och köphistorik. Dina
          uppgifter lagras så länge vi har en rättslig grund att behandla dina
          uppgifter, exempelvis för att fullfölja avtalet mellan oss eller för
          att efterleva en rättslig förpliktelse enligt exempelvis
          bokföringslagen.
          <br />
          <br />
          <b>11.3 Rättslig grund</b>
          <br />
          I samband med ett köp behandlas dina personuppgifter för att fullfölja
          avtalet med dig. Marknadsföring, kampanjer och liknande utskick sker
          efter samtycke från dig.
          <br />
          <br />
          <b>11.4 Vilka uppgifter delas och med vilket syfte?</b>
          <br />
          <br />
          11.4.1 Betalleverantör
          <br />
          Vid genomförande av köp, delas information med vår betalleverantör.
          Det som lagras är förnamn, efternamn, adress, e-postadress och
          telefonnummer. Väljer du att betala med faktura sparas även
          personnummer hos betalleverantören. Informationen sparas för att kunna
          genomföra köpet och för att skydda parterna mot bedrägeri. De
          betalleverantörer (betaltjänster) som vi använder oss av är: Swish.
          <br />
          <br />
          11.4.2 Fraktbolag
          <br />
          För att kunna leverera dina beställningar och slutföra vårt avtal
          måste vi dela med specifik information med fraktbolaget, i det fall
          frakt föreligger. Det som delas med fraktbolaget är förnamn, efternamn
          samt adressuppgifter för leverans. E-postadress och/eller mobilnummer
          kan även komma att delas med fraktbolaget för avisering.
          <br />
          <br />
          11.4.3 Nyhetsbrev
          <br />
          Har du valt att prenumerera på vårt nyhetsbrev delas förnamn,
          efternamn och e-postadress med vår nyhetsbrevsleverantör. Detta för
          att kunna hålla dig uppdaterad med information och erbjudanden i
          marknadsföringssyfte. Vi står i nuläget själva för utskick av
          nyhetsbrev.
          <br />
          <br />
          <b>11.5 Dina rättigheter</b>
          <br />
          <br />
          <b>Rätten till tillgång</b> - Du har rätt att kontakta oss och begära
          tillgång till den personliga information vi behandlar om dig, och att
          fråga om t.ex. syftet med behandlingen och vem som har fått den
          personliga informationen. Tågluffa i Europa kommer att ge dig en
          gratis kopia av den personliga information som vi behandlar. Vi kan
          debitera en administrativ avgift för ytterligare kopior. Utdrag
          levereras elektroniskt i ett läsbart format.
          <br />
          <br />
          <b>Rätten till rättelse och radering</b> - Du har rätt att få din
          personliga information rättad utan onödigt dröjsmål eller, under vissa
          förutsättningar, att få tillgång till den begränsad. Om du tror att
          Tågluffa i Europa behandlar personlig information om dig som är
          felaktig eller ofullständig, kan du begära att den ska rättas eller
          uppdateras.
          <br />
          <br />
          <b>Rätten att bli glömd</b> - Du kan när som helst begära att dina
          data raderas, t.ex. om informationen inte längre är nödvändig för
          syftet eller behandlingen baseras på samtycke som har återkallats. Det
          kan dock finnas lagstadgade krav eller avtalsvillkor som hindrar oss
          från att radera din personliga information (exempelvis enligt
          bokföringslagen).
          <br />
          <br />
          <b>Rätten till begränsning av behandling</b> - Du har rätt till
          begränsning av behandlingen av din personliga information. I specifika
          situationer kan vi stoppa eller begränsa sättet de använder din
          personliga information på. Om en begränsning är i bruk, kommer vi att
          vidta rimliga åtgärder för att informera andra databehandlare och
          kommer också att informera dig innan begränsningen hävs.
          <br />
          <br />
          <b>Rätten att invända</b> - Du har rätt att invända mot behandlingen
          av din personliga information när som helst om den legala grunden för
          behandlingen är ett intresseavvägande. Du har också rätt att invända
          mot behandlingen av din personliga information när som helst om
          informationen används för direktmarknadsföring.
          <br />
          <br />
          <b>Rätten till dataportabilitet</b> - Du har rätt att hämta ut den
          personliga informationen du har lämnat till Tågluffa i Europa och att
          lagra eller överföra den till en annan kontrollant (dataportabilitet).
          Detta gäller dock under förutsättning att det är tekniskt möjligt och
          att behandlingen är nödvändig för att uppfylla avtal.
          <br />
          <br />
          <b>Rätten att klaga</b> - Om du är missnöjd med hur vi har behandlat
          din personliga information, vänligen kontakta oss, se våra
          kontaktuppgifter i avsnitt 11.8. Du har också rätt att lämna in en
          anmälan om vår personuppgiftsbehandling till den svenska myndigheten
          för skydd av personuppgifter (IMY):
          <br />
          IMY
          <br />
          Box 8114
          <br />
          SE-104 20 Stockholm
          <br />
          imy@imy.se
          <br />
          <br />
          <b>11.8 Ansvarig för dataskydd</b>
          <br />
          Tågluffa i Europa AB är ansvarig för lagring och behandling av
          personuppgifter i webbutiken och ser till att reglerna efterföljs.
          <br />
          <br />
          <b>kontaktuppgifter:</b>
          <br />
          Tågluffa i Europa AB
          <br />
          Mölndalsvägen 39
          <br />
          SE-412 63 GÖTEBORG
          <br />
          E-mail: kundservice@tagluffaieuropa.se
          <br />
          <br />
          <b>11.9 Så skyddar vi dina personuppgifter</b>
          <br />
          Vi använder oss av industristandarder som SSL/TLS och envägs
          hash-algoritmer för att lagra, behandla och kommunicera känslig
          information som exempelvis personuppgifter och lösenord på ett säkert
          sätt.
        </Typography>
      </>
    ),
  },
]

export default TermsAndConditions

export { termsandconditions }
