import React from 'react'
const FAQ_återköp = [
  {
    question: 'Hur aktiverar jag mina biljetter?',
    answer: (
      <span>
        För att se och använda dina biljetter använder du Interrails Rail
        Planner app. Du aktiverar biljetterna genom att ange koden som vi
        skickade till dig i ditt konfirmations-mail.
      </span>
    ),
  },
  {
    question: 'Vad gör jag om min telefon med biljetterna inte är tillgänglig?',
    answer: (
      <span>
        Om du på något sätt förlorat dina digitala biljetter så rekomenderar vi
        dig att kontakta Interrails support{' '}
        <a href="https://www.interrail.eu/en/support#/">här</a>. Ange där ditt
        bokningsnummer så kommer du få hjälp.
      </span>
    ),
  },
  {
    question:
      'Kan jag uppgradera mina biljetter eller lägga till fler res-dagar?',
    answer: (
      <span>
        Det är tyvärr inte möjligt att modifiera en bokad biljett. Om du vill
        förlänga din resa rekomenderar vi att köpa ytterliggare biljetter.
      </span>
    ),
  },
  {
    question: 'Hur fungerar barn-biljetter?',
    answer: (
      <span>
        En barn-biljett aktiveras i Interrails app precis som en
        standardbiljett. Viktigt att veta är att maximalt två barn-biljetter är
        tillåtna för varje vuxen-biljett.
      </span>
    ),
  },
  {
    question: 'Vilka enheter har support för digitala biljetter?',
    answer: (
      <span>
        Interrails app är tillgänglig på både Android och Iphone. För att
        installera appen krävs Android version 6.0 eller senare samt IOS version
        11.0 eller senare.
      </span>
    ),
  },
  {
    question:
      'Vad gör jag om jag tappar bort min biljett eller min boknings-information?',
    answer: (
      <span>
        All information om din bokning finns i bekräftings-mailet som vi skicka
        vid godkänd bokning. Om du av någon aledning inte har tillgång till
        mailet längre, kontakt vår support så skickar vi ett nytt.
      </span>
    ),
  },
  {
    question:
      'Kan jag flytta biljetter mellan enheter? Till exempel om min resgrupp vill dela på sig under resan?',
    answer: (
      <span>
        Det är inte möjligt att flytta biljetter mellan enheter efter att dom
        har aktiverats. Det finns dock inget krav att alla biljetter gjorda i
        samma bokning aktiveras på samma enhet. Om ni tror att ni kommer dela på
        gruppen under resans gång bör ni aktivera biljetterna på var resenärs
        enhet.
      </span>
    ),
  },
]

export default FAQ_återköp
