import React, { useEffect } from 'react'
import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Checkbox,
  Button,
  IconButton,
  Dialog,
  Link,
  Box,
  CircularProgress,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { styles } from 'styles'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import refineRailBookAvailResponse from './functions/refineRailBookAvailResponse'
import generateinterrailAvailReq from './functions/generateinterrailAvailReq'
import postACPRailRequest from './functions/postACPRailRequest'
import {
  createBooking,
  CreatePayment,
  checkSwishStatus,
} from './functions/BookEurail'
import {
  createCartWithContent,
  validateCart,
  createOrder,
} from './functions/postEurail'
import backendAdress from '../backendAdress'
import generateinterrailCart from './functions/generateinterrailCart'
import { useHistory, useLocation } from 'react-router-dom'
//import sanitizer from "./functions/sanitizer";
import { Label } from '@material-ui/icons'
import priceChecker from './functions/priceChecker'
import sendAlertMail from './functions/sendAlertMail'
import TermsAndConditions from './TermsAndConditons'
import Fader from './effects/Fader'
import { generate_GA4_event_parameters_purchase } from './functions/googleAnalyticsFunctions'

const xml2js = require('xml2js')

const backend_adress = backendAdress

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

function Checkout(props) {
  const history = useHistory()

  const location = useLocation()

  var bookingID = new URLSearchParams(location.search).get('bookingID')

  function handleParamUpdate(bookingid) {
    history.push({
      pathname: '/buy/checkout',
      search: `?bookingID=${bookingid}`,
    })
  }

  useEffect(() => {
    if (bookingID) {
      setSwishDialogOpen(true)
      setBookStatus('swish_pending')
      checkSwishStatus(bookingID).then((result) => {
        if (result == true) {
          createBooking(bookingID, props.order, setBookStatus)
            .then((result) => {
              var executed_correctly = handle_error_booking(result)
              if (executed_correctly) {
                return true
              } else {
                return false
              }
            })
            .then((res) => {
              var temp_order = props.order
              props.update_order(temp_order)

              if (res) {
                history.push('/buy/sendof')
              }
            })
          //
        }
        if (result == false) {
          setSwishDialogOpen(false)
        }
      })
    }
  }, [bookingID])

  const classes = styles()
  var originalPaymentReference = ''

  const [CurrentSwishIdentifier, setCurrentSwishIdentifier] = React.useState('')
  const [paymentOption, setPaymentOption] = React.useState('swish')
  const [termsConditions, setTermsConditions] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [errorAlert, setErrorAlert] = React.useState(null)
  const [swishDialogOpen, setSwishDialogOpen] = React.useState(false)
  const [invalidOrder, setInvalidOrder] = React.useState(false)
  const [bookStatus, setBookStatus] = React.useState(null)

  props.change_stepper('Betalning')

  const handleChange = (event) => {
    setPaymentOption(event.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSwishDialog = () => {
    handleBooking()
  }

  const handleSwishDialogTest = () => {
    handleTestBooking()
  }

  function handleTestBooking() {
    console.log('Testing the swish')
    prepareSwishPayment(1)
      .then(function (result) {
        if (!result) {
          throw 'PrepareSwishPayment did not execute properly'
        }
        return checkSwishStatus(result)
      })
      .then(function (result) {
        if (!result) {
          throw 'The callBackSimulator did not execute properly'
        }
      })
  }

  const handleAccept = () => {
    setOpen(false)
    handleTermsConditions(true)
  }

  function sendFailedBook(pnr_numbers, acp_ans, error) {
    var baseURL = props.backend_adress + '/'
    const url = baseURL + 'pnr2dB'
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status: false,
        pnr_number: pnr_numbers,
        order: props.order,
        ACP_dump: acp_ans,
        error_msg: error,
        shopping_cart: props.shopping_cart,
      }),
    })
  }

  const termsConditionLabel = (
    <div>
      <span>Jag har läst och godkänner webbplatsens </span>
      <Link style={{ pointerEvents: 'auto' }} onClick={handleClickOpen}>
        villkor
      </Link>
    </div>
  )

  async function handleSwish() {
    var temp_order = props.order
    temp_order.order = props.shopping_cart
    console.log(props.shopping_cart)
    props.update_order(temp_order)

    if (!props.order.is_order_invalid()) {
      setInvalidOrder(false)
      prepareSwishPayment(100)
      return true
    } else {
      setInvalidOrder(true)
    }
  }

  async function prepareSwishPayment(amount) {
    var swishIdentifier

    console.log('Talking to swish!')
    var baseURL = props.backend_adress + '/'

    var url = baseURL + 'paymentrequests'
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payerAlias: '46705777903',
        amount: amount,
        message: 'Hello World!',
        callbackUrl: baseURL + '/swishCallback',
      }),
    })
      .then(function (response) {
        if (response.status != 201) {
          console.log('Request failure: ' + response.statusText)
          return
        }
        return response.json()
      })
      .then(function (json) {
        if (json) {
          setCurrentSwishIdentifier(json['id'])
          swishIdentifier = json['id']
          console.log(
            'Payment request created with identifier ' +
              json['id'] +
              ', open app.'
          )

          //State change for visuals to check
          setBookStatus('swish_pending')
        }
      })
      .catch(function (error) {
        console.log('Request failure: ', error)
      })
    return swishIdentifier
  }

  async function checkActiveSwish(SwishIdentifier) {
    var status
    var baseURL = props.backend_adress + '/'
    var url = baseURL + 'paymentrequests/' + SwishIdentifier
    var errorMessage = ''

    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        if (json.status == 'PAID') {
          originalPaymentReference = json['paymentReference']
          console.log('Swish was paid correctly!')
          // Close dialog
          // Send user to confirmation page
          //          bookACPTicket().then((result) =>
          //            result
          //              ? history.push("/buy/sendof")
          //              : console.log("Tickets where not booked correctly!")
          //          );
          //history.push("/buy/sendof");
          status = true
        } else if (json.status == 'FAILED') {
          // TODO: Verify failure status
          // Alter error message for order to be stored in dB
          if (errorMessage) {
            errorMessage.concat(' | ')
          }
          errorMessage.concat('Swish payment failed.')
          // Show failure message
          status = false
        } else if (json.status == 'ERROR') {
          // TODO: Verify failure status
          // Alter error message for order to be stored in dB
          if (errorMessage) {
            errorMessage.concat(' | ')
          }
          errorMessage.concat('Swish payment encountered an error!')
          // Show failure message
          status = false
        }
        // Send order and swish_status to dB (COMMENTED THIS PART AWAY DUE TO MISMATCH IN DB STRUCTURE PLEASE RESOLVE //JENS)
        /* url = baseURL + "order2dB";
				fetch(url, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						swish_status: json.status,
						order: sanitizer(props.order),
						error: errorMessage,
					}),
				}); */
        console.log(
          'Payment(identifier: ' +
            SwishIdentifier +
            ', paymentReference: ' +
            originalPaymentReference +
            ') ' +
            json.status
        )
      })
      .catch(function (error) {
        console.log('Request failure: ', error)
      })
    return status
  }

  function invalidOrderPrintout() {
    if (invalidOrder) {
      return (
        <Grid item xs={12}>
          <Typography align="center" className={classes.invalidText}>
            {invalidOrder}. Åtgärda det och fortsätt sedan till betalning.
          </Typography>
        </Grid>
      )
    } else {
      return null
    }
  }

  function showAlert() {
    if (errorAlert) {
      console.log(errorAlert)
      return <Alert severity="error">{errorAlert}</Alert>
    } else {
      return null
    }
  }

  async function callBackSimulation(result) {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    await delay(50000)
    checkActiveSwish(result)
    setBookStatus('swish_paid')
    return true
  }

  function handle_error_booking(result) {
    if (result.statusMessage == 'Successfully sent swish request') {
      try {
        props.gtag(
          'event',
          'purchase',
          generate_GA4_event_parameters_purchase(props.order)
        )
      } catch (error) {
        // Handle the error if needed
        props.gtag('event', 'checkout_error', {
          error_message: error,
        })
      }
    }
    if (result.error) {
      setSwishDialogOpen(false)
      console.log(
        'Error message: ',
        result.concatenatedError[result.concatenatedError.length - 1]
      )
      var errorMessage
      switch (result.concatenatedError[result.concatenatedError.length - 1]) {
        case 'Error in cart creation at Eurail':
          errorMessage =
            'Bokningen gick inte att genomföra. Kontakta kundsupport för att få hjälp med problemet.'
          break
        case 'Preparing swish payment did not succeed':
          if (result.concatenatedError[0] == 'Payer not Enrolled') {
            errorMessage = 'Felaktigt swishnummer.'
          } else if (result.concatenatedError[0] == 'Payer alias is invalid') {
            errorMessage = 'Swishnummer är felaktigt ifyllt.'
          } else {
            errorMessage =
              'Uppsättning av swishbetalning lyckades inte. Säkerställ att ingen information är felaktigt inmatad och försök igen.'
          }
          break
        case 'Swish payment failed.':
          errorMessage =
            'Swishbetalning genomfördes inte. Var god försök igen. Om det inte fungerar då, var vänlig kontakta kundservice.'
          break
        case 'Create order did not execute properly':
          sendAlertMail(props.order, backend_adress, result.concatenatedError)
          history.push('/order-error')
          break
        case 'Verifying price failed.':
          sendAlertMail(props.order, backend_adress, result.concatenatedError)
          errorMessage =
            'Bokning genomfördes inte. Var god försök igen. Om det inte fungerar då, var vänlig kontakta kundservice.'
          break
        case 'Validating cart did not succeed.':
          switch (result.concatenatedError[0].errorCode) {
            case 50:
              errorMessage =
                'Antalet barnbiljetter överstiger 2 per vuxen resenär. Vänligen lägg till fler vuxna resenärer eller kategorisera det överstigande antalet barn som ungdomar.'
              break
            case 72:
              errorMessage =
                'Barnbiljetter överensstämmer inte med vuxen resenär. Vänligen säkerställ att exempelvis vald komfort och typ av pass överensstämmer.'
              break
            default:
              errorMessage =
                'Innehållet i din kundvagn är ogiltigt. Säkerställ att du följt samtliga riktlinjer och försök igen. Om det inte lyckas, sök svaret i vår FAQ under support.'
              break
          }
          break
        default:
          sendAlertMail(props.order, backend_adress, result.concatenatedError)
          errorMessage =
            'Ett fel uppstod. Om din betalning genomförts så kommer du bli kontaktad av kundtjänst inom kort. Om inte, försök igen. Om det inte lyckas, sök svaret i vår FAQ under support.'
          break
      }
      setErrorAlert(errorMessage)

      return false
    }

    return true
  }

  //This is a bit bare bones but it works. Note that Swish does not handle callbacks in its demo-api so we need to simulate a callback
  //I nuläget görs två checks mot ACP som inte beror på varandra. Detta är ju inte att önska. handleBooking bör skicka sitt resultat genom kedjan till bookACPTickets.
  //Glöm inte att kolla om priserna matchar med förväntningarna för att inte saker ska bli fel och vi förlorar pengar. Checka i checkACPTickets
  async function handleBooking() {
    console.log('BOOKINGID:', bookingID)
    //await createBooking(bookingID, setBookStatus)
    setSwishDialogOpen(true)
    await CreatePayment(props.shopping_cart, props.order, setBookStatus).then(
      (result) => {
        console.log('Result from BookEurail:', result)
        console.log(result)

        var executed_correctly = handle_error_booking(result)
        if (executed_correctly) {
          handleParamUpdate(props.order.shopping_cart_id)

          history.go(0)
        }
      }
    )
  }

  const handleSwishFirstName = (event) => {
    var temp_order = props.order
    temp_order.swish_first_name = event.target.value
    props.update_order(temp_order)
  }

  const handleSwishSurname = (event) => {
    var temp_order = props.order
    temp_order.swish_surname = event.target.value
    props.update_order(temp_order)
  }

  const handleSwishPhoneNumber = (event) => {
    var temp_order = props.order
    var swish_phone_number = event.target.value
    //Remove the +46 if the number starts with that
    swish_phone_number = swish_phone_number.startsWith('+46')
      ? swish_phone_number.replace('+46', '46')
      : swish_phone_number
    swish_phone_number = swish_phone_number.startsWith('0')
      ? swish_phone_number.replace('0', '46')
      : swish_phone_number
    console.log(swish_phone_number)
    temp_order.swish_phone_number = swish_phone_number
    props.update_order(temp_order)
  }

  const handleTermsConditions = (event) => {
    var temp_order = props.order
    if (event) {
      temp_order.approved_conditions = true
      setTermsConditions(true)
    } else {
      temp_order.approved_conditions = !termsConditions
      setTermsConditions(!termsConditions)
    }
    props.update_order(temp_order)
    console.log(props.order)
    console.log(props.order.is_order_invalid())
  }

  function swishDialog() {
    var output
    if (!bookStatus) {
      output = (
        <div align="center">
          <Box m={2}>
            <img
              style={{ width: '50%' }}
              src={require('../assets/swish-logo.jpg')}
              alt="Logo"
            ></img>
          </Box>
          <Box m={2}>
            <Typography> Reserverar interrailbiljetter. </Typography>
          </Box>
          <CircularProgress />
        </div>
      )
    } else if (bookStatus == 'swish_pending') {
      output = (
        <div align="center">
          <Box m={2}>
            <img
              style={{ width: '50%' }}
              src={require('../assets/swish-logo.jpg')}
              alt="Logo"
            ></img>
          </Box>
          <Box m={2}>
            <Typography>
              {' '}
              Inväntar swishbetalning. Öppna swish i din mobil.{' '}
            </Typography>
          </Box>
          <CircularProgress />
        </div>
      )
    } else if (bookStatus == 'swish_paid') {
      output = (
        <div align="center">
          <Box m={2}>
            <img
              style={{ width: '50%' }}
              src={require('../assets/swish-logo.jpg')}
              alt="Logo"
            ></img>
          </Box>
          <Box m={2}>
            <Typography> Swish betald. Bokar biljetter. </Typography>
          </Box>
          <CircularProgress />
        </div>
      )
    }
    return output
  }

  return (
    <Fader in={true}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/*           <FormControl component="fieldset" fullWidth={true}>
            <RadioGroup
              aria-label="delivery"
              name="delivery1"
              value={paymentOption}
              onChange={handleChange}
            >
              <FormControlLabel
                value="swish"
                control={<Radio />}
                label="Swish"
              />
            </RadioGroup>
          </FormControl> */}
        </Grid>

        <Grid item xs={12}>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Förnamn"
                  type="text"
                  autoComplete="given-name"
                  variant="filled"
                  fullWidth
                  onChange={(event) => handleSwishFirstName(event)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Telefonnummer kopplat till swish"
                  type="text"
                  autoComplete="tel"
                  variant="filled"
                  fullWidth
                  onChange={(event) => handleSwishPhoneNumber(event)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Efternamn"
                  type="text"
                  autoComplete="family-name"
                  variant="filled"
                  fullWidth
                  onChange={(event) => handleSwishSurname(event)}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            style={{ pointerEvents: 'none' }}
            control={
              <Checkbox
                id={'not_the_label_id'}
                style={{ pointerEvents: 'auto' }}
                color="default"
                name="checked"
                checked={termsConditions}
                onClick={() => handleTermsConditions()}
              />
            }
            labelstyle={{ zIndex: 3 }}
            label={
              <label htmlFor={'not_the_same_as_above'}>
                {termsConditionLabel}
              </label>
            }
            className={classes.greyText}
          />
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <MuiDialogTitle disableTypography>
              <Typography variant="h6">Allmänna villkor</Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
              <TermsAndConditions />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleAccept} color="primary">
                Godkänn
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          {showAlert()}
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={classes.greyText}>
            Totalbelopp inkl. angiven moms
          </Typography>
          <Typography align="center" variant="h6" className={classes.greyText}>
            {props.totalpris()} SEK
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Grid item xs={4}>
            <Button
              variant="contained"
              className={classes.swishButton}
              color="primary"
              onClick={() => handleSwishDialog()}
            >
              Swisha
            </Button>
          </Grid>
          {/* <Grid item xs={4}>
            <Button
              variant="contained"
              className={classes.swishButton}
              color="primary"
              onClick={() => handleSwishDialogTest()}
            >
              Testa att swisha en krona
            </Button>
          </Grid> */}
          {invalidOrderPrintout()}
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          <Grid container spacing={0}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <img
                className={classes.swishLogo}
                src={require('../assets/swish-logo.jpg')}
                alt="Logo"
              />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}></Grid>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={swishDialogOpen}
        >
          <MuiDialogTitle disableTypography className={classes.swishDialog}>
            <Grid
              container
              className={classes.swishDialogBox}
              direction="row"
              alignItems="center"
              justify="center"
            >
              {swishDialog()}
            </Grid>
          </MuiDialogTitle>
        </Dialog>
      </Grid>
    </Fader>
  )
}

export default Checkout

/*
function CustomizedDialogs() {
  
  return (
    <div>
    <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
            dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
*/
