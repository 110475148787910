import React from 'react'
import Helmet from 'react-helmet'
import { styles } from 'styles'
import { Grid, Typography, Box, Fade } from '@material-ui/core'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import interrail_for_beginners from '../assets/guider/interrail_for_beginners'
import vinresa_frankrike from '../assets/guider/vinresa_frankrike'
import paris_frankrike from '../assets/guider/paris_frankrike'
import packning from '../assets/guider/packning'
import GuideCard from './guideCard.js'
import StaticPage from './StaticPage.js'
import Fader from './effects/Fader'

/**
 * The idea behind this page is to provide a filterable list of all guides available. All guides are supposed
 * to exist in one .js-file each in the assets/guides folder and should be able to render into a cars at this
 * webpage, but also be able to render into a static page once the card is clicked.
 */

function ThinkAbout(props) {
  let match = useRouteMatch()
  const classes = styles()
  var list_of_guides = [
    interrail_for_beginners,
    //vinresa_frankrike,
    paris_frankrike,
    packning,
  ]

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Innan man ger sig ut i Europa är det bra att känna till tips, tricks och i allmänhet hur bör gå tillväga för att tågluffa. I denna guid finns information värd att veta och tips för en lyckad resa."
        />
        <meta
          name="keywords"
          content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, nybörjare, information, guide, planera, planering"
        />
        <title>Tågluffning för nybörjare</title>
        <link
          rel="canonical"
          href="https://tagluffaieuropa.se/guider/tagluffning-for-nyborjare"
        />
        <script type="application/ld+json">{`
            {
              "@context": "http://schema.org",
              "@type": "Guide",
              "about": "Tågluffa"
              "name": "Tågluffning för nybörjare"
              "text": "Innan man ger sig ut i Europa är det bra att känna till tips, tricks och i allmänhet hur bör gå tillväga för att tågluffa. I denna guid finns information värd att veta och tips för en lyckad resa."
              "reviewAspect": ["Planering,
              Olycka,
              Pass"]
            }
          `}</script>
      </Helmet>
      <StaticPage content={interrail_for_beginners.content} />
    </div>
  )
}

export default ThinkAbout
