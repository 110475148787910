import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import FAQ_återköp from './FAQ/FAQ_återköp'
import FAQ_biljetter from './FAQ/FAQ_biljetter'

function questionLooper(array) {
  return array.map((x, i) => {
    return (
      <Accordion key={i}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            {i + 1}: {x.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{x.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    )
  })
}

var support_text = [
  {
    title: 'Support',
    picPath: 'railway-italy.jpeg',
    body: 'Välkommen till vår support sida. Här kan du få svar på några vanliga frågor runt bokningar och interrail kort. Om du skulle undra något som inte täcks av våran FAQ får du gärna kontakta vår support.',
  },
  {
    title: 'FAQ',
    picPath: '',
    body: (
      <>
        <Grid container spacing={1}>
          <Grid item>
            {' '}
            <Typography variant="h5" component="h2">
              Bokning
            </Typography>{' '}
          </Grid>
          <Grid item>
            <div>{questionLooper(FAQ_återköp)}</div>
          </Grid>
          <Grid item>
            {' '}
            <Typography variant="h5" component="h2">
              Biljetter
            </Typography>{' '}
          </Grid>
          <Grid item>
            <div>{questionLooper(FAQ_biljetter)}</div>
          </Grid>
          <Grid item>
            <div>
              <Typography variant="body1" component="h2" align={'left'}>
                Fick du inte svar på din fråga?
              </Typography>
              <div>
                Kontakta gärna vår support på&nbsp;
                <a href="mailto: kundservice@tagluffaieuropa.se">
                  kundservice@tagluffaieuropa.se
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    ),
  },
]

export default support_text
