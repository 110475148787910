import React from "react";

////////////////////////////////////////////////////////////////////
// Function takes xmlMessage and address as input parameters
// The information is sent to the ACPRail API
// A Response is achieved, converted into a jsonResponse which is finally returned
////////////////////////////////////////////////////////////////////

export default async function postEurailAPI(message, address, backend_adress) {
	var response, jsonResponse;

	var baseURL = backend_adress + "/";
	var url = baseURL + "postEurail";

	console.log("received post request with:");
	console.log("address:", address);
	console.log("APImessage:", message.body);

	await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			url: address,
			message: message,
		}),
	})
		.then(function (response) {
			console.log("First response: ");
			console.log(response);
			if (!response.ok) {
				message.status = response.status;
				throw response;
			}
			return response.json();
		})
		.then(function (json) {
			console.log("postEurailAPI response:");
			console.log(json);
			if (json) {
				jsonResponse = json;
			}
		})
		.catch(function (error) {
			if (message.status < 205) {
				message.status = 400;
			}
			message.error = error;
			message.concatenatedError.push(error);
			message.concatenatedError.push(
				"An error has occured in frontend communication with backend."
			);
			return message;
		});

	return jsonResponse;
}
