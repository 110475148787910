import React from 'react'
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core'
import { cookie } from 'request'

const consent_options = { title: 'Anpassa cookies' }

function ConsentOptions(props) {
  const handleChangeGA4 = (event) => {
    let cookies_copy = JSON.parse(JSON.stringify(props.cookies))
    cookies_copy.GA4 = event.target.value
    props.setCookies(cookies_copy)
  }

  const handleChangeAd = (event) => {
    let cookies_copy = JSON.parse(JSON.stringify(props.cookies))
    cookies_copy.ad_storage = event.target.value
    props.setCookies(cookies_copy)
  }

  return (
    <>
      <h1 style={{ marginLeft: '0px' }}>{consent_options.title}</h1>
      <Typography gutterBottom>
        Cookies är små filer med information som lagras i webbläsaren för att
        förbättra webbplatsen och din upplevelse.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Nödvändiga cookies
      </Typography>
      <ul style={{ paddingInlineStart: '15px', lineHeight: '1.5' }}>
        <li>Behövs för att du ska kunna surfa på tagluffaieuropa.se</li>
        <li>Ger oss anonym statistik så att vi kan förbättra webbplatsen</li>
      </ul>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Sätt på eller av nödvändiga cookies"
          value={'På'} //To be changed
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel
            value="På"
            disabled
            control={<Radio />}
            label="På"
          />
          <FormControlLabel
            value="Av"
            disabled
            control={<Radio />}
            label="Av"
          />
        </RadioGroup>
      </FormControl>

      <Typography variant="h6" gutterBottom>
        Personalisering
      </Typography>
      <ul style={{ paddingInlineStart: '15px', lineHeight: '1.5' }}>
        <li>Kommer ihåg vad du har gjort på tagluffaieuropa.se</li>
        <li>
          Sparar dina inställningar och preferenser för att underlätta kommande
          besök
        </li>
      </ul>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Sätt på eller av cookies för personalisering"
          value={props.cookies.GA4}
          onChange={handleChangeGA4}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel value="true" control={<Radio />} label="På" />
          <FormControlLabel value="false" control={<Radio />} label="Av" />
        </RadioGroup>
      </FormControl>
      <Typography variant="h6" gutterBottom>
        Marknadsföring
      </Typography>
      <ul style={{ paddingInlineStart: '15px', lineHeight: '1.5' }}>
        <li>Ger dig personliga erbjudanden på tagluffaieuropa.se</li>
        <li>Visar personliga erbjudanden i våra samarbetspartners kanaler</li>
      </ul>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Sätt på eller av cookies för marknadsföring"
          value={props.cookies.ad_storage}
          onChange={handleChangeAd}
          defaultValue="true"
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel value="true" control={<Radio />} label="På" />
          <FormControlLabel value="false" control={<Radio />} label="Av" />
        </RadioGroup>
      </FormControl>
      <br />
      <a href="#" onClick={() => props.setAbout(true)}>
        Om cookies
      </a>
    </>
  )
}

export default ConsentOptions

export { consent_options }
