import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Euro, Translate, People } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    margin: theme.spacing(2),
    minWidth: 150,
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
  },
}))

const cardsData = [
  { icon: <Euro />, text: '' },
  { icon: <Translate />, text: '' },
  { icon: <People />, text: '' },
]

const SquareCards = (props) => {
  const classes = useStyles()

  // Merge text with cardsData
  const mergedData = cardsData.map((card, index) => ({
    ...card,
    text: props.text[index],
  }))

  return (
    <Grid container spacing={2} justify="center">
      {mergedData.map((card, index) => (
        <Grid item key={index} xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.icon}>{card.icon}</div>
              <p>{card.text}</p>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default SquareCards
