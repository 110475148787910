import React from 'react'
import TableComponent from '../Components/tableComponent'
import backendAdress from '../backendAdress'

//////////////////////////////////////////////////////////////////////////////////////
//
// Content for the interrailkort webpage. In order to render a table of interrail pass prices add the following to a body:
// <TableComponent
// title={["Interrailkort", "Ungdom", "Vuxen", "Senior"]}
// listitems={["InterrailPrices"]}
// />
//
// Here the ["InterrailPrices"] in listitems generate the ticket price content in the TableComponent Component.
//
///////////////////////////////////////////////////////////////////////////////////////////
const countries = [
  'Belgien',
  'Bosnien och Hercegovina',
  'Bulgarien',
  'Danmark',
  'Estland',
  'Finland',
  'Frankrike',
  'Grekland',
  'Irland',
  'Italien',
  'Kroatien',
  'Lettland',
  'Litauen',
  'Luxemburg',
  'Montenegro',
  'Nederländerna',
  'Nordmakedonien',
  'Norge',
  'Polen',
  'Portugal',
  'Rumänien',
  'Schweiz',
  'Serbien',
  'Slovakien',
  'Slovenien',
  'Spanien',
  'Storbritannien',
  'Sverige',
  'Tjeckien',
  'Tyskland',
  'Turkiet',
  'Ungern',
  'Österrike',
]

var interail_kort_text = [
  {
    title: 'Interrailkort',
    picPath: 'interrail-kort-platform2.jpeg',
    body: (
      <div>
        Om du har bestämt dig för att du vill ta dig fram i Europa med hjälp av
        tåg är det bästa alternativet att inhandla interrailkort. Interrail
        erbjuder många varianter, men i korta drag innebär det att du köper ett
        kort som är laddat med ett visst antal resdagar. Under dessa resedagar
        kan du hoppa på vilket tåg som helst, men om du vill vara säker på att
        få sitt-, eller sovplats måste du boka detta i förväg. Göra en, så
        kallad, platsreservation. Då tillkommer en liten extra avgift, men denna
        är liten i förhållande till tågresans fulla pris.
      </div>
    ),
  },
  {
    subtitle: 'När behöver jag boka plats?',
    picPath: '',
    body: (
      <div>
        Det finns dessvärre vissa tåg som ofta är fullbokade, har få platser
        avsatta för interrail, eller rent av kräver platsbokning. Det är därför
        viktigt att planera sin rutt innan man ger sig av och ge sig möjlighet
        att upptäcka biljetter som behöver bokas på förhand. Det bör också
        nämnas att privatägda tågbolag, som till exempel Thalys och Thello, inte
        är en del av interrail-samarbetet. Man kan därmed inte åka på dessa tåg
        med endast ett interrailkort, men det kan ge rabatt på biljettpriset
        däremot. De tåg som man å andra sidan bara kan hoppa på är just de
        statligt ägda tågbolagen och regionaltrafiken i varje land. Det vill
        säga SJ:s motsvarigheter i Europa.
        <br />
        <br />
        För de som vill åka tåg inom endast ett land finns specialanpassade
        interrailkort för detta. De kallas Interrail One Country Pass.
      </div>
    ),
  },
  {
    subtitle: 'Hur genomför jag en platsreservation?',
    picPath: '',
    body: (
      <div>
        Platsbokning på ett tåg under din resa erhåller du enklast och billigast
        genom railplanner-appen. När du planerar in resan i appen så dyker där
        upp information om att platsreservation kan behövas. Därtill erhålls
        länkar till rekommenderade sajter för platsbokning givet den planerade
        resan. Genomför din platsbokning där.
      </div>
    ),
  },
  {
    subtitle: 'Pris interrailkort',
    picPath: '',
    body: 'Priset för global passes beror på typ av interrailkort, passangerarens ålder samt vald komfort. Nedan visas vår prislista för Global Pass:',
  },
  {
    subtitle: '',
    picPath: '',
    body: (
      <>
        <TableComponent
          title={['Interrailkort', 'Ungdom', 'Vuxen', 'Senior']}
          listitems={['InterrailPrices']}
        />
        <p>Priser inom parentes är priser för en 1:a klass biljett.</p>
      </>
    ),
  },
  {
    subtitle: 'Interrailkort för barn',
    picPath: '',
    body: (
      <>
        Barn under 12 år åker gratis om de åker tillsammans med föräldrar. Varje
        vuxen har lov att färdas med två barn gratis. Skulle ett barn resa utan
        föräldrar, eller en förälders kvot om två barn redan fyllts så kommer
        barnet bli tvungen att skaffa ett ungdomskort.
        <br />
        <br />
        Tillhör du antingen åldersgruppen senior eller ungdom och planerar att
        resa med barn, så behöver du uppgradera din biljett till vuxen. Det är
        betydligt mer prisvärt än att uppgradera barnets biljett till ungdom.
        <br />
        <br />
        Barn upp till 4 års ålder behöver inte ha ett personligt interrailkort.
      </>
    ),
  },
  {
    subtitle: 'Ditt interrailkort gäller i 33 Europeiska länder:',
    picPath: 'guider/interrail-countries-tagluffaieuropa.png',
    //body: 'Belgien, Bosnien-Hercegovina, Bulgarien, Danmark, Estland, Finland, Frankrike, Grekland, Irland, Italien, Kroatien, Lettland, Litauen, Luxemburg, Makedonien, Montenegro, Nederländerna, Norge, Polen, Portugal, Rumänien, Schweiz, Serbien, Slovakien, Slovenien, Spanien, Storbritannien, Tjeckien, Turkiet , Tyskland, Ungern, Österrike samt på rederiet Atticas båtar mellan Italien och Grekland.',
    picAfter: true,
    body: (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            listStyle: 'None',
            width: '33%',
            paddingLeft: '1em',
            paddingRight: '1em',
          }}
        >
          {countries
            .slice(0, Math.ceil(countries.length / 3))
            .map((country, index) => (
              <li key={index}>{country}</li>
            ))}
        </div>
        <div
          style={{
            listStyle: 'None',
            width: '33%',
            paddingLeft: '1em',
            paddingRight: '1em',
          }}
        >
          {countries
            .slice(
              Math.ceil(countries.length / 3),
              Math.ceil(countries.length / 3) * 2
            )
            .map((country, index) => (
              <li key={index}>{country}</li>
            ))}
        </div>
        <div
          style={{
            listStyle: 'None',
            width: '33%',
            paddingLeft: '1em',
            paddingRight: '1em',
          }}
        >
          {countries
            .slice(Math.ceil(countries.length / 3) * 2)
            .map((country, index) => (
              <li key={index}>{country}</li>
            ))}
        </div>
      </div>
    ),
  },
  {
    subtitle: 'Interrailkortet gäller inte i ditt hemland',
    picPath: '',
    //body: "Interrailkortet ”Global Pass” gäller alltså inte i Sverige, vilket är bra att veta. Däremot får man stor rabatt på tågresor till, eller från svenska gränsen. Denna rabatt gäller endast dagen innan ditt interrailkort börjar gälla och dagen efter ditt interrailkort tagit slut. Väl värt att nämna är att denna rabatt inte går att erhålla genom bokning på internet, utan endast genom personlig kontakt med förslagsvis SJ.",
    //body: "Interrailkortet ”Global Pass” gäller inte för resor i Sverige om du är född i Sverige, vilket är bra att veta. Däremot får man stor rabatt på tågresor till, eller från svenska gränsen. Denna rabatt gäller endast dagen innan ditt interrailkort börjar gälla och dagen efter ditt interrailkort tagit slut. Väl värt att nämna är att denna rabatt inte går att erhålla genom bokning på internet, utan endast genom personlig kontakt med förslagsvis SJ."
    body: (
      <p>
        Interrail Global Pass ger dig möjlighet till 2 resor i ditt hemland: 1
        för att åka iväg och 1 för att återvända, med flera tågbyten
        inkluderade. Ditt hemland är det land där du är bosatt och exempelvis
        har permanent boende, arbetar, studerar och betalar skatt. Du kan resa
        under en resdag för att lämna ditt hemland (utresan) och en resdag för
        att återvända (inresan). Du kan använda dessa resor i ditt eget land när
        som helst under din Pass giltighetstid.
        <br />
        <br />
        Värt att observera är att utresa och inresa inte räknas som extra
        resdagar, men att de måste genomföras under ditt pass giltighetstid. Har
        du ett pass med <i>10 resdagar inom två månader</i>, så behöver in- och
        utresa ske inom de två månaderna. Har du, å andra sidan, ett pass med{' '}
        <i>15 resdagar i följd</i>, så behöver in- och utresa ske inom de 15
        dagarna.
        <br />
        <br />
        När du reser med ett mobilpass känner appen automatiskt igen en utgående
        eller ingående resa, baserat på ditt hemland. Om du lägger till en
        utgående eller ingående resa till ditt pass, kommer ett popup-fönster
        att meddela dig att du kommer att använda en av de två tillgängliga
        ut/inresor. Du måste godkänna detta innan du kan lägga till din resa i
        ditt pass.
        <br />
        <br />
        Tänk på att när du vill resa från ett land till ett annat och måste resa
        genom ditt hemland, kommer appen att förbruka både den in- och utgående
        resan på denna resa.
      </p>
    ),
  },
]

export default interail_kort_text
