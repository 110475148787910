import React from "react";

var vinresa_frankrike = {
	content: [
		{
			title: "Vinresa i Frankrike",
			subtitle: "",
			picPath: "provance-lavendar-fields.jpeg",
			body: (
				<p>
					Om du har <b> bestämt </b> dig för att du vill ta dig fram i Europa med hjälp
					av tåg är det bästa sättet att inhandla InterRail-kort. InterRail
					erbjuder många varianter, men i korta drag innebär det att du köper
					ett kort som är laddat med ett visst antal resdagar. Under dessa
					resedagar kan du hoppa på vilket tåg som helst, men om du vill vara
					säker på att få sitt-, eller sovplats måste du boka i förväg. Då
					tillkommer en liten extra avgift, men denna är inte speciellt stor.
				</p>
			),
		},
		{
			title: "",
			subtitle: "",
			picPath: "",
			body:
				"Det finns dessvärre vissa tåg som ofta är fullbokade eller har väldigt få platser avsatta för InterRail. Då kan en bokning krävas. Därför är det viktigt att planera sin rutt innan man ger sig ut för att kunna upptäcka biljetter som kan behöva bokas. Dessutom bör det nämnas att snabbtåg och privatägda tågbolag, som till exempel Eurostar, Thalys och Thello, inte är en del av InterRail-samarbetet. Man kan därmed inte åka på dessa tåg med endast ett InterRail-kort, men får däremot rabatt på dem. De tåg som man å andra sidan bara kan hoppa på är just de statligt ägda tågbolagen i varje land. Det vill säga SJ:s motsvarigheter i Europa.",
		},
		{
			title: "",
			subtitle: "",
			picPath: "",
			body:
				"För de som vill åka tåg inom endast ett land så finns det specialanpassade InterRail-kort för dessa. De kallas InterRail One Country Pass.",
		},
		{
			title: "",
			subtitle: "Prislista för Global Pass",
			picPath: "",
			body: "TBA",
		},
		{
			title: "",
			subtitle: "Interrail-kort för barn",
			picPath: "",
			body:
				"Det säljs inte biljetter för barn, dvs. personer under 12 år. Barn åker gratis om de åker tillsammans med föräldrar och de kommer då få egna speciella kort som används tillsammans med förälderns. Skulle ett barn resa utan föräldrar så kommer denna bli tvungen att skaffa ett ungdomskort.",
		},
		{
			title: "",
			subtitle: "Ditt InterRail-kort gäller i 30 Europeiska länder:",
			picPath: "",
			body:
				"Belgien, Bosnien-Herzegovina, Bulgarien, Danmark, Finland, Frankrike, Grekland, Irland, Italien, Kroatien, Luxemburg, Makedonien, Montenegro, Nederländerna, Norge, Polen, Portugal, Rumänien, Schweiz, Serbien, Slovakien, Slovenien, Spanien, Storbritannien, Tjeckien, Turkiet , Tyskland, Ungern, Österrike samt på rederiet Atticas båtar mellan Italien och Grekland.",
		},
		{
			title: "",
			subtitle: "Interrail-kortet gäller inte i Sverige",
			picPath: "",
			body:
				"InterRail-kortet ”Global Pass” gäller alltså inte i Sverige, vilket är bra att veta. Däremot får man stor rabatt på tågresor till, eller från svenska gränsen. Denna rabatt gäller endast dagen innan ditt InterRail-kort börjar gälla och dagen efter ditt InterRail-kort tagit slut. Väl värt att nämna är att denna rabatt inte går att erhålla genom bokning på internet, utan endast genom personlig kontakt med förslagsvis SJ.",
		},
	],
	img_title: "Provance lavendel fält",
	img_path: "provance-lavendar-fields.jpeg",
	title: "Vinresa i Frankrike",
	header:
		"Har du ett brinnande intresse för franska viner? Vi har satt samman en guide för att tågluffa genom Frankrikes bästa vindistrikt. Följ med på en fantastiskt kulinarisk resa!",
	sitePath: "/vinresa-frankrike",
};

export default vinresa_frankrike;
