import React from 'react'
import {
  Grid,
  Box,
  Button,
  Drawer,
  Typography,
} from '@material-ui/core'
import { styles } from 'styles'

function CookieBanner(props) {
  const classes = styles()

  const [cookieOpen, setCookieOpen] = React.useState(true)

  return (
    <div>
      {cookieOpen && (
        <Drawer
          anchor={'bottom'}
          variant="permanent"
          open={cookieOpen}
          onClose={() => setCookieOpen(false)}
        >
          <Box style={{ padding: '1rem' }}>
            <Grid
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography>
                  Vi använder cookies för att ge dig bästa möjliga
                  användarupplevelse. Genom att klicka ”Acceptera cookies”
                  godkänner du att vi sparar data för dessa syften.
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  className={classes.bookingButton}
                  onClick={() => setCookieOpen(false)}
                >
                  Acceptera cookies
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      )}
    </div>
  )
}

export default CookieBanner
