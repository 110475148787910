import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

function MedPaResan() {
  return medPaResan[0].body
}

var medPaResan = {
  content: [
    {
      title: 'Det får du ha med på din Tågluff i Europa',
      picPath: 'guider/bringOnTrip.png',
      body: (
        <div>
          Oavsett om du är en äventyrslysten resenär med särskilda behov, en
          ivrig cyklist som vill utforska nya platser, eller en stolt
          husdjursägare som inte vill lämna din fyrbenta vän bakom dig, har vi
          täckt dig. Resa med funktionsvariation, cykel eller husdjur på tåg i
          Europa kan vara en fantastisk upplevelse, och det finns gott om
          möjligheter att göra det smidigt. I den här artikeln kommer vi att
          guida dig genom vad du behöver veta för att få ut det mesta av din
          tågluff i Europa, oavsett ditt specifika behov eller följeslagare. Så
          låt oss börja med några användbara tips och råd.
          <br />
          <br />
          <b>Resa med en funktionsvariation</b>
          <br />
          <br />
          Om du är en resenär med en funktionsnedsättning eller nedsatt
          rörlighet och behöver hjälp längs vägen, kan du ordna denna assistans
          direkt med transportören. De flesta järnvägsföretag i Europa erbjuder
          assistans för funktionsnedsatta passagerare. Dessutom krävs ofta en
          separat bokning för många tåg i hela Europa, särskilt om du reser med
          rullstol.
          <br />
          <br />
          Du kan hitta mer information om hur du ordnar assistans och var du kan
          göra reservationer i{' '}
          <Link to="/artiklar/funktionsvariation">denna artikel</Link>.
          <br />
          <br />
          <b>Resa med cykel</b>
          <br />
          <br />
          I många länder är det möjligt att ta med din cykel på tåget. Du kan
          behöva registrera din cykel eller köpa en särskild cykelbiljett innan
          du kan ta med cykeln på tåget med dig. För mer information om att resa
          med cykel, vänligen kontrollera webbplatserna för det eller de
          järnvägsbolag du planerar att resa med.
          <br />
          <br />
          <b>Resa med husdjur</b>
          <br />
          <br />I många länder är det möjligt att ta med ditt husdjur på tåget.
          Du kanske behöver registrera ditt husdjur eller köpa en särskild
          biljett för husdjur innan du kan ta med din pälskling på tåget med
          dig. För mer information om att ta med ditt husdjur på tåget, vänligen
          kontrollera webbplatserna för det eller de järnvägsbolag du planerar
          att resa med.
        </div>
      ),
    },
  ],
  img_title: 'Vad får jag resa med?',
  img_path: 'guider/bringOnTrip.png',
  title: 'Vad får jag med på resan?',
  header:
    'Vi går igenom vad man får med sig på sin resa genom Europa och vilka förberedelser man kan behöva göra för att det ska gå så smidigt som möjligt.',
  sitePath: '/med-pa-resan',
  helmet: (
    <Helmet>
      <meta
        name="description"
        content="Vi går igenom vad man får med sig på sin resa genom Europa och vilka förberedelser man kan behöva göra för att det ska gå så smidigt som möjligt."
      />
      <meta
        name="keywords"
        content="handikapp, disability, husdjur, katt, hund, pet, dog, cat, cykel, bike, elscooter, sparkcykel, elsparkcykel, tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff"
      />
      <title>Så aktiverar du ditt Mobila Pass</title>
      <link
        rel="canonical"
        href="https://tagluffaieuropa.se/guider/med-pa-resan"
      />
      <script type="application/ld+json">{`
  {
    "@context": "http://schema.org",
    "@type": "Guide",
    "about": "Det får du ha med på din Tågluff i Europa"
    "name": "Vad får jag med på resan?"
    "text": ""
    "reviewAspect": ["Tillhörigheter, Rullstol, Husdjur, Cykel"]
  }
`}</script>
    </Helmet>
  ),
}

export default MedPaResan

export { medPaResan }
