import React from 'react'
import { Grid, Typography, TextField, MenuItem } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { styles } from 'styles'
import { ContactSupportOutlined } from '@material-ui/icons'

function TravellerInfo(props) {
  const classes = styles()
  const [country, setCountry] = React.useState(props.ticket.nationality)
  const [travelDate, setTravelDate] = React.useState(
    props.ticket.first_travel_date
  )
  const [birthDay, setBirthDay] = React.useState(
    date2text(props.ticket.date_of_birth)
  )

  const [firstNameError, setFirstNameError] = React.useState(null)
  const [lastNameError, setLastNameError] = React.useState(null)
  const [passNrError, setPassNrError] = React.useState(null)
  const [birthDayError, setBirthDayError] = React.useState(null)
  const [editable, setEditable] = React.useState(false)
  const [myAge, setMyAge] = React.useState(props.ticket.age)
  const [myComfort, setMyComfort] = React.useState(props.ticket.comfort)
  const [myType, setMyType] = React.useState(props.ticket.type)
  const [first_name, set_first_name] = React.useState(props.ticket.first_name)

  const [error, setError] = React.useState()
  const surname = props.ticket.surname
  const passport_number = props.ticket.passport_number

  const ages = {
    CHD: 'Barn 0-11 år',
    YTH: 'Ungdom 12-27 år',
    ADT: 'Vuxen 28-59 år',
    SEN: 'Senior 60+ år',
    99: 'Superold Jensboy',
  }

  const ticketAges = [
    {
      value: 'CHD',
      label: 'Barn 0-11 år',
    },
    {
      value: 'YTH',
      label: 'Ungdom 12-27 år',
    },
    {
      value: 'ADT',
      label: 'Vuxen 28-59 år',
    },
    {
      value: 'SEN',
      label: 'Senior 60+ år',
    },
  ]

  const age_lower_limit = {
    YTH: 12,
    ADT: 28,
    SEN: 60,
  }

  const age_upper_limit = {
    CHD: age_lower_limit.YTH,
    YTH: age_lower_limit.ADT,
    ADT: age_lower_limit.SEN,
  }

  const comforts = {
    '1stclass': 'Första klass',
    '2ndclass': 'Andra klass',
    third: 'Plankning',
  }

  const ticketComforts = [
    {
      value: '1stclass',
      label: 'Första klass',
    },
    {
      value: '2ndclass',
      label: 'Andra klass',
    },
  ]

  function date2text(date) {
    if (date instanceof Date && !isNaN(date)) {
      return (
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)
      )
    }
    return date
  }

  const handleNationalityChange = (event) => {
    setCountry(event.target.value)
    props.update_ticket(
      props.ticket.id,
      null,
      null,
      null,
      null,
      null,
      null,
      event.target.value,
      null,
      null
    )
    props.set_tickets_valid_to_false()
  }

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      // Setting time for traveldate in order to make sure date will not change due to borderline values.
      date.setMinutes(37)
      date.setHours(13)
    } else {
      date = 'Invalid'
    }
    setTravelDate(date)
    props.update_ticket(
      props.ticket.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      date
    )
    props.set_tickets_valid_to_false()
  }

  const handleBirthdayChange = (date) => {
    var nr = date.target.value
    var date_of_birth = null
    setBirthDayError(null)
    // Correct input
    nr = nr.replace(/\D/g, '')
    if (nr.length > 4) {
      var corrected_nr = nr.slice(0, 4) + '-' + nr.slice(4, 6)
      if (nr.length > 6) {
        corrected_nr = corrected_nr + '-' + nr.slice(6, 8)
        if (corrected_nr.length == 10) {
          date_of_birth = new Date()
          date_of_birth.setDate(nr.slice(6, 8))
          console.log(nr.slice(4, 6) - 1)
          date_of_birth.setMonth(nr.slice(4, 6) - 1)
          date_of_birth.setFullYear(nr.slice(0, 4))
          date_of_birth.setMinutes(37)
          date_of_birth.setHours(13)
        }
      }
      nr = corrected_nr
    }
    setBirthDay(nr)
    // Verify that the entered date of birth is corresponding to the age class chosen
    // Exceptions:
    // - The adult class may be of any real age, since both older and younger parents should be able to take their children.
    // - The youth class do also allow lower ages, since the third child for an adult should be able to join the trip.

    var today = new Date()
    if (date_of_birth instanceof Date && !isNaN(date_of_birth)) {
      console.log(date_of_birth)
      console.log(minDateAllowed())
      console.log(maxDateAllowed())
      if (
        !(
          nr.slice(0, 4) < today.getFullYear() + 1 &&
          nr.slice(5, 7) > 0 &&
          nr.slice(5, 7) < 13 &&
          nr.slice(8, 10) > 0 &&
          nr.slice(8, 10) < 32
        )
      ) {
        setBirthDayError('Felaktigt ifyllt datum')
        date_of_birth = 'Invalid'
      } else if (date_of_birth < minDateAllowed()) {
        setBirthDayError('Du är äldre än din valda ålderkategori')
        date_of_birth = 'Invalid'
      } else if (date_of_birth > maxDateAllowed()) {
        setBirthDayError('Du är yngre än din valda ålderkategori')
        date_of_birth = 'Invalid'
      } else {
        setBirthDayError(null)
      }
    } else {
      date_of_birth = 'Invalid'
    }
    // Update value in ticket
    props.update_ticket(
      props.ticket.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      date_of_birth,
      null
    )
    props.set_tickets_valid_to_false()
  }

  const handleFirstNameChange = (event) => {
    props.update_ticket(
      props.ticket.id,
      null,
      null,
      null,
      event.target.value,
      null,
      null,
      null,
      null,
      null
    )
    if (event.target.value == '') {
      setFirstNameError('Input a first name')
    } else {
      setFirstNameError(null)
    }
    set_first_name(event.target.value)
    props.set_tickets_valid_to_false()
  }
  const handleLastNameChange = (event) => {
    props.update_ticket(
      props.ticket.id,
      null,
      null,
      null,
      null,
      event.target.value,
      null,
      null,
      null,
      null
    )
    if (event.target.value == '') {
      setLastNameError('Input a last name')
    } else {
      setLastNameError(null)
    }
    props.set_tickets_valid_to_false()
  }
  const handlePassportNumberChange = (event) => {
    props.update_ticket(
      props.ticket.id,
      null,
      null,
      null,
      null,
      null,
      event.target.value,
      null,
      null,
      null
    )
    if (event.target.value == '') {
      setPassNrError('Input a passport number')
    } else {
      setPassNrError(null)
    }
    props.set_tickets_valid_to_false()
  }
  const countries = [
    {
      value: 'SE',
      label: 'Sverige',
    },
    {
      value: 'NO',
      label: 'Norge',
    },
    {
      value: 'DK',
      label: 'Danmark',
    },
  ]

  props.change_stepper('Resenärer')

  function bulletFunc() {
    if (editable) {
      return <div />
    } else {
      return <div className={classes.circleBullet} />
    }
  }

  function nameFunc() {
    if (editable) {
      return <div />
    } else {
      return <Typography>{props.ticket.name}</Typography>
    }
  }

  function ageFunc() {
    if (editable) {
      return (
        <TextField
          id="filled-select-age"
          select
          value={myAge}
          onChange={(event) => {
            setMyAge(event.target.value)
          }}
          fullWidth
        >
          {ticketAges.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    } else {
      return <Typography>{ages[myAge]}</Typography>
    }
  }

  function comfortFunc() {
    if (editable) {
      return (
        <TextField
          id="filled-select-comfort"
          select
          value={myComfort}
          onChange={(event) => {
            setMyComfort(event.target.value)
          }}
          fullWidth
        >
          {ticketComforts.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    } else {
      return <Typography>{comforts[myComfort]}</Typography>
    }
  }

  function editFunc() {
    if (editable) {
      return (
        <Button
          startIcon={<SaveIcon />}
          onClick={() => {
            setEditable(!editable)
            props.update_ticket(props.ticket.id, myType, myAge, myComfort)
            props.set_tickets_valid_to_false()
            var date_of_birth = props.order.get_ticket(props.ticket.id)
            if (date_of_birth < minDateAllowed()) {
              setBirthDayError('Du är äldre än din valda ålderkategori')
              date_of_birth = 'Invalid'
            } else if (date_of_birth > maxDateAllowed()) {
              setBirthDayError('Du är yngre än din valda ålderkategori')
              date_of_birth = 'Invalid'
            } else {
              setBirthDayError(null)
            }
          }}
        >
          Spara
        </Button>
      )
    } else {
      return (
        <Button startIcon={<EditIcon />} onClick={() => setEditable(!editable)}>
          Redigera
        </Button>
      )
    }
  }

  function firstNameGraphics() {
    if (editable) {
      return (
        <TextField
          id="firstName"
          disabled
          label="Förnamn"
          type="text"
          autoComplete="given-name"
          variant="filled"
          onChange={handleFirstNameChange}
          fullWidth
        />
      )
    } else {
      return (
        <TextField
          error={firstNameError}
          id="firstName"
          label="Förnamn"
          type="text"
          autoComplete="given-name"
          variant="filled"
          defaultValue={first_name}
          onChange={handleFirstNameChange}
          fullWidth
          helperText={firstNameError}
        />
      )
    }
  }
  function passportGraphics() {
    if (editable) {
      return (
        <TextField
          id="passport"
          disabled
          label="Passnummer"
          type="text"
          autoComplete="off"
          variant="filled"
          onChange={handlePassportNumberChange}
          fullWidth
        />
      )
    } else {
      return (
        <TextField
          error={passNrError}
          id="passport"
          label="Passnummer"
          type="text"
          autoComplete="off"
          variant="filled"
          defaultValue={passport_number}
          onChange={handlePassportNumberChange}
          fullWidth
          helperText={passNrError}
        />
      )
    }
  }
  function lastNameGraphics() {
    if (editable) {
      return (
        <TextField
          id="lastName"
          disabled
          label="Efternamn"
          type="text"
          autoComplete="family-name"
          variant="filled"
          onChange={handleLastNameChange}
          fullWidth
        />
      )
    } else {
      return (
        <TextField
          error={lastNameError}
          id="lastName"
          label="Efternamn"
          type="text"
          autoComplete="family-name"
          variant="filled"
          defaultValue={surname}
          onChange={handleLastNameChange}
          fullWidth
          helperText={lastNameError}
        />
      )
    }
  }
  function nationalityGraphics() {
    if (editable) {
      return (
        <TextField
          id="nationality"
          disabled
          select
          label="Födelseland"
          value={country}
          autoComplete="country"
          onChange={handleNationalityChange}
          variant="filled"
          fullWidth
        >
          {countries.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    } else {
      return (
        <TextField
          id="nationality"
          select
          label="Födelseland"
          value={country}
          autoComplete="country"
          onChange={handleNationalityChange}
          variant="filled"
          fullWidth
        >
          {countries.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    }
  }
  function birthdayGraphics() {
    if (editable) {
      return (
        <TextField
          disabled
          id="dateOfBirth"
          label="Födelsedatum"
          type="text"
          autoComplete="bday"
          variant="filled"
          placeholder="YYYY-MM-dd"
          value={birthDay}
          fullWidth
          onChange={(event) => handleBirthdayChange(event)}
        />
      )
    } else {
      if (!birthDayError) {
        return (
          <TextField
            id="dateOfBirth"
            label="Födelsedatum"
            type="text"
            autoComplete="bday"
            variant="filled"
            placeholder="YYYY-MM-dd"
            value={birthDay}
            fullWidth
            onChange={(event) => handleBirthdayChange(event)}
          />
        )
      } else {
        return (
          <TextField
            error
            id="dateOfBirth"
            label="Födelsedatum"
            type="text"
            autoComplete="bday"
            variant="filled"
            placeholder="YYYY-MM-dd"
            helperText={birthDayError}
            value={birthDay}
            fullWidth
            onChange={(event) => handleBirthdayChange(event)}
          />
        )
      }
    }
  }
  function traveldateGraphics() {
    if (editable) {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            disabled
            variant="dialog"
            inputVariant="filled"
            format="yyyy-MM-dd"
            id="traveldate"
            label="Första resedatum"
            value={travelDate}
            minDate={new Date()}
            minDateMessage="Utresedatum får inte ha passerat"
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      )
    } else {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="dialog"
            inputVariant="filled"
            format="yyyy-MM-dd"
            id="traveldate"
            label="Första resedatum"
            value={travelDate}
            minDate={new Date()}
            minDateMessage="Utresedatum får inte ha passerat"
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            fullWidth
          />
        </MuiPickersUtilsProvider>
      )
    }
  }

  function minDateAllowed() {
    let age = props.ticket.get_age()
    let minDate = new Date()
    let today = new Date()
    if (age === 'CHD') {
      minDate.setFullYear(today.getFullYear() - age_upper_limit.CHD)
      minDate.setDate(minDate.getDate() + 1)
    } else if (age === 'YTH') {
      minDate.setFullYear(today.getFullYear() - age_upper_limit.YTH)
      minDate.setDate(minDate.getDate() + 1)
    } else if (age === 'ADT') {
      // No upper limit for Adults according to excpetions and therefore setting the upper limits like for Seniors.
      //minDate.setFullYear(today.getFullYear() - age_upper_limit.ADT);
      //minDate.setDate(minDate.getDate() + 1);
      minDate = new Date(1900, 0, 1)
    } else if (age === 'SEN') {
      minDate = new Date(1900, 0, 1)
    } else {
      minDate = new Date(1900, 0, 1)
    }
    return minDate
  }

  function maxDateAllowed() {
    let age = props.ticket.get_age()
    var maxDate = new Date()
    if (age === 'CHD') {
    } else if (age === 'YTH') {
      // No lower limit for Youths according to exceptions.
      //maxDate.setFullYear(maxDate.getFullYear() - age_lower_limit.YTH);
    } else if (age === 'ADT') {
      // No lower limit for Adults according to expections.
      //maxDate.setFullYear(maxDate.getFullYear() - age_lower_limit.ADT);
    } else if (age === 'SEN') {
      maxDate.setFullYear(maxDate.getFullYear() - age_lower_limit.SEN)
    }
    return maxDate
  }

  return (
    <>
      <Grid container spacing={3}>
        <Typography>{error}</Typography>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item>{bulletFunc()}</Grid>
                    <Grid item>{ageFunc()}</Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item>{bulletFunc()}</Grid>
                    <Grid item>{comfortFunc()}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container sm={12} justifyContent="flex-end">
                {editFunc()}
              </Grid>
            </Grid>
          </Grid>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {firstNameGraphics()}
              </Grid>
              <Grid item xs={12} sm={6}>
                {lastNameGraphics()}
              </Grid>
              <Grid item xs={12} sm={6}>
                {nationalityGraphics()}
              </Grid>
              <Grid item xs={12} sm={6}>
                {birthdayGraphics()}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default TravellerInfo
