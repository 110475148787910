//const Order = require("./../src/order_class");
//const Ticket = require("./../src/ticket_class");
//const Addition = require("./../src/addition_class");
//const Item = require("./../src/order_class");

function sanitizer(input) {
  var output
  if (typeof input == 'object' && input) {
    try {
      if (Array.isArray(input)) {
        output = []
        input.map((value) => output.push(sanitizer(value)))
      } else if (input.constructor.name == 'order_class') {
        output = input
        output.first_name = sanitizer(input.get_first_name())
        output.surname = sanitizer(input.get_surname())
        output.email = sanitizer(input.get_email())
        output.adress = sanitizer(input.get_adress())
        output.postal_code = sanitizer(input.get_postal_code())
        output.ort = sanitizer(input.get_ort())
        output.phone = sanitizer(input.get_phone())
        output.swish_first_name = sanitizer(input.get_swish_first_name())
        output.swish_surname = sanitizer(input.get_swish_surname())
        output.swish_phone_number = sanitizer(input.get_swish_phone_number())
        output.approved_conditions = sanitizer(input.get_approved_conditions())
      } else if (input.constructor.name == 'ticket_class') {
        output = input
        output.id = sanitizer(input.get_id())
        output.name = sanitizer(input.get_name())
        output.age = sanitizer(input.get_age())
        output.comfort = sanitizer(input.get_comfort())
        output.price = sanitizer(input.get_price())
        output.duration = sanitizer(input.get_duration())
        output.pass_model = sanitizer(input.get_pass_model())
        output.first_name = sanitizer(input.get_first_name())
        output.surname = sanitizer(input.get_surname())
        output.passport_number = sanitizer(input.get_passport_number())
        output.date_of_birth = sanitizer(input.get_date_of_birth())
        output.first_travel_date = sanitizer(input.get_first_travel_date())
        output.nationality = sanitizer(input.get_nationality())
      } else if (input.constructor.name == 'addition_class') {
        output = input
        output.id = sanitizer(input.get_id())
        output.name = sanitizer(input.get_name())
        output.price = sanitizer(input.get_price())
      } else if (input.constructor.name == 'item_class') {
        output = input
        output.id = sanitizer(input.get_id())
        output.name = sanitizer(input.get_name())
        output.price = sanitizer(input.get_price())
      } else if (input instanceof Date) {
        output = new Date(
          sanitizer(input.getFullYear()),
          sanitizer(input.getMonth()),
          sanitizer(input.getDate()),
          sanitizer(input.getHours())
        )
      } else if (Object.keys(input)) {
        output = {}
        var keys = Object.keys(input)
        keys.map((key) => (output[key] = sanitizer(input[key])))
      }
    } catch (error) {
      console.log('Error received: ' + error + '\nfor input: ' + input)
      console.log(input)
    }
  } else {
    output = cleanInput(input)
  }
  return output
}

function cleanInput(input) {
  if (typeof input == 'string') {
    input = input.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      ''
    ) // Remove javascript tags
    input = input.replace(/(<([^>]+)>)/gi, '') // Strip out HTML tags
    input = input.replace(/\r?\n|\r/g, '') // Remove all newlines
    input = input.replace(/SELECT|INSERT|DROP|CREATE|WHERE|FROM/gi, '') // Remove some typical dB commands
    input = input.replace(/    |   |  /gi, ' ') // Remove double/triple/quadrouple spaces
  }
  return input
}

module.exports = sanitizer
