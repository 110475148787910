class item_class {
  constructor(id, name, price = null, VAT = null, discount_price = null) {
    this.types = {
      TICKET: 'ticket',
      ADDITION: 'addition',
      PRODUCT: 'product',
    }
    this.type = null
    this.id = id
    this.name = name
    this.price = price
    this.VAT = VAT
    this.discount_price = discount_price
  }

  get_id() {
    return this.id
  }

  get_type() {
    return this.type
  }

  get_name() {
    return this.name
  }

  get_price() {
    return this.discount_price ? this.discount_price : this.price
  }

  get_VAT() {
    return this.VAT
  }

  set_id(x) {
    this.price = x
  }

  set_type(x) {
    this.type = x
  }

  set_name(x) {
    this.name = x
  }

  set_price(x) {
    this.price = x
  }

  set_discount_price(x) {
    this.discount_price = x
  }

  what_is_missing() {
    return
  }
}

export default item_class

//module.exports = item_class;
