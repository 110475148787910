import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { styles } from 'styles'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Fader from './effects/Fader'

function CheckoutInfo(props) {
  const classes = styles()

  const handleFirstName = (event) => {
    var temp_order = props.order
    temp_order.first_name = event.target.value
    props.update_order(temp_order)
  }

  const handleAdress = (event) => {
    var temp_order = props.order
    temp_order.adress = event.target.value
    props.update_order(temp_order)
  }

  const handleLastName = (event) => {
    var temp_order = props.order
    temp_order.surname = event.target.value
    props.update_order(temp_order)
  }

  const handlePostalCode = (event) => {
    var temp_order = props.order
    temp_order.postal_code = event.target.value
    props.update_order(temp_order)
  }

  const handleEmail = (event) => {
    var temp_order = props.order
    temp_order.email = event.target.value
    props.update_order(temp_order)
  }

  const handleCounty = (event) => {
    var temp_order = props.order
    temp_order.ort = event.target.value
    props.update_order(temp_order)
  }

  const handlePhoneNumber = (event) => {
    var temp_order = props.order
    temp_order.phone = event.target.value
    props.update_order(temp_order)
  }

  return (
    <Fader in={true}>
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="firstName"
              label="Förnamn"
              type="text"
              autoComplete="given-name"
              variant="filled"
              fullWidth
              onChange={(event) => handleFirstName(event)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="address"
              label="Adress"
              type="text"
              autoComplete="off"
              variant="filled"
              fullWidth
              onChange={(event) => handleAdress(event)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              label="Efternamn"
              type="text"
              autoComplete="family-name"
              variant="filled"
              fullWidth
              onChange={(event) => handleLastName(event)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="postal-code"
              label="Postnummer"
              type="text"
              autoComplete="postal-code"
              variant="filled"
              fullWidth
              onChange={(event) => handlePostalCode(event)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              label="Ort"
              type="text"
              autoComplete="address-level2"
              variant="filled"
              fullWidth
              onChange={(event) => handleCounty(event)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              label="Mailadress"
              type="text"
              autoComplete="email"
              variant="filled"
              fullWidth
              onChange={(event) => handleEmail(event)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="tel"
              label="Telefonnummer"
              type="text"
              autoComplete="tel"
              variant="filled"
              fullWidth
              onChange={(event) => handlePhoneNumber(event)}
            />
          </Grid>
        </Grid>
      </form>
    </Fader>
  )
}

export default CheckoutInfo
