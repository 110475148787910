import React from 'react'
import Ticket from './ticket'
import NextBackButtons from './NextBackButtons'
import { Grid, Box, IconButton } from '@material-ui/core'

const PLACEHOLDER_TICKETSXXX = [
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
  {
    name: '4 dagar under 1 månad',
    price: {
      child: {
        first: 100,
        second: 200,
      },
      young: {
        first: 2000,
        second: 3000,
      },
      adult: {
        first: 4000,
        second: 3000,
      },
      senior: {
        first: 2000,
        second: 1000,
      },
    },
    picPath: 'assets/hanjtkj245c41.jpg',
  },
]

function priceParser(ticket) {
  var altered_ticket = ticket
  if (typeof ticket.price == 'string') {
    altered_ticket.price = JSON.parse(ticket.price)
  }
  return altered_ticket
}

class TicketViewGenerator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tickets: [],
      PLACEHOLDER_TICKETS: PLACEHOLDER_TICKETSXXX,
      expanded: Array.from({ length: 8 }, (i) => false),
    }
    this.handleExpansion = this.handleExpansion.bind(this)
    this.handleCollapse = this.handleCollapse.bind(this)
    //fetch("/alltickets").then((data) => this.setState({tickets: JSON.parse(data)})

    fetch(props.backend_adress + '/getflag', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        flagname: 'discount',
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.ans != 1) {
          fetch(props.backend_adress + '/alltickets')
            .then((resp) => resp.json())
            .then((data) => this.setState({ tickets: data.ans }))
        } else {
          fetch(props.backend_adress + '/allticketsWithDiscount')
            .then((resp) => resp.json())
            .then((data) => this.setState({ tickets: data.ans }))
        }
      })

    props.change_stepper('Produkt')
  }

  handleExpansion(index) {
    var newExpanded = this.state.expanded
    newExpanded.forEach(function (element, i) {
      if (element === true) {
        newExpanded[i] = false
      }
    })
    newExpanded[index] = true
    this.setState({ expanded: newExpanded })
  }

  handleCollapse(index) {
    var newExpanded = this.state.expanded
    newExpanded[index] = false
    this.setState({ expanded: newExpanded })
  }

  collapseAll() {
    var newExpanded = this.state.expanded
    for (const [index] of newExpanded.entries()) {
      newExpanded[index] = false
    }
    this.setState({ expanded: newExpanded })
  }

  render() {
    return (
      <div>
        <Box width="100%">
          <Grid
            container
            justify="center"
            direction="row"
            spacing={1}
            alignItems="center"
            alignContent="center"
          >
            {this.state.tickets.map((ticket, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ position: 'relative' }}
                >
                  {/*                   <IconButton
                    style={{
                      position: 'absolute',
                      top: '4px',
                      right: '4px',
                      zIndex: '1',
                    }}
                  >
                    <HelpIcon style={{ color: 'white' }} />
                  </IconButton> */}
                  <Ticket
                    key={index}
                    index={index}
                    ticket={priceParser(ticket)}
                    expanded={this.state.expanded[index]}
                    handleExpansion={this.handleExpansion}
                    handleCollapse={this.handleCollapse}
                    add_to_shopping_cart={this.props.add_to_shopping_cart}
                    current_new_ticket_index={
                      this.props.current_new_ticket_index
                    }
                    modify_ticket_index={this.props.modify_ticket_index}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
          <NextBackButtons
            backPath={'/buy'}
            disableBack={true}
            removeBack={true}
            nextPath={'/buy/travelers'}
            disableNext={this.props.shopping_cart.length == 0}
          />
        </Box>
      </div>
    )
  }
}

export default TicketViewGenerator
