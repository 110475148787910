import React from 'react'
import {
  Card,
  Typography,
  CardContent,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import SecurityIcon from '@material-ui/icons/Security'
import NextBackButtons from './NextBackButtons'
import addition_class from '../addition_class'
import { styles } from 'styles'
import Fader from './effects/Fader'

function Additions(props) {
  const [avbestallningsskydd, setAvbestallningsskydd] = React.useState(
    props.find_item_in_shopping_cart(0) ? true : false
  )
  const classes = styles()

  props.change_stepper('Tillval')

  function handleAvbetalningsskydd(lamo) {
    setAvbestallningsskydd(lamo.target.checked)
    if (lamo.target.checked) {
      props.add_to_shopping_cart(
        new addition_class(0, 'Avbeställningskydd', 99)
      )
    } else {
      props.remove_from_shopping_cart(0)
    }
  }

  return (
    <>
      <Fader in={true}>
        <Card className={classes.addition} variant="elevation">
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" component="h2" align={'left'}>
                  Avbeställningsskydd
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <SecurityIcon />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" component="p" align={'left'}>
                <br />
                Med ett avbeställningsskydd till dina biljetter får du pengarna
                tillbaka om du behöver ställa in din resa. Då återbetalas det
                fulla biljettpriset utan att behöva bekosta någon returavgift.
                Avbeställningsskyddet gäller för samtliga biljetter i
                beställningen, men biljetterna måste avbokas innan dess de
                aktiveras. Mer information om innebörden av
                avbeställningsskyddet finns att finna i våra villkor.
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                style={{ width: '100%' }}
                control={
                  <Checkbox
                    color="default"
                    name="checked"
                    checked={avbestallningsskydd}
                    onClick={(lamo) => handleAvbetalningsskydd(lamo)}
                  />
                }
                label="Ja, tack! (+99kr)"
              />
            </Grid>
          </CardContent>
        </Card>
      </Fader>
      <NextBackButtons nextPath={'/buy/summary'} backPath={'/buy/travelers'} />
    </>
  )
}

export default Additions
