import React from 'react'
import Helmet from 'react-helmet'

function Funktionsvariation() {
  return funktionsvariation[0].body
}

var funktionsvariation = {
  content: [
    {
      title: 'Resa med en funktionsvariation',
      picPath: '',
      body: (
        <div>
          Om du är en resenär med en funktionsvariation eller nedsatt rörlighet
          och behöver hjälp längs vägen, kan du ordna denna assistans direkt med
          transportören. De flesta järnvägsföretag i Europa erbjuder assistans
          för funktionsnedsatta passagerare. Dessutom krävs ofta en separat
          bokning för många tåg i hela Europa, särskilt om du reser med en
          rullstol.
          <br />
          <br />
          Du kan hitta mer information om hur du ordnar assistans och var du kan
          göra reservationer längre ner på denna sida.
          <br />
          <br />
          Om du behöver ta med en vårdgivare på din resa kan du vara berättigad
          att ansöka om ett kostnadsfritt Interrail Pass för din vårdgivare.
          Kontakta gärna vår vänliga kundtjänst för mer information.
          <br />
          <br />
          <b>Passagerarassistans i Europa</b>
          <br />
          <br />
          Om du ger dig ut på ditt Interrail-äventyr med nedsatt rörlighet kan
          du begära assistans vid ombordstigning och avstigning från tåget för
          att säkerställa en smidig upplevelse ombord.
          <br />
          <br />
          Varje land och tågoperatör har sina egna riktlinjer för
          passagerarassistans och reservationer för personer med nedsatt
          rörlighet, så vi rekommenderar att du kontrollerar detta för ditt
          aktuella avrese- och destinationsland. Informationen per land är
          uppdelad i två delar:
          <br />
          <ul>
            <li>
              <b>Assistans</b>
              <br />
              Assistans innebär att ett järnvägsbolag kommer att hjälpa dig att
              stiga på och av ditt tåg. Det är alltid bra att kolla med varje
              land och tågoperatör om hur, när och var du kan begära
              passagerarassistans. Om du är en resenär med funktionsvariation
              eller nedsatt rörlighet och behöver assistans under resan kan du
              ordna detta direkt med transportören.
            </li>
            <br />
            <li>
              <b>Reservationer</b>
              <br />
              Reservationer kan göras på många tåg i hela Europa - det kommer
              att finnas länkar längre ner på denna sida där du kan hitta sådan
              information. Det är ofta också möjligt att boka en rullstolsplats
              och en 'följeslagarplats' bredvid.
            </li>
          </ul>
          <br />
          <b>Bra att veta vid platsreservation med rullstol:</b>
          <br />
          <ol>
            <li>
              När du bokar reservationer med rullstolar, se till att schemalägga
              tillräckligt med överföringstid eller begära det. Tågplanerare kan
              föreslå vissa tåg, men förseningar kan alltid inträffa och med
              sådana specifika reserverade platser kan det vara svårt att boka
              nya platser i sista minuten.
            </li>
            <br />
            <li>
              Var medveten om dina rättigheter om du stöter på svårigheter. Det
              är bra att veta att det finns internationella lagar som reglerar
              resande med funktionsvariation. För mer information, se{' '}
              <a
                href="https://europa.eu/youreurope/citizens/travel/transport-disability/reduced-mobility/index_sv.htm#train"
                target="_blank"
              >
                här
              </a>
              .
            </li>
            <br />
            <li>
              När du bokar per telefon, se till att ha ett kreditkort redo. Var
              också medveten om att call-center-personal inte alltid talar
              engelska, så en översättare kan vara till hjälp.
            </li>
            <br />
            <li>
              Att ordna assistans är en kostnadsfri tjänst som tillhandahålls av
              järnvägsoperatörer. Säte- eller rullstolsreservationer å andra
              sidan kommer vanligtvis med vissa avgifter.
            </li>
          </ol>
          <br />
          <b>Assistansförfrågan - e-mail mall</b>
          <br />
          <br />
          När du begär assistans behöver du oftast tillhandahålla
          standardinformation på ett visst format. Det kan vara till hjälp att
          använda sig av en mall så att du snabbt kan skicka komplett
          information. Vi har tagit fram en sådan och använd gärna den:
          <br />
          <br />
          Ämne: Assistance Request for Upcoming Journey
          <br />
          <br />
          <cite>
            Hello [Tågbolagets namn],
            <br />
            <br />
            I hope this message finds you well. I am planning a trip with
            [Tågbolagets namn] and I would need assistance to make my journey as
            smooth as possible. Below, you will find the necessary details for
            my request:
            <br />
            <br />
            Type of assistance needed: [Rullstol/Annat]
            <br />
            Date of the journey: [Ange datum]
            <br />
            From departure station: [Ange station]
            <br />
            To arrival station: [Ange station]
            <br />
            Type and size of my mobility aid: [Ange typ och storlek]
            <br />
            Type of disability: [Ange typ av funktionsvariation]
            <br />
            Number of pieces of luggage or travel companions: [Ange antal]
            <br />
            My name: [Ditt namn]
            <br />
            My phone number: [Ditt telefonnummer]
            <br />
            My email address: [Din e-postadress]
            <br />
            Ticket information: I have a valid [Ange klass] Interrail Pass
            [Eventuellt passnummer].
            <br />
            <br />
            I would appreciate it if you could send me a confirmation once the
            assistance is booked. Thank you in advance for your assistance!
            <br />
            <br />
            Please consider my request and help me ensure a smooth and
            comfortable journey. I look forward to traveling with [Tågbolagets
            namn].
            <br />
            <br />
            Best regards, [Ditt namn]
          </cite>
          <br />
          <br />
          <br />
          <b>Resa med ledarhundar</b>
          <br />
          <br />
          Ledarhundar, assistanshundar eller tjänstehundar är generellt välkomna
          på tåg i Europa, men det beror mycket på resan och landet. Det är
          viktigt att ta med ledarhundscertifikatet eller -registreringen. När
          du reser på inrikes tåg tillåter de flesta länder ledarhundar utan
          kostnad och utan reservation. På internationella tåg är reglerna mer
          komplicerade, och det är bäst att kontrollera specika bestämmelser för
          avrese- och inreselandet eller den specifika tågoperatören.
        </div>
      ),
    },
  ],
  img_title: 'Tågluffa med funktionsvariation',
  img_path: 'guider/aktivera-mobile-pass.png',
  title: 'Tågluffa med funktionsvariation',
  header:
    'Vi går igenom vilka rättigheter man har och vilken hjälp man kan få som funktionsnedsatt på tågluff i Europa. Läs vår artikel för att resan ska gå så smidigt som möjligt.',
  sitePath: '/funktionsvariation',
  helmet: (
    <Helmet>
      <meta
        name="description"
        content="Vi går igenom vilka rättigheter man har och vilken hjälp man kan få som funktionsnedsatt på tågluff i Europa. Läs vår artikel för att resan ska gå så smidigt som möjligt."
      />
      <meta
        name="keywords"
        content="handikapp, disability, tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff"
      />
      <title>Tågluffa med funktionsvariation i Europa</title>
      <link
        rel="canonical"
        href="https://tagluffaieuropa.se/artiklar/funktionsvariation"
      />
      <script type="application/ld+json">{`
  {
    "@context": "http://schema.org",
    "@type": "Artikel",
    "about": "Tågluffa med funktionsvariation i Europa"
    "name": "Tågluffa med funktionsvariation"
    "text": ""
    "reviewAspect": ["Rullstol, Funktionsvariation, Assistans"]
  }
`}</script>
    </Helmet>
  ),
}

export default Funktionsvariation

export { funktionsvariation }
