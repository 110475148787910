import React from 'react'
import { Button, Dialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import ConsentOptions from './ConsentOptions'
import ConsentContent from './ConsentContent'
import CookieInformation from './CookieInformation'

import { styles } from 'styles'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

function CookieConsentPopUp(props) {
  const classes = styles()
  const [open, setOpen] = React.useState(true)
  const [settings, setSettings] = React.useState(false)
  const [about, setAbout] = React.useState(false)
  const [cookies, setCookies] = React.useState({
    GA4: 'false',
    ad_storage: 'false',
  })

  const EXPIRATION_DAYS = 30
  const COOKIE_NAME = 'consentCookie'

  let consentCookie = getConsentCookie(COOKIE_NAME)

  const handleAccept = () => {
    let cookies_copy = JSON.parse(JSON.stringify(cookies))
    cookies_copy.GA4 = 'true'
    cookies_copy.ad_storage = 'true'
    setCookies(cookies_copy)
    handleSave(cookies_copy)
  }

  const handleSave = (updatedCookies) => {
    const exdate = new Date()
    exdate.setDate(exdate.getDate() + EXPIRATION_DAYS)
    var cookieValue =
      JSON.stringify(updatedCookies) +
      '; samesite=Lax' +
      '; path=/ ; expires=' +
      exdate.toUTCString()
    document.cookie = COOKIE_NAME + '=' + cookieValue

    if (updatedCookies.GA4 === 'true') {
      props.gtag('consent', 'update', { analytics_storage: 'granted' })
    }

    if (updatedCookies.ad_storage === 'true') {
      props.gtag('consent', 'update', { ad_storage: 'granted' })
    }

    props.gtag('event', 'consentConfiguredEvent')

    setOpen(false)
  }

  const handleSettings = () => {
    setSettings(true)
  }

  if (!consentCookie) {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers>
          {settings ? (
            about ? (
              <CookieInformation />
            ) : (
              <ConsentOptions
                cookies={cookies}
                setCookies={setCookies}
                setAbout={setAbout}
              />
            )
          ) : (
            <ConsentContent />
          )}
        </DialogContent>
        <DialogActions>
          {settings ? (
            about ? (
              <Button onClick={() => setAbout(false)} color="primary">
                Tillbaka
              </Button>
            ) : (
              <Button onClick={() => handleSave(cookies)} color="primary">
                Spara
              </Button>
            )
          ) : (
            <>
              <Button onClick={handleSettings} color="primary">
                Inställningar
              </Button>
              <Button onClick={handleAccept} color="primary">
                Godkänn alla cookies
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    )
  } else {
    if (consentCookie.GA4 === 'true') {
      props.gtag('consent', 'update', { analytics_storage: 'granted' })
    }

    if (consentCookie.ad_storage === 'true') {
      props.gtag('consent', 'update', { ad_storage: 'granted' })
    }

    props.gtag('event', 'consentConfiguredEvent')
    return null
  }
}

function getConsentCookie(cookieName) {
  const documentCookies = document.cookie.split(';')
  for (const cookie of documentCookies) {
    const posEquals = cookie.indexOf('=')
    const name = cookie.substring(0, posEquals).replace(/^\s+|\s+$/g, '')
    const value = cookie.substring(posEquals + 1).replace(/^\s+|\s+$/g, '')
    if (name === cookieName) {
      try {
        return JSON.parse(value)
      } catch (e) {
        return undefined
      }
    }
  }
  return undefined
}

export default CookieConsentPopUp
