import React from 'react'
import Helmet from 'react-helmet'

var interrail_for_beginners = {
  content: [
    {
      title: 'Tågluffning för nybörjare',
      subtitle: '',
      picPath: 'guider/att-tanka-pa-tagluffaieuropa.jpg',
      body: (
        <div>
          Innan man ger sig ut i Europa är det bra att känna till tips, tricks
          och i allmänhet veta hur man bör gå tillväga för att tågluffa. I denna
          guide finns information värd att veta och tips för en lyckad resa.
        </div>
      ),
    },
    {
      title: '',
      subtitle: 'Planera din resa',
      picPath: '',
      body: (
        <div>
          Det är mycket viktigt att lägga tid på att planera sin resa innan
          avfärd. När man tågluffar har man inte oändligt med tid och
          interrail-kortet är i vissa fall begränsande. Man vill gärna lägga så
          lite pengar som möjligt på transporten och i de fall man skaffat sig
          ett interrailkort med 5-15 resdagar under 1-2 månader, så bör man
          lägga extra tanke kring vilka tåg man tar och vid vilken tid de avgår.
          Om man planerar färdas långt bort, men samtidigt önskar göra stopp på
          vägen gäller det att använda resdagarna så effektivt som möjligt. I
          RailPlanner-appen kan man söka resor och planera sin resa; gör det.
          Dessutom kan appen spara ner tågtabeller offline i telefonen.
          <br />
          <br />
          En fråga vi ofta får är vad som räknas som en resdag. Resdagarna
          räknas baserat på vilket datum man reser och alltså inte på hur många
          timmar det gått sedan du satte dig på första tåget. Ett förklarande
          exempel:
          <br />
          <cite>
            Janne sätter sig på ett tåg 18:00 den 6 juni och är framme 22:55
            samma dag. Klockan 03:15 den 7 juni sätter han sig på ett nytt tåg
            och är framme 07:45 samma dag. I detta fall har Janne använt två
            resdagar på sitt kort i och med att han rest över två olika
            kalenderdagar.
          </cite>
          <br />
          <br />
          Med andra ord så är det inte alltid smart att ta ett sent tåg om det
          inte tar en enda fram till destinationen, utan man behöver ta ett nytt
          tåg dagen efter. I det fallet kan det vara bättre att ta ett tåg som
          går efter midnatt, eller att börja åka tidigt på morgonen.
          <br />
          <br />
          <b>Nattåg</b>
          <br />
          Vad gäller nattåg så räknas dessa alltid som en resdag, tillhörande
          dagen för avresa med nattåget. Om nattåget avgår innan midnatt, så
          kommer man ha rest en resdag när man kommer fram. Om man behöver göra
          ett tågbyte efter ankomst med nattåget och således genomför
          ytterligare en tågresa efter midnatt, räknas det som två resdagar.
        </div>
      ),
    },
    {
      title: '',
      subtitle: 'Vid olycka',
      picPath: '',
      body: (
        <div>
          När man reser finns det förstås lika stor, om inte större, risk att
          vara med om en olycka, som hemma. Då är det viktigt att ha koll på vad
          som gäller innan man ger sig av. Om man reser inom Norden har man
          alltid rätt till vård. Vården sker då på samma ekonomiska
          förutsättning som för invånarna i det land du besöker. När vi säger
          vård, menar vi inte endast akut sjukvård, utan även tandvård och
          läkemedel. Reser man runt i Europa är det nödvändigt att ha med sig
          ett EU-kort som visar att du är försäkrad i Sverige. Detta EU-kort
          beställer du av{' '}
          <a href="https://www.forsakringskassan.se/privatperson/flytta-jobba-studera-eller-fa-vard-utomlands/eu-kort-och-vard-utomlands/bestall-eu-kort#/">
            försäkringskassan
          </a>
          . Tänk på att kortet tar tid att tillverka och att det kan vara
          väntetid, så var ute i god tid.
          <br />
          <br />
          Ska du däremot resa utanför EU, finns inte längre samma möjlighet till
          gratis sjukvård. Det finns vissa länder som Sverige har vissa avtal
          med och som kan ge viss sjukvård. Villkoren skiljer sig från land till
          land, så var noga med att kolla upp vad som gäller dit du ska. Ska du
          till ett land utanför EU så finns det sällan möjlighet till ersättning
          av vård, med vanlig hemförsäkring. Om du vill ha möjlighet till detta
          måste du teckna en privat reseförsäkring. Mer information om
          försäkringars giltighet vid resa hittar du hos ditt försäkringsbolag.
          När det gäller en tågluff med InterRail-kort behöver man endast skaffa
          privat reseförsäkring om man skulle åka till Turkiet.
        </div>
      ),
    },
    {
      title: '',
      subtitle: 'Pass med på resan',
      picPath: '',
      body: (
        <div>
          När man reser inom EU krävs i regel inget pass, men det är något som
          man ska ta med ändå. Flertalet länder i östeuropa kräver pass vid sina
          gränskontroller. Dessutom kan pass alltid vara bra om någonting skulle
          hända, vare sig det är extra tullkontroll, sjukhusbesök, eller vid
          poliskontakt. Ett pass kan verkligen underlätta din resa om du skulle
          hamna i oanade situationer.
        </div>
      ),
    },
  ],
  img_title: 'image title',
  img_path: 'guider/att-tanka-pa-tagluffaieuropa.jpg',
  title: 'Tågluffning för nybörjare',
  header:
    'Innan man ger sig ut i Europa är det bra att känna till tips, tricks och i allmänhet veta hur man bör gå tillväga för att tågluffa. I denna guide finns information värd att veta och tips för en lyckad resa.',
  sitePath: '/tagluffning-for-nyborjare',
  helmet: (
    <Helmet>
      <meta
        name="description"
        content="Innan man ger sig ut i Europa är det bra att känna till tips, tricks och i allmänhet hur bör gå tillväga för att tågluffa. I denna guid finns information värd att veta och tips för en lyckad resa."
      />
      <meta
        name="keywords"
        content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, nybörjare, information, guide, planera, planering"
      />
      <title>Tågluffning för nybörjare</title>
      <link
        rel="canonical"
        href="https://tagluffaieuropa.se/guider/tagluffning-for-nyborjare"
      />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Article',
          author: {
            '@type': 'Organization',
            name: 'Tågluffa i Europa',
            url: 'https://www.tagluffaieuropa.se',
          },
          image:
            'https://www.tagluffaieuropa.se/static/media/att-tanka-pa-tagluffaieuropa.jpg',
          headline: 'Tågluffning för nybörjare',
          datePublished: '2024-02-11T08:00:00+08:00',
          dateModified: '2024-02-11T09:20:00+08:00',
          text: 'Innan man ger sig ut i Europa är det bra att känna till tips, tricks och i allmänhet hur bör gå tillväga för att tågluffa. I denna guid finns information värd att veta och tips för en lyckad resa.',
        })}
      </script>
    </Helmet>
  ),
}

export default interrail_for_beginners
