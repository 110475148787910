import React from 'react'
import Helmet from 'react-helmet'
import { styles } from 'styles'
import { Grid, Typography, Box, Fade } from '@material-ui/core'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import interrail_for_beginners from '../assets/guider/interrail_for_beginners'
import valj_interrailkort from '../assets/guider/valj_interrailkort'
import vinresa_frankrike from '../assets/guider/vinresa_frankrike'
import paris_frankrike from '../assets/guider/paris_frankrike'
import packning from '../assets/guider/packning'
import { mobile_pass } from 'assets/guider/mobile-pass'
import GuideCard from './guideCard.js'
import StaticPage from './StaticPage.js'
import Fader from './effects/Fader'
import { medPaResan } from 'assets/guider/med-pa-resan'
import prag_tjeckien from 'assets/guider/prag_tjeckien'
import cinque_terre from 'assets/guider/cinque_terre'

/**
 * The idea behind this page is to provide a filterable list of all guides available. All guides are supposed
 * to exist in one .js-file each in the assets/guides folder and should be able to render into a cars at this
 * webpage, but also be able to render into a static page once the card is clicked.
 */

function GuideList(props) {
  let match = useRouteMatch()
  const classes = styles()
  var list_of_guides = [
    interrail_for_beginners,
    mobile_pass,
    packning,
    valj_interrailkort,
    //vinresa_frankrike,
    medPaResan,
    paris_frankrike,
    prag_tjeckien,
    cinque_terre,
  ] //TODO: Put all content in a folder and import all files of that folder, put in this array and fill the page.

  return (
    <div>
      <Switch>
        {list_of_guides.map((guide, index) => (
          <Route exact path={`${match.path}${guide.sitePath}`} key={index}>
            {guide.helmet ? guide.helmet : <></>}
            <StaticPage content={guide.content} />
          </Route>
        ))}
        {
          'This is wierd that the top page does not facilitate the pages underneath it. Should be fixed in the future'
        }
        <Route exact path={`${match.path}/`}>
          <Helmet>
            <meta
              name="description"
              content="För att på förhand vara så förberedd som möjligt inför sin resa har vi sammanställt ett otal guider. Dessa guider kan antingen ge tips och tricks kring att tågluffa eller ge rekommentationer kring resplan och destinationer."
            />
            <meta
              name="keywords"
              content="tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff, interrailkort, guide, guider, information, hjälp, resmål, destination, tips"
            />
            <title>Guider</title>
            <link rel="canonical" href="https://tagluffaieuropa.se/guider/" />

            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Article',
                author: {
                  '@type': 'Organization',
                  name: 'Tågluffa i Europa',
                  url: 'https://www.tagluffaieuropa.se',
                },
                headline: 'Tågluffarguider',
                datePublished: '2024-02-05T08:00:00+08:00',
                dateModified: '2024-02-05T09:20:00+08:00',
                text: 'För att på förhand vara så förberedd som möjligt inför sin resa har vi sammanställt ett otal guider. Dessa guider kan antingen ge tips och tricks kring att tågluffa eller ge rekommendationer kring resplan och destinationer.',
              })}
            </script>
          </Helmet>
          <StaticPage
            content={[
              {
                title: 'Guider',
                subtitle: '',
                picPath: '',
                body: list_of_guides.map((guide, index) => (
                  <Box marginBottom={1} key={index}>
                    <GuideCard content={guide} path={match.path} />
                  </Box>
                )),
              },
            ]}
          />
        </Route>
        <Route path={match.path}>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  )
}

export default GuideList
