import React, { useEffect } from 'react'
import TicketViewGenerator from './ticketViewGenerator'
import FieldWrapper from './fieldWrapper'
import TravellerInfo from './traveller'
import Summary from './summary'
import CheckoutSummary from './checkoutSummary'
import StepperBar from './StepperBar'
import Additions from './additions'
import CheckoutInfo from './checkoutInfo'
import DeliveryInfo from './deliveryInfo'
import Checkout from './checkout'
import NextBackButtons from './NextBackButtons'
import EndInfo from './EndInfo'
import PrintTravellers from './../PrintTravellers'
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { Grid, Typography, Box } from '@material-ui/core'
import { ContactSupportOutlined } from '@material-ui/icons'
import Login from './Login'
import Fader from './effects/Fader'

function TicketBuy(props) {
  let match = useRouteMatch()
  const small_margin = 1
  const small_center = 10
  const big_margin = 2
  const big_center = 8

  const [step, setStepper] = React.useState('Summering')

  const [checkValid, setCheckValid] = React.useState(false)

  const [error, setError] = React.useState()

  const [token, setToken] = React.useState(false)

  let location = useLocation()
  //Get bookingID query parameter
  const bookingID = new URLSearchParams(location.search).get('bookingID')

  function change_stepper(step) {
    setStepper(step)
  }

  if (token) {
    return <Login setToken={setToken} backend_adress={props.backend_adress} />
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <Grid container>
        <StepperBar activeStep={step} />
        <Grid item xs={small_margin} md={big_margin}></Grid>
        <Grid item xs={small_center} md={big_center}>
          <Switch>
            <Route path={`${match.path}/test`}>
              <div> I AM TESTING </div>
            </Route>
            <Route exact path={`${match.path}/ticketView`}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem',
                  paddingBottom: '2rem',
                  paddingTop: '1rem',
                }}
              >
                <h1 style={{ margin: 0 }}>Välj ditt Mobile Pass</h1>
              </div>
              <TicketViewGenerator
                add_to_shopping_cart={props.add_to_shopping_cart}
                change_stepper={change_stepper}
                current_new_ticket_index={props.current_new_ticket_index}
                modify_ticket_index={props.modify_ticket_index}
                shopping_cart={props.shopping_cart}
                backend_adress={props.backend_adress}
                gtag={props.gtag}
              />
            </Route>
            <Route exact path={`${match.path}/travelers`}>
              {props.shopping_cart.length <= 0 ? (
                <Redirect to={`${match.path}/ticketView`} />
              ) : (
                <Fader in={true}>
                  <div>
                    <Box component="span" m={1}>
                      <Typography>{error}</Typography>
                    </Box>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '1rem',
                        paddingBottom: '2rem',
                        paddingTop: '1rem',
                      }}
                    >
                      <h1 style={{ margin: 0 }}>Fyll i resenärinformation</h1>
                    </div>
                    <PrintTravellers
                      setError={setError}
                      shopping_cart={props.shopping_cart}
                      TravellerInfo={props.TravellerInfo}
                      update_ticket={props.update_ticket}
                      remove_from_shopping_cart={
                        props.remove_from_shopping_cart
                      }
                      are_tickets_valid={props.are_tickets_valid}
                      set_tickets_valid_to_false={
                        props.set_tickets_valid_to_false
                      }
                      order={props.order}
                      change_stepper={change_stepper}
                    />
                    <FieldWrapper
                      content_function={function foo(
                        ticket = null,
                        funkis = null
                      ) {
                        return null
                      }}
                      price={props.summarize_price()}
                      bigPrice={true}
                      title={'Totalt'}
                      bin={false}
                    />
                    <NextBackButtons
                      backPath={'/buy/ticketView'}
                      nextPath={`/buy/checkout`}
                      disableNext={!props.tickets_valid}
                      gtag={props.gtag}
                      gtag_event={'begin_checkout'}
                    />
                  </div>
                </Fader>
              )}
            </Route>
            {/*  <Route exact path={`${match.path}/additions`}>
              {props.shopping_cart.length <= 0 ? (
                <Redirect to={`${match.path}/ticketView`} />
              ) : (
                <>
                  <Additions
                    add_to_shopping_cart={props.add_to_shopping_cart}
                    change_stepper={change_stepper}
                    remove_from_shopping_cart={props.remove_from_shopping_cart}
                    find_item_in_shopping_cart={
                      props.find_item_in_shopping_cart
                    }
                  />
                  <FieldWrapper
                    content_function={function foo(
                      ticket = null,
                      funkis = null
                    ) {
                      return null
                    }}
                    price={props.summarize_price()}
                    bigPrice={true}
                    title={'Totalt'}
                    bin={false}
                  />
                </>
              )}
            </Route> */}
            {/*           <Route exact path={`${match.path}/summary`}>
              {props.shopping_cart.length <= 0 ? (
                <Redirect to={`${match.path}/ticketView`} />
              ) : (
                <>
                  <div>
                    <Fader in={true}>
                      <div>
                        <FieldWrapper
                          content_function={Summary}
                          price={props.summarize_price()}
                          bigPrice={false}
                          title={'Summering'}
                          ticket={props.shopping_cart}
                          bin={false}
                          change_stepper={change_stepper}
                        />
                        <FieldWrapper
                          content_function={function foo(
                            ticket = null,
                            funkis = null
                          ) {
                            return null
                          }}
                          price={props.summarize_price()}
                          bigPrice={true}
                          title={'Totalt'}
                          bin={false}
                        />
                      </div>
                    </Fader>
                  </div>
                  <NextBackButtons
                    backPath={`${match.path}/travelers`}
                    nextPath={'/buy/checkout'}
                  />{' '}
                </>
              )}
            </Route> */}
            <Route exact path={`${match.path}/checkout`}>
              {props.shopping_cart.length <= 0 && !bookingID ? (
                <Redirect to={`${match.path}/ticketView`} />
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '1rem',
                      paddingBottom: '2rem',
                      paddingTop: '1rem',
                    }}
                  >
                    <h1 style={{ margin: 0 }}>Summering</h1>
                  </div>
                  <FieldWrapper
                    content_function={CheckoutSummary}
                    price={props.summarize_price()}
                    bigPrice={false}
                    title={'Dina produkter'}
                    ticket={props.shopping_cart}
                    bin={false}
                    change_stepper={change_stepper}
                  />
                  <FieldWrapper
                    content_function={CheckoutInfo}
                    order={props.order}
                    update_order={props.update_order}
                    price={null}
                    bigPrice={false}
                    title={'Dina uppgifter'}
                    bin={false}
                  />
                  {/*}
					<FieldWrapper
						content_function={DeliveryInfo}
						order={props.order}
						update_order={props.update_order}
						price={null}
						bigPrice={false}
						title={"Leverans"}
						bin={false}
					/>{*/}
                  <FieldWrapper
                    content_function={Checkout}
                    order={props.order}
                    update_order={props.update_order}
                    shopping_cart={props.shopping_cart}
                    find_item_in_shopping_cart={
                      props.find_item_in_shopping_cart
                    }
                    price={null}
                    bigPrice={false}
                    title={'Betalning'}
                    bin={false}
                    totalpris={props.summarize_price}
                    change_stepper={change_stepper}
                    backend_adress={props.backend_adress}
                    gtag={props.gtag}
                  />
                  <NextBackButtons
                    backPath={'/buy/travelers'}
                    removeNext={true}
                  />
                </>
              )}
            </Route>
            <Route exact path={`${match.path}/sendof`}>
              {props.order.eurail_order_id == null ? (
                <Redirect to={`${match.path}/ticketView`} />
              ) : (
                <EndInfo
                  order={props.order}
                  change_stepper={change_stepper}
                  flush_shopping_cart={props.flush_shopping_cart}
                  price={props.summarize_price()}
                />
              )}
            </Route>
            <Route path={match.path}>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </Grid>
        <Grid item xs={small_margin} md={big_margin}></Grid>
      </Grid>
    </div>
  )
}

export default TicketBuy
