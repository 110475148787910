function generate_GA4_event_parameters_item(item) {
  return {
    currency: 'SEK',
    price: item.get_price(),
    item_name: item.get_name(),
    item_id: item.get_sku(),
    item_category: item.get_age(),
    item_category2: item.get_comfort(),
  }
}

function generate_GA4_event_parameters_purchase(order) {
  return {
    currency: 'SEK',
    value: Math.round((order.get_total_VAT() * 1.06) / 0.06),
    tax: order.get_total_VAT(),
    items: order.order.map((item) => generate_GA4_event_parameters_item(item)),
    price: order.get_total_price(),
  }
}

export {
  generate_GA4_event_parameters_item,
  generate_GA4_event_parameters_purchase,
}
