import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { display } from '@material-ui/system'

var mobile_pass = {
  content: [
    {
      title: 'Hur du installerar ditt mobila pass',
      subtitle: '',
      picPath: '',
      body: (
        <div>
          <div>
            Kolla på den här videon om du föredrar att få din förklaring via
            video. Under videon hittar du en text som beskriver hur Interrails
            digitala biljettsystem fungerar.
            <br />
            <br />
          </div>
          <iframe
            width="100%"
            style={{
              aspectRatio: '16/9',
            }}
            src="https://www.youtube.com/embed/_s_AhIqxR-M?si=6JRoicb4bQ6PdPq6"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      ),
    },
    {
      title: '',
      subtitle: 'Ladda ner railplanner appen',
      picPath: '',
      body: (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
        >
          <div>
            Innan du börjar rekomenderar vi att du laddar ner den senaste
            versionen av Rail Planner Appen. Du kommer behöva en
            internetanslutning för vissa funktioner i appen så ta det i
            beaktning när du är på resande fot. Appen är supportad för Android
            6.0 och senare, samt iOS 13.0 och senare.
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '1rem',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <a href="https://apps.apple.com/se/app/eurail-interrail-rail-planner/id579547877">
              <img
                src="https://tagluffaieuropa.se/pictures_for_external_use/AppStore.png"
                alt="App Store link"
                width="140"
              ></img>
            </a>
            <a href="https://play.google.com/store/apps/details?id=org.eurail.railplanner&pcampaignid=web_share">
              <img
                src="https://tagluffaieuropa.se/pictures_for_external_use/GooglePlay.png"
                alt="Google Play link"
                width="140"
              ></img>
            </a>
          </div>
        </div>
      ),
    },
    {
      title: '',
      subtitle: 'Aktivera ditt pass i appen',
      picPath: '',
      body: (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
        >
          <div>
            Få ditt "Mobile Pass" redo med tre enkla steg. Följ guiden för att
            aktivera din biljett.
          </div>
          <img src={require('../passguide/step1.png')}></img>
          <h2 style={{ margin: 0, fontSize: '16px' }}>
            Steg 1: Lägg till din biljett
          </h2>
          <div>
            Börja med att öppna appen och trycka på "Add your pass". Ange ditt
            efternamn och biljettnummer. Du hittar biljettnummret i
            konfirmationsmailet vi skickade till dig efter din bokning.
            <br />
            <br />
            Efter att biljetten är aktiverad hittar du den under "My Pass."
          </div>
          <div style={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>
            Reser ni som en grupp
          </div>
          <div>
            Du kan lägga till flera biljetter i samma app. Men kom ihåg att du
            inte kan flytta en biljett mellan olika enheter om biljetten är
            aktiverad.
          </div>
          <div style={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>
            Reser ni med barn
          </div>
          <div style={{ marginBottom: '3rem' }}>
            En barnbiljett skall vara tillaggd på samma enhet som minst en
            vuxenbiljett.
          </div>
          <img src={require('../passguide/step2.png')}></img>
          <h2 style={{ margin: 0, fontSize: '16px' }}>
            Steg 2: Anslut en resa
          </h2>
          <div>
            Anslut en resa till din biljett. Med hjälp av resan kan du hålla
            full koll på hur du skall åka. Du kan skapa en ny resa eller välja
            en redan skapad resa och koppla ihop den med din biljett.
          </div>
          <div style={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>
            Mer än en biljett på din enhet?
          </div>
          <div style={{ marginBottom: '3rem' }}>
            Varje biljett behöver sin egen resa. Du kan dupplicera en resa för
            att koppla din till flera biljetter på samma enhet.
          </div>
          <img src={require('../passguide/step3.png')}></img>
          <h2 style={{ margin: 0, fontSize: '16px' }}>
            Steg 3: Aktivera din biljett
          </h2>
          <div>
            Innan du åker iväg måste du aktivera din biljett så det är redo att
            användas. För att göra detta behöver du ditt pass eller ID och du
            behöver veta vilket datum du planerar att påbörja din resa.
          </div>
          <div style={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>
            Är du inte säker på vart du börjar din resa?
          </div>
          <div>
            Du behöver inte oroa dig. Du kan göra detta steget senare. Det går
            att aktivera en biljett ända fram tills ditt första resedatum. Men
            kom ihåg att du kan inte gå på ett tåg utan ett aktiverad biljett.
            Ett pass behöver aktiveras max 11 månader efter köpdatum.
          </div>
        </div>
      ),
    },
    ,
    {
      title: 'Redo att gå på tåget',
      subtitle: '',
      picPath: '',
      body: (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
        >
          <div>
            Få din biljett redo med tre enkla steg. Följ guiden för att aktivera
            ditt pass.
          </div>
          <img src={require('../passguide/Plan a journey.png')}></img>
          <h2 style={{ margin: 0, fontSize: '16px' }}>Planera din resa</h2>
          <div>
            Sök tåg och resor i appen. Tryck på en resa för att få se alla
            resedetaljer. Du kan filtrera sökresultatet.
          </div>
          <img src={require('../passguide/Save it to My Trip.png')}></img>
          <h2 style={{ margin: 0, fontSize: '16px' }}>
            Spara den till dina resor
          </h2>
          <div>
            När du hittat en resa som passar dig tryck på den för att spara den
            bland dina resor.
          </div>
          <img src={require('../passguide/Add it to your Pass.png')}></img>
          <h2 style={{ margin: 0, fontSize: '16px' }}>
            Koppla din resa till ditt pass
          </h2>
          <div>
            Redo att ge dig av? Gå till mina resor och lägg till din resa till
            ditt Pass.
          </div>
          <img src={require('../passguide/Show your ticket.gif')}></img>
          <h2 style={{ margin: 0, fontSize: '16px' }}>Visa din biljett</h2>
          <div>
            Detta är biljetten som biljettkontrollanten vill se. Varje resdag i
            ditt pass har en egen biljett och arje biljett visar alla resor du
            lagt till för den dagen.
            <br />
            <br />
            Du kan visa din biljett med två enkla steg gå till "My Pass" tryck
            på "Show ticket".
          </div>
        </div>
      ),
    },
  ],
  img_title: 'aktivera mobile pass',
  img_path: 'guider/aktivera-mobile-pass.png',
  title: 'Så aktiverar du ditt Mobila Pass',
  header:
    'Vi hjälper dig genom alla steg till att lyckas aktivera ditt mobila interrail pass.',
  sitePath: '/mobile-pass',
  helmet: (
    <Helmet>
      <meta
        name="description"
        content="Innan du ger dig iväg, behöver du komma igång med ditt mobila pass. Vi visar hur du aktiverar ditt mobila pass i tre enkla steg."
      />
      <meta
        name="keywords"
        content="mobile pass, aktivera, interrailkort, biljett, tågluffa, interrail, tågluffaieuropa, europa, tågsemester, tåg, semester, tågluff"
      />
      <title>Så aktiverar du ditt Mobila Pass</title>
      <link
        rel="canonical"
        href="https://tagluffaieuropa.se/guider/mobile-pass"
      />
      <script type="application/ld+json">{`
      {
        "@context": "http://schema.org",
        "@type": "Guide",
        "about": "Så aktiverar du ditt Mobila Pass"
        "name": "Mobile Pass"
        "text": ""
        "reviewAspect": ["Mobile Pass, Biljett,
        Aktivera"]
      }
    `}</script>
    </Helmet>
  ),
}

export { mobile_pass }
