import React from 'react'
import ticket_class from '../ticket_class'
import { DeleteForever } from '@material-ui/icons'
import {
  Typography,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  ButtonGroup,
} from '@material-ui/core'
import addition_class from 'addition_class'
import { styles } from 'styles'

function Shopping_cart_ticket(props) {
  const classes = styles()
  const ages = {
    CHD: 'Barn',
    YTH: 'Ungdom',
    ADT: 'Vuxen',
    SEN: 'Senior',
    99: 'Superold Jensboy',
  }

  const comforts = {
    FIRST: 'Första klass',
    STANDARD: 'Andra klass',
    third: 'Plankning',
    '1stclass': 'Första klass',
    '2ndclass': 'Andra klass',
  }

  function secondary_ticket_text() {
    if (props.ticket.type === 'ticket') {
      return (
        ages[props.ticket.get_age()] +
        ', ' +
        comforts[props.ticket.get_comfort()]
      )
    } else if (props.ticket.type === 'addition') {
      return null
    }
  }

  function getRoundedSalePrice() {
    if (props.ticket.discount_price < props.ticket.price) {
      return (
        Math.round(
          ((props.ticket.discount_price / props.ticket.price - 1) * 100) / 5
        ) *
          5 +
        '%'
      )
    } else {
      return 'Rabatt'
    }
  }

  return (
    <>
      <ListItem divider={true}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-end"
        >
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <ListItemText
                primary={props.ticket.get_name()}
                secondary={secondary_ticket_text()}
              />
            </Grid>
            {props.ticket.discount_price ? (
              <Grid item>
                <div className={classes.DiscountIndicator}>
                  <Typography
                    style={{
                      margin: 'auto',
                      textAlign: 'center',
                      color: 'white',
                    }}
                    align="center"
                    variant="body2"
                  >
                    {getRoundedSalePrice()}
                  </Typography>
                </div>
              </Grid>
            ) : (
              <div></div>
            )}
            {/*<Grid item>
							<IconButton
								edge="end"
								aria-label="comments"
								size="small"
								onClick={() =>
									props.ids.forEach((element) => {
										props.remove_from_shopping_cart(element);
									})
								}
							>
								<DeleteForever />
							</IconButton>
							</Grid>*/}
          </Grid>
          <Grid container direction="row" justify="space-between">
            <Grid item></Grid>
            {/* <Grid item>
							<ButtonGroup
								size="small"
								aria-label="small outlined button group"
							>
								<Button
									onClick={() =>
										props.remove_from_shopping_cart(props.ticket.get_id())
									}
								>
									-
								</Button>
								<Button disabled> {props.ticket_count} </Button>
								<Button
									onClick={() => {
										if (props.ticket.get_type() === "ticket") {
											props.add_to_shopping_cart(
												new ticket_class(
													Math.random(),
													props.ticket.get_name(),
													props.ticket.get_age(),
													props.ticket.get_comfort(),
													props.ticket.get_price()
												)
											);
										} else if (props.ticket.type === "addition") {
											props.add_to_shopping_cart(
												new addition_class(
													Math.random(),
													props.ticket.get_name(),
													props.ticket.get_price()
												)
											);
										}
									}}
								>
									+
								</Button>
							</ButtonGroup>
								</Grid> */}
            <Grid item>
              <Typography variant="body2">
                {props.ticket.get_price() + ' SEK'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </>
  )
}

export default Shopping_cart_ticket
