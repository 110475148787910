import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  TableHead,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import TableComponent from './tableComponent'
import EndSummary from './endsummary'
import { styles } from 'styles'

function OrderSummary(props) {
  const classes = styles()

  const theme = useTheme()
  const screenIsNotSmall = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>Orderinfo</Typography>
          {screenIsNotSmall ? (
            <TableComponent
              title={['Nr', 'Namn', 'Email', 'Telefon', 'Adress']}
              listitems={[
                [
                  1,
                  String(props.order.first_name + ' ' + props.order.surname),
                  String(props.order.email),
                  String(props.order.phone),

                  String(
                    props.order.adress +
                      ', ' +
                      props.order.postal_code +
                      ', ' +
                      props.order.ort
                  ),
                ],
              ]}
            />
          ) : (
            <TableContainer component={Paper}>
              <Table
                aria-label="summary of interrail passes in shopping cart"
                style={{ minWidth: '0px' }}
              >
                <TableHead className={classes.table}>
                  <TableRow>
                    <TableCell>Beställare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {props.order.first_name + ' ' + props.order.surname}
                      <br />
                      {props.order.phone}
                      <br />
                      {props.order.email}
                      <br />
                      {props.order.adress +
                        ', ' +
                        props.order.postal_code +
                        ', ' +
                        props.order.ort}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item xs={12}>
          <EndSummary
            change_stepper={function (dummy_value) {
              return
            }}
            ticket={props.order.order}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderSummary
