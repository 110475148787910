import React from "react";
import AlertEmailTemplate from "../alertEmailTemplate";
import { renderEmail } from "react-html-email";

export default async function sendAlertMail(order, backend_adress, errors) {
	var response;

	var baseURL = backend_adress + "/";
	var url = baseURL + "sendConfirmationMail";

	await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			from: '"Tågluffa i Europa" kundservice@tagluffaieuropa.se',
			email:
				"jens.hulteberg@tagluffaieuropa.se, emil.andersson@tagluffaieuropa.se",
			subject: "Felnotifikation",
			messageHtml: renderEmail(
				<AlertEmailTemplate
					name={order.first_name}
					ärendenummer={order.tagluffa_order_number}
					mail={order.email}
					telefon={order.phone}
					errors={errors}
				/>
			), // HTML code
		}),
	})
		.then(function (response) {
			if (!response.ok) {
				const message = `An error has occured: ${response.status}`;
				throw new Error(message);
			}
			return response.json();
		})
		.then(function (json) {
			if (json) {
				response = json;
				if (response.msg == "fail") {
					throw new Error("Error when sending alert mail.");
				} else if (response.msg == "success") {
					console.log("Successfully sent alert mail!");
				}
			}
		})
		.catch(function (error) {
			console.log("Alert mail failure: ", error);
			return "fail";
		});

	return "success";
}
