import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import backendAdress from "../backendAdress";
import { styles } from "styles";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// The tableComponent returns a complete table based on a title and itemlist input.
// Both inputs may be single strings or arrays of strings, the component will handle the input still.
// In order for the table to look good both input arrays would need to be of the same length.
//
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function TableComponent(props) {
	const classes = styles();
	const [listitems, setListitems] = React.useState(props.listitems);

	function headerPrinter(input) {
		if (Array.isArray(input)) {
			return input.map((value, index) => {
				return <TableCell key={index}>{value}</TableCell>;
			});
		} else {
			return <TableCell>{input}</TableCell>;
		}
	}

	React.useEffect(() => {
		if (props.listitems[0] == "InterrailPrices") {
			tickets_and_prices();
		}
		setListitems(props.listitems);
	}, [props.listitems]);

	function tickets_and_prices() {
		var ticketprices, currentPrice;
		var tablecontent = [];
		fetch(backendAdress + "/alltickets")
			.then((resp) => {
				return resp.json();
			})
			.then((data) => {
				ticketprices = data.ans;
				for (var i = 0; i < ticketprices.length; i++) {
					currentPrice = JSON.parse(ticketprices[i].price);
					tablecontent.push([
						ticketprices[i].name,
						currentPrice["YTH"]["2ndclass"].price +
							":- (" +
							currentPrice["YTH"]["1stclass"].price +
							":-)",
						currentPrice["ADT"]["2ndclass"].price +
							":- (" +
							currentPrice["ADT"]["1stclass"].price +
							":-)",
						currentPrice["SEN"]["2ndclass"].price +
							":- (" +
							currentPrice["SEN"]["1stclass"].price +
							":-)",
					]);
				}
				setListitems(tablecontent);
			})
			.catch((error) => console.log("Error occurres: " + error));
	}

	return (
		<TableContainer component={Paper}>
			<Table aria-label="packlista" style={{ minWidth: "0px" }}>
				<TableHead className={classes.table}>
					<TableRow>{headerPrinter(props.title)}</TableRow>
				</TableHead>
				<TableBody>
					{listitems.map((item, index) => {
						if (Array.isArray(item)) {
							return (
								<TableRow key={index}>
									{item.map((value, index) => {
										return <TableCell key={index}>{value}</TableCell>;
									})}
								</TableRow>
							);
						} else {
							return (
								<TableRow key={index}>
									<TableCell>{item}</TableCell>
								</TableRow>
							);
						}
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default TableComponent;
