import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'

const primary = '#ff8a65'
const secondary = '#006064'
const white = '#ffffff'
const black = '#000000'

const styles = makeStyles((theme) => ({
  header: {
    background: white,
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    paddingTop: '88px',
    overflowX: 'hidden',
    paddingBottom: '96px',
    overflow: 'hidden',
  },
  pagetabs: {
    height: '88px',
    fontSize: '16px',
  },
  bookingButton: {
    background: primary,
    borderRadius: 0,
    color: white,
    fontSize: '16px',
  },
  bookingButtonInvisible: {
    height: '24px',
    width: '24px',
    padding: '3px',
  },
  shoppingCartButton: {
    background: primary,
    borderRadius: 0,
    color: white,
    height: '36px',
    width: '36px',
  },
  animatedItem: {
    animation: `$myEffect 1000ms`,
  },
  '@keyframes myEffect': {
    '0%': { transform: 'scale(1,1) translateY(0)' },
    '10%': { transform: 'scale(1.1,0.9) translateY(0)' },
    '30%': { transform: 'scale(0.9,1.1) translateY(-20%)' },
    '50%': { transform: 'scale(1.05,0.95) translateY(0)' },
    '57%': { transform: 'scale(1,1) translateY(-10%)' },
    '64%': { transform: 'scale(1,1) translateY(0)' },
    '100%': { transform: 'scale(1,1) translateY(0)' },
  },
  ticket: {
    //    maxWidth: "248px",
    minHeight: '275px',
    maxHeight: '275px',
    position: 'relative',
    width: '100%',
  },
  ticketTitle: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    padding: '16px',
    color: white,
  },
  ticketImg: {
    filter: 'brightness(80%)',
  },
  addition: {
    backgroundColor: '#FAFAFA',
    //    maxWidth: "50%",
    position: 'relative',
    margin: 'auto',
    marginTop: '12px',
  },
  deliveryText: {
    lineHeight: '42px',
    color: '#C0C0C0',
  },
  textField: {
    width: '25ch',
  },
  wrapperDivider: {
    height: '2px',
    backgroundColor: black,
    color: black,
    marginBottom: '12px',
  },
  wrapperIcons: {
    color: black,
  },
  priceDistance: {
    paddingTop: '32px',
  },
  circleBullet: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: black,
    opacity: '54%',
    marginTop: '8px',
  },
  shopping_cart_drawer: {
    flexShrink: 0,
  },
  shopping_cart_list: {
    width: 320,
    flexShrink: 0,
    marginTop: '38px',
  },
  greyText: {
    color: '#757575',
    marginRight: '0px',
  },
  invalidText: {
    color: '#ff0000',
    fontSize: '12px',
    paddingTop: '6px',
  },
  swishLogo: {
    height: '100%',
    width: '100%',
  },
  swishButton: {
    width: '100%',
    height: '100%',
  },
  showOrderButton: {
    width: '100%',
    height: '56px',
  },
  disabledTableCell: {
    color: 'lightgrey',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  popup: {
    margin: 0,
    padding: theme.spacing(2),
  },
  shopping_cart_sticky_bottom: {
    backgroundColor: white,
    zIndex: theme.zIndex.drawer + 1,
  },
  blogImg: {
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
    verticalAlign: 'bottom',
    objectPosition: '50% 50%', //specific for suport-turku
  },
  nextBackButtons: {
    backgroundColor: white,
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    height: '48px',
    zIndex: 10,
  },
  boxForShadow: {
    width: '100%',
    height: '100%',
  },
  frontPageImage: {
    width: '100%',
    height: '60vh',
  },
  frontPageTextWraper: {
    height: '100%',
  },
  frontPageBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    padding: '20pt',
    marginTop: '20%',
  },
  frontPageTextOnImage: {
    color: black,
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    //marginTop: "20%",
  },
  HomepageBuyButton: {
    width: '100%',
    marginTop: '10%',
    borderRadius: 0,
    color: white,
  },
  frontpageDivider: {
    backgroundColor: primary,
    height: '2px',
    width: '100%',
  },
  frontpageCardImg: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    height: '140px',
  },
  first_shopping_cart: {},
  swishDialog: {
    width: '300px',
    height: '400px',
  },
  swishDialogBox: {
    width: '100%',
    height: '100%',
  },
  guides: {
    maxWidth: '100%',
  },
  contentborder: {
    backgroundColor: white,
    borderColor: primary,
    margin: 8,
    border: 1,
    borderStyle: 'solid',
    padding: 12,
    //alignContent: "center",
    //align: "center",
    //justifyContent: "center",
    //textAlign: "center",
    style: { width: '5rem', height: '5rem' },
    borderRadius: 16,
  },
  contentBoxTitle: {
    marginTop: -24,
    //backgroundColor: white,
  },
  backgroundColorWhite: {
    backgroundColor: white,
    paddingLeft: 8,
    paddingRight: 8,
  },
  verticalAlign: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignCenter: {
    textAlign: 'center',
  },
  DiscountIndicator: {
    backgroundColor: secondary,
    height: '3rem',
    width: '3rem',
    borderRadius: '50%',
    display: 'flex',
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.3)',
  },
  SaleBanner: {
    //transform: 'translateX(50%) rotate(315deg)',
    right: '0vmax',
    bottom: '0vmax',
    position: 'absolute',
    textAlign: 'left',
    color: 'white',
    backgroundColor: primary,
    width: 'calc(100% - 1rem)',
    padding: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
}))

const THEME = createMuiTheme({
  overrides: {},
  transitions: {
    duration: {
      shortest: 300,
      shorter: 300,
      short: 300,
      standard: 300,
      complex: 300,
      enteringScreen: 300,
      leavingScreen: 300,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
})

export { styles, THEME, primary, secondary }
