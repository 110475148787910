import React, { useEffect } from 'react'
import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Fade,
  Collapse,
  Paper,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { styles } from 'styles'
//These are removed due to conflicts maybe add them again later?
//import pic from "assets/provance-lavendar-fields.jpeg";
//import pic2 from "assets/railway-italy.jpeg";
import pic3 from 'assets/trainbridge.jpeg'
import pic1 from 'assets/swish-logo.jpg'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined'
import Fader from './effects/Fader'

var imageTitle = 'SKA DU UT OCH TÅGLUFFA?'
var imageText =
  'Då har du hamnat helt rätt. Köp ditt interrailkort redan idag och förbered dig inför 2024 års stora äventyr. Vi hjälper dig till en perfekt resa!'

function Homepage(props) {
  const classes = styles()

  function HomepageInformationBox(title, text, img, icon = null) {
    // Function rendering boxes of information and related picture/icon on frontpage
    const [picLoaded, setpicLoaded] = React.useState(false)

    if (icon) {
      return (
        <Grid
          container
          className={classes.frontPageTextWraper}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={3}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Fader in={true}>{img()}</Fader>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography
              variant="h6"
              style={{ marginBottom: '1rem' }}
              align={'left'}
            >
              {title}
            </Typography>
            <Typography variant="body1" align={'left'}>
              {' '}
              {text}{' '}
            </Typography>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid
          container
          className={classes.frontPageTextWraper}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={3}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Fader in={picLoaded}>
                  <img
                    className={classes.frontpageCardImg}
                    src={img}
                    alt={'Img'}
                    onLoad={() => setpicLoaded(true)}
                  />
                </Fader>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography
              variant="h6"
              style={{ marginBottom: '1rem' }}
              align={'left'}
            >
              {title}
            </Typography>
            <Typography variant="body1" align={'left'}>
              {' '}
              {text}{' '}
            </Typography>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Fader in={true}>
          <div
            className={classes.frontPageImage}
            style={{
              backgroundImage: `url(${pic3})`,
              backgroundPosition: '0% 35%', // for trainbridge: "0% 35%", // for railway italy: "0% 40%", // for provance lavendar fiels: "0% 50%", // for glennfinnian picture "50% 35%",
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <Grid
              container
              className={classes.frontPageTextWraper}
              alignItems="center"
              style={{ padding: '1rem' }}
            >
              <Grid item sm={1}></Grid>
              <Grid item sm={7} md={4}>
                <Paper className={classes.frontPageBox}>
                  <Typography
                    className={classes.frontPageTextOnImage}
                    variant="h4"
                    align={'left'}
                  >
                    {imageTitle}
                  </Typography>
                  <Typography variant="body1" align={'left'}>
                    {imageText}
                  </Typography>
                  <Grid item xs={12} align="center">
                    <Grid item xs={6} align="center">
                      <Button
                        variant="contained"
                        className={classes.HomepageBuyButton}
                        color="primary"
                        component={Link}
                        to="/buy/ticketView"
                        onClick={() => {
                          props.gtag('event', 'view_item_list')
                        }}
                      >
                        Köp
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sm={4} md={6}></Grid>
            </Grid>
          </div>
        </Fader>
      </Grid>
      <Grid item sm={12}>
        <Grid
          container
          className={classes.frontPageTextWraper}
          alignItems="center"
        >
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Box mt="4rem" />
            <Typography variant="h4" component="h1" align="center">
              Ditt äventyr som tågluffare börjar här!
            </Typography>
            <Divider variant="middle" className={classes.frontpageDivider} />
            <Box mt="4rem" />
            {HomepageInformationBox(
              'UPPTÄCK',
              'Ge dig iväg på ett äventyr du sent lär glömma. Med ett interrailkort kan du nå 33 länder i Europa och resa obegränsat från 5 dagar upp till en hel månad. Som hjälp har du också alla våra guider som kommer se till att äventyret går problem- och bekymmersfritt.',
              function () {
                return (
                  <ExploreOutlinedIcon
                    color="secondary"
                    style={{ fontSize: 140, color: '#003d80' }}
                  />
                )
              },
              true
            )}
            <Box mt="4rem" />
            {HomepageInformationBox(
              'SÄKERT',
              'Vi är Sveriges äldsta tågluffarplattform och har sedan 2009 arbetat för att förbättra resenärers tågluffarupplevelser. Vi sätter resenärernas intressen först, förbereder för att minimera risken för oväntade problem och är dessutom SSL-certifierade för att garantera säker handel i vår webshop. Med oss kan du känna dig trygga.',
              function () {
                return (
                  <LockOutlinedIcon
                    style={{ fontSize: 140, color: '#ffae42' }}
                  />
                ) //yellow "#ffcc00" "#faca0f"; orange: "#ffae42" //Or perhaps just go for the primary color?
              },
              true
            )}
            <Box mt="4rem" />
            {HomepageInformationBox(
              'ENKELT',
              'Det är inte bara resan som ska vara enkel, vägen dit också. Därför har vi försökt göra det så enkelt som möjligt för dig att köpa dina tågluffarkort, exempelvis genom att inte göra betalningen besvärligare än att swisha. ',
              pic1
            )}
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Homepage
