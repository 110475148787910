import item_class from "./item_class";
//const item_class = require("./item_class");

class addition_class extends item_class {
  constructor(id, name, price) {
    super(id, name, price);
    this.type = this.types.ADDITION;
  }
  fromJSON(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.price = obj.price;
  }
}

export default addition_class;

//module.exports = addition_class;
