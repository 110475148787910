import React from 'react'
import { Link } from 'react-router-dom'
import mainLogo from '../tagluffaieuropa_logo.svg'
import interrailLogo from '../assets/SVG/interrail_logo.svg'
import { primary, secondary } from 'styles'
//import { useMediaQuery } from 'react-responsive'
import { Grid } from '@material-ui/core'

function Footer() {
  // Use 'react-responsive' to check screen size
  //const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'whitesmoke',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'grey',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          margin: '1em',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '1200px',
            width: '100%',
            padding: '1em',
            flexWrap: 'wrap',
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            style={{
              //width: isMobile ? '100%' : '20%',
              paddingLeft: '1em',
              paddingRight: '1em',
            }}
          >
            <h3>Om oss</h3>
            <Link
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              to="/support"
            >
              Support
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            style={{
              //width: isMobile ? '100%' : '20%',
              paddingLeft: '1em',
              paddingRight: '1em',
            }}
          >
            <h3>Kom igång</h3>
            <div
              style={{
                marginBlockEnd: '0.5em',
              }}
            >
              <Link
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  marginBlockEnd: '1em',
                }}
                to="/guider/tagluffning-for-nyborjare"
              >
                Nybörjarguide
              </Link>
            </div>
            <div
              style={
                {
                  //marginBlockEnd: '0.5em',
                }
              }
            >
              <Link
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                to="/artiklar"
              >
                Artiklar
              </Link>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            style={{
              // width: isMobile ? '100%' : '20%',
              paddingLeft: '1em',
              paddingRight: '1em',
            }}
          >
            <h3>Villkor</h3>
            <Link
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              to="/villkor"
            >
              Allmänna villkor
            </Link>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          maxWidth: '1200px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            maxWidth: '1200px',
            backgroundColor: 'black',
            height: '1px',
            marginRight: '1em',
            marginLeft: '1em',
          }}
        />
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            margin: '1em',
            columnGap: '2rem',
            flexWrap: 'wrap',
            rowGap: '1rem',
          }}
        >
          <img src={mainLogo} height="64px" alt="LOGO" />
          <img src={interrailLogo} height="64px" alt="LOGO" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ textAlign: 'center', lineHeight: '1.5' }}>
            © Tågluffa i Europa <br />
            Mölndalsvägen 39 <br />
            412 63 Göteborg <br />
            Org.nr. 559276-6595
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
