import React from 'react'
const FAQ_återköp = [
  {
    question: 'Hur gör jag för att returnera en eller flera av mina biljetter?',
    answer: (
      <span>
        Du kan returnera hur många biljetter du vill förutsatt att du inte har
        aktiverat biljetterna eller dom av någon annan anledning inte är
        retunerbara. För att återköpa en eller flera biljetter kan du använda
        vårat återköps-flöde, som går att finna <a href="/återköp">här</a>.
      </span>
    ),
  },
  {
    question: 'Min biljett inehåller felaktig information, vad ska jag göra?',
    answer: (
      <span>
        Du kan tyvärr inte modifiera informationen på din biljett själv.
        Kontakta vår support så ska vi göra vårat bästa för att hjälpa dig.
      </span>
    ),
  },
  {
    question:
      'Jag har betalat för mina biljetter, men har inte fått något boknings-bekräftelse. Vad gör jag?',
    answer: (
      <span>
        Det kan finnas en viss fördröjning från det att bokningen genomförts
        tills dess mailet skickas ut. Om du inom 12 timmar inte fått något
        bekräftelsemail, kontakta då vår kundsupport så hjälper vi dig.
      </span>
    ),
  },
]

export default FAQ_återköp
