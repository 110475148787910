import React, { useEffect } from 'react'
import FieldWrapper from './Components/fieldWrapper'
import TravellerInfo from './Components/traveller'
import Fader from './Components/effects/Fader'

function PrintTravellers(props) {
  useEffect(() => {
    const interval = setInterval(() => {
      if (true) {
        props.setError(props.are_tickets_valid())
        console.log('Check!')
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  function printTraveller(ticket, index) {
    if (ticket.get_type() === 'ticket') {
      var travellerNumber = index + 1
      return (
        <Fader in={true}>
          <FieldWrapper
            key={ticket.get_id()}
            content_function={TravellerInfo}
            price={ticket.get_price()}
            bigPrice={false}
            title={ticket.name} // Could be title={"Resenär " + ticket.get_id()} but will result in strange numbers if id logic is not modified.
            bin={true}
            ticket={ticket}
            update_ticket={props.update_ticket}
            remove_from_shopping_cart={props.remove_from_shopping_cart}
            are_tickets_valid={props.are_tickets_valid}
            set_tickets_valid_to_false={props.set_tickets_valid_to_false}
            change_stepper={props.change_stepper}
            order={props.order}
          />
        </Fader>
      )
    } else {
      return null
    }
  }

  return (
    <Fader in={true}>
      <div>
        {props.shopping_cart.map((ticket, index) =>
          printTraveller(ticket, index)
        )}
      </div>
    </Fader>
  )
}

export default PrintTravellers
