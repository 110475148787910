import item_class from './item_class'
//const item_class = require("./item_class");

class ticket_class extends item_class {
  constructor(
    id,
    name,
    age = null,
    comfort = null,
    price = null,
    EURprice = null,
    VAT = null,
    duration = null,
    pass_model = null,
    sku = null,
    first_name = null,
    surname = null,
    passport_number = null,
    date_of_birth = null,
    first_travel_date = null,
    nationality = null,
    mobile_activation_code = null,
    eurail_ticket_id = null,
    discount_price = null
  ) {
    super(id, name, price, VAT, discount_price)
    this.age = age
    this.comfort = comfort
    this.duration = duration
    this.pass_model = pass_model
    this.first_name = first_name
    this.surname = surname
    this.passport_number = passport_number
    this.date_of_birth = date_of_birth
    this.first_travel_date = first_travel_date
    this.nationality = nationality
    this.EURprice = EURprice
    this.sku = sku
    this.type = this.types.TICKET
    this.mobile_activation_code = mobile_activation_code
    this.eurail_ticket_id = eurail_ticket_id
    this.refundable = false
    this.status = null
  }

  get_name() {
    return this.name
  }

  set_age(x) {
    this.age = x
  }

  get_age() {
    return this.age
  }

  get_EURprice() {
    return this.EURprice
  }

  set_comfort(x) {
    this.comfort = x
  }

  get_comfort() {
    return this.comfort
  }

  set_duration(x) {
    this.duration = x
  }

  get_duration() {
    return this.duration
  }

  set_pass_model(x) {
    this.pass_model = x
  }

  get_pass_model() {
    return this.pass_model
  }

  set_first_name(x) {
    this.first_name = x
  }

  get_first_name() {
    return this.first_name
  }

  set_surname(x) {
    this.surname = x
  }

  get_surname() {
    return this.surname
  }

  set_passport_number(x) {
    this.passport_number = x
  }

  get_passport_number() {
    return this.passport_number
  }

  set_date_of_birth(x) {
    this.date_of_birth = x
  }

  get_date_of_birth() {
    return this.date_of_birth
  }

  set_first_travel_date(x) {
    this.first_travel_date = x
  }

  get_first_travel_date() {
    return this.first_travel_date
  }

  set_nationality(x) {
    this.nationality = x
  }

  get_nationality() {
    return this.nationality
  }

  set_sku(x) {
    this.sku = x
  }

  get_sku() {
    return this.sku
  }

  set_mobile_activation_code(x) {
    this.mobile_activation_code = x
  }

  get_mobile_activation_code() {
    return this.mobile_activation_code
  }

  set_eurail_ticket_id(x) {
    this.eurail_ticket_id = x
  }

  get_eurail_ticket_id() {
    return this.eurail_ticket_id
  }

  set_refundable(x) {
    this.refundable = x
  }

  get_refundable() {
    return this.refundable
  }

  set_status(x) {
    this.status = x // Could be set to purchased, returned and reimbursed.
  }

  get_status() {
    return this.status
  }

  what_is_missing() {
    return (
      'Id: ' +
      this.id +
      '\nType: ' +
      this.type +
      '\nAge: ' +
      this.age +
      '\nComfort: ' +
      this.comfort +
      '\nprice: ' +
      this.price +
      '\nFirst name: ' +
      this.first_name +
      '\nSurname: ' +
      this.surname +
      '\nPassport Number: ' +
      this.passport_number +
      '\nDate of Birth: ' +
      this.date_of_birth +
      '\nFirst travel date: ' +
      this.first_travel_date +
      '\nSKU number: ' +
      this.sku
    )
  }

  is_ticket_valid() {
    if (!this.age) {
      return false
    }
    if (!this.comfort) {
      return false
    }
    if (this.price == null) {
      return false
    }
    if (!this.first_name) {
      return false
    }
    if (!this.surname) {
      return false
    }
    if (!this.nationality) {
      return false
    }
    /*if (!this.passport_number) {
      return false;
    }*/
    if (!this.sku) {
      return false
    }
    if (
      !this.isDateWithinAgeSpan(this.date_of_birth) ||
      this.date_of_birth == null
    ) {
      return false
    }
    /*if (
      !this.isValidDate(this.first_travel_date) ||
      this.first_travel_date == null
    ) {
      return false;
    }*/
    return true
  }

  isValidDate(d) {
    return d instanceof Date && !isNaN(d)
  }

  isDateWithinAgeSpan(d) {
    if (this.isValidDate(d)) {
      if (d < this.maxDateAllowed() && d > this.minDateAllowed()) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  fromJSON(obj) {
    this.id = obj.id
    this.name = obj.name
    this.price = obj.price
    this.EURprice = obj.EURprice
    this.VAT = obj.VAT
    this.age = obj.age
    this.comfort = obj.comfort
    this.duration = obj.duration
    this.pass_model = obj.pass_model
    this.first_name = obj.first_name
    this.surname = obj.surname
    this.passport_number = obj.passport_number
    this.date_of_birth = new Date(obj.date_of_birth)
    this.first_travel_date = new Date(obj.first_travel_date)
    this.nationality = obj.nationality
    this.sku = obj.sku
    this.mobile_activation_code = obj.mobile_activation_code
    this.eurail_ticket_id = obj.eurail_ticket_id
    this.status = obj.status
  }

  minDateAllowed() {
    let age = this.age
    let minDate = new Date()
    let today = new Date()
    if (age === 'CHD') {
      minDate.setFullYear(today.getFullYear() - 12)
      minDate.setDate(minDate.getDate() + 1)
    } else if (age === 'YTH') {
      minDate.setFullYear(today.getFullYear() - 28)
      minDate.setDate(minDate.getDate() + 1)
    } else if (age === 'ADT') {
      //minDate.setFullYear(today.getFullYear() - 60)
      //minDate.setDate(minDate.getDate() + 1)
      minDate = new Date(1900, 0, 1)
    } else if (age === 'SEN') {
      minDate = new Date(1900, 0, 1)
    } else {
      minDate = new Date(1900, 0, 1)
    }
    return minDate
  }

  maxDateAllowed() {
    let age = this.age
    var maxDate = new Date()
    if (age === 'CHD') {
    } else if (age === 'YTH') {
      //maxDate.setFullYear(maxDate.getFullYear() - 12)
    } else if (age === 'ADT') {
      //maxDate.setFullYear(maxDate.getFullYear() - 28)
    } else if (age === 'SEN') {
      maxDate.setFullYear(maxDate.getFullYear() - 60)
    }
    return maxDate
  }
}

export default ticket_class

//module.exports = ticket_class;
