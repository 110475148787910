import React from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Grid,
  Typography,
  Box,
  Hidden,
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
//import Typography from "material-ui/styles/typography";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#ff8a65',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#ff8a65',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderWidthTop: 3,
    borderRadius: 1,
  },
})(StepConnector)

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#ff8a65',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#ff8a65',
    zIndex: 1,
    fontSize: 18,
  },
})

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

function getSteps() {
  return ['Produkt', 'Resenärer', 'Betalning']
}

/*function getStepContent(step) {
  switch (step) {
    case 0:
      return "Välj interrailkort!";
    case 1:
      return "Vilka ska åka?";
    case 2:
      return "Får det lov att vara något mer?";
    case 3:
      return "Detta har ni valt!";
    case 4:
      return "Dags att betala!";
    default:
      return "Unknown step";
  }
}*/

function getActiveStep(step) {
  const steps = getSteps()
  return steps.findIndex(step)
}

function StepperBar(props) {
  const classes = useStyles()
  //const [activeStep, setActiveStep] = React.useState(1);
  const steps = getSteps()

  const theme = useTheme()
  const screenIsSmall = useMediaQuery(theme.breakpoints.up('sm'))

  /*const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };*/

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="center">
        <Hidden smUp>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <Typography variant="h6">
                <Box fontWeight="fontWeightLight">{props.activeStep}</Box>
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <Stepper
            alternativeLabel
            activeStep={getActiveStep(function foo(str2comp) {
              return str2comp === props.activeStep
            })}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {screenIsSmall ? label : null}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </div>
  )
}

export default StepperBar

/* Next and back buttons removed from above
<div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
*/
