import generateinterrailCart from './generateinterrailCart'
import backendAdress from '../../backendAdress'
import postEurailAPI from './postEurailAPI'
import sendConfirmationMail from './sendConfirmationMail'
import { useHistory } from 'react-router-dom'
import React from 'react'
import order_class from 'order_class'
import priceCheckerEurail from './priceCheckerEurail'

const nodeFetch = require('node-fetch')

const backend_adress = backendAdress
var originalPaymentReference = ''

/* const [CurrentSwishIdentifier, setCurrentSwishIdentifier] = React.useState("");
const [bookStatus, setBookStatus] = React.useState(null); */

var CurrentSwishIdentifier = ''
var bookStatus = null

var myHeaders = new nodeFetch.Headers()
myHeaders.append(
  'Authorization',
  'Bearer pHlCLuAeF4dlH9RpfpPdgSA5bhOI0vgCIgtShpRLXNU0JmIvfYb7PqWbpwkOjJiecZW7yPhrvphIrOP5'
)
myHeaders.append('Content-Type', 'application/json')

export async function CreatePayment(shopping_cart, order, swishDialogUpdate) {
  var interrailCart

  /*
  Booking response status messages:
  200 - OK
  400 - Unspecified error
  401 - 
  */

  var message = {
    status: 400,
    statusMessage: 'Unspecified error',
    body: '',
    concatenatedError: [],
    error: '',
  }

  try {
    interrailCart = generateinterrailCart(shopping_cart, order)
  } catch (err) {
    message.error = err
    message.concatenatedError.push(err)
    message.concatenatedError.push(
      'Error in interrail cart creation (generateinterrailCart). '
    )
    return message
  }

  var price = 0

  var result = await createCartWithContent(interrailCart, message)
    .then((result) => {
      if (result.error) {
        result.concatenatedError.push('Error in cart creation at Eurail')
        throw result
      }
      order.set_shopping_cart_id(result.body.id)
      console.log('price:', price)
      shopping_cart.map((ticket) => (price += ticket.get_price()))
      return priceCheckerEurail(result, shopping_cart, price)
    })
    .then(function (result) {
      if (result.error) {
        result.concatenatedError.push('Verifying price failed.')
        throw result
      }
      if (result.body == false) {
        result.error = 'Eurail prices detected to be higher then ours.'
        result.concatenatedError.push(
          'Eurail prices detected to be higher then ours.'
        )
        throw result
      }
      console.log('id to validate:', order.get_shopping_cart_id())
      return validateCart(order.get_shopping_cart_id(), result)
    })
    .then(function (result) {
      if (result.error) {
        result.concatenatedError.push('Validating cart did not succeed.')
        throw result
      }
      console.log('Tickets valid? ', result.body)

      // Send order to incomplete orders table to logg
      return send_process_to_db(order, shopping_cart, result)
    })
    .then(function (result) {
      if (result.error) {
        result.concatenatedError.push(
          'Sending draft order to incomplete_orders did not succeed.'
        )
        throw result
      }
      // Send order information per mail to us

      swishDialogUpdate('swish_pending')

      // shopping_cart.map((ticket) => (price += ticket.get_price()))

      return prepareSwishPayment(price, order.get_swish_phone_number(), result)
    })
    .then(function (result) {
      console.log('Prepare swish:')
      console.log(result)
      if (result.error) {
        // Överflödig
        result.concatenatedError.push('Preparing swish payment did not succeed')
        throw result
      }

      updateIncOrder(order.shopping_cart_id, result.body)

      return result
    })

  return result
}

export async function createBooking(bookingId, order, swishDialogUpdate) {
  var interrailCart

  /*
  Booking response status messages:
  200 - OK
  400 - Unspecified error
  401 - 
  */

  //var order = null
  var shopping_cart = null

  var message = {
    status: 400,
    statusMessage: 'Unspecified error',
    body: '',
    concatenatedError: [],
    error: '',
  }

  /*   try {
    interrailCart = generateinterrailCart(shopping_cart, order)
  } catch (err) {
    message.error = err
    message.concatenatedError.push(err)
    message.concatenatedError.push(
      'Error in interrail cart creation (generateinterrailCart). '
    )
    return message
  } */

  var price = 0

  var bookable = await is_booking_bookable(bookingId)

  var result = null

  if (bookable != false) {
    order.fromJSON(bookable)
    order.set_shopping_cart_id(bookingId)

    console.log(order.order)

/*     shopping_cart = bookable.order
    order.shopping_cart_id = bookingId */

    shopping_cart = order.order

    console.log('ORDER BEFORE CREATEBOOK', order)

    try {
      interrailCart = generateinterrailCart(shopping_cart, order)
    } catch (err) {
      message.error = err
      message.concatenatedError.push(err)
      message.concatenatedError.push(
        'Error in interrail cart creation (generateinterrailCart). '
      )
      return message
    }

    swishDialogUpdate('swish_paid')

    result = createCartWithContent(interrailCart, message)
      .then((result) => {
        if (result.error) {
          result.concatenatedError.push('Error in cart creation at Eurail')
          throw result
        }
        order.set_shopping_cart_id(result.body.id)
        console.log('price:', price)
        shopping_cart.map((ticket) => (price += ticket.get_price()))
        return priceCheckerEurail(result, shopping_cart, price)
      })
      .then(function (result) {
        if (result.error) {
          result.concatenatedError.push('Verifying price failed.')
          throw result
        }
        if (result.body == false) {
          result.error = 'Eurail prices detected to be higher then ours.'
          result.concatenatedError.push(
            'Eurail prices detected to be higher then ours.'
          )
          throw result
        }
        console.log('id to validate:', order.get_shopping_cart_id())
        return validateCart(order.get_shopping_cart_id(), result)
      })
      .then(function (result) {
        if (result.error) {
          result.concatenatedError.push('Validating cart did not succeed.')
          throw result
        }
        console.log('Tickets valid? ', result.body)

        // Send order to incomplete orders table to logg
        return message
      })
      .then(function (result) {
        swishDialogUpdate('swish_paid')
        console.log('creating order with id: ', order.get_shopping_cart_id())

        return createOrder(order.get_shopping_cart_id(), result)
      })
      .then(function (result) {
        if (!result) {
          // TODO: Send failed booking to failed booking database
          /*sendFailedBook(
          pnr_numbers,
          railBookRQ,
          railBookRS.ACP_RailBookRS.Errors[0].Error[0]
        );*/
          throw 'Create order did not execute properly'
        }
        if (result.error) {
          result.concatenatedError.push('Create order did not execute properly')
          throw result
        }

        add_activation_code_to_tickets(shopping_cart, result.body)
        order.set_eurail_order_number(result.body.orderNumber) // This is the one of importance
        order.set_eurail_order_id(result.body.id)
        order.set_eurail_booking(result.body)

        updateIncOrderToBooked(bookingId)

        // Send booking information to database & send confirmation mail
        return send_booking_to_db(order, shopping_cart, result)
      })
      .then(function (result) {
        // Make sure everything went fine and then return
        if (!result) {
          // TODO: Alert admin about error of dB storage and confirmation mail.
          throw 'Sending confirmation mail or storing in dB was faulty.'
        }
        return true
      })
      .catch((error) => {
        console.log('Error: ', error)
        return error
        // (Store error order)
        // Return error for customer to be faced with
      })
  } else {
  }
  return result
}

async function createCartWithContent(cart, message) {
  message.body = cart
  var result = await postEurailAPI(message, 'create_cart', backend_adress)

  //console.log("Cart was created with id:", result.id);
  console.log('createCartResult:')
  console.log(result)

  return result
}

async function validateCart(cartId, message) {
  console.log('validation received id: ', cartId)
  message.body = cartId

  var result = await postEurailAPI(message, 'validate_cart', backend_adress)

  console.log('asdasd', result)

  return result
}

async function createOrder(cartId, message) {
  console.log('validation received id: ', cartId)
  message.body = {
    id: cartId,
    version: 2,
    paymentInterface: 'nonepayment',
  }

  var result = await postEurailAPI(message, 'create_order', backend_adress)
  if (result.error) {
    result.concatenatedError.push('Create order did not execute properly')
    throw result
  }
  if (!result.body.hasOwnProperty('orderNumber')) {
    result.concatenatedError.push('Create order did not execute properly')
    throw result
  }
  return result
}

async function send_process_to_db(order, cart, message) {
  var result
  order.order = cart
  const url = backend_adress + '/booking2dB'
  result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status: false,
      eurail_order_number: '-',
      order: order,
      swish_status: 'NOT_PAID',
      error_msg: 'None',
      ACP_dump: message.body,
      shopping_cart: cart,
      id: order.shopping_cart_id,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        message.status = response.status
        throw response
      }
      return response.json()
    })
    .catch(function (error) {
      if (message.status < 205) {
        message.status = 400
      }
      message.error = error
      message.concatenatedError.push(error)
      message.concatenatedError.push(
        'An error has occured in frontend communication with backend when storing draft booking in incomplete orders database.'
      )
      return message
    })
  return message //Currently not happening nothing with this error
}

async function send_booking_to_db(order, cart, message) {
  var result
  order.order = cart
  order.set_ticket_status('purchased')
  const url = backend_adress + '/booking2dB'
  result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status: true,
      eurail_order_number: message.body.orderNumber,
      order: order,
      ACP_dump: message.body,
      shopping_cart: cart,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        message.status = response.status
        throw response
      }
      return response.json()
    })
    .then(function (response) {
      order.set_tagluffa_order_number(response.ans)
      /*
      var temp_order = props.order;
			temp_order.tagluffa_order_number = response.ans;
			temp_order.order = props.shopping_cart;
			props.update_order(temp_order);
      */
    })
    .then(() => {
      console.log('About to sent confirmation mail.')
      sendConfirmationMail(order, backend_adress).then((response) => {
        if (response == 'success') {
          console.log('Confirmation mail successfully sent.')
        } else {
          console.log('Failed to send confirmation mail.')
          throw 'Failed to send confirmation mail.'
        }
      })
      return true
    })
    .catch(function (error) {
      if (message.status < 205) {
        message.status = 400
      }
      message.error = error
      message.concatenatedError.push(error)
      message.concatenatedError.push(
        'An error has occured in frontend communication with backend when storing booking in database.'
      )
      return message
    })
  return result
}

function add_activation_code_to_tickets(shopping_cart, eurail_order) {
  // Index in booking will be same as index in shopping cart
  var index = 0
  shopping_cart.map((ticket) => {
    if (ticket.get_type() === 'ticket') {
      ticket.set_mobile_activation_code(
        eurail_order.lineItems[index].custom.fields.mobilePassNumber
      )
      ticket.set_eurail_ticket_id(eurail_order.lineItems[index].id)
      index += 1
    }
  })
}

async function prepareSwishPayment(amount, swish_number, result) {
  var swishIdentifier

  console.log('Talking to swish!')
  console.log('pris:', amount)
  console.log('swish_number:', swish_number)
  console.log('result:', result)
  var baseURL = backend_adress + '/'

  var url = baseURL + 'paymentrequests'
  var id = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      payerAlias: swish_number,
      amount: amount,
      message: 'Interrailkort',
      result: result,
      callbackUrl: 'https://api.tagluffaieuropa.se/backend' + '/swishCallback',
    }),
  })
    .then(function (response) {
      console.log('return from swish:', response)
      if (!response) {
        //if (!response.ok) {
        result.status = response.status
        throw response
      }
      return response.json()
    })
    .then(function (json) {
      console.log('Returning json:', json)
      if (json) {
        if (json.status != 201) {
          throw json
        }
        CurrentSwishIdentifier = json.body['id']
        swishIdentifier = json.body['id']
        console.log(
          'Payment request created with identifier ' +
            json.body['id'] +
            ', open app.'
        )

        //State change for visuals to check
        bookStatus = 'swish_pending'

        console.log('End of swish!')
        console.log('Leaving with:', result)
        result.body = swishIdentifier
        result.statusMessage = 'Successfully sent swish request'
        return result
      }
    })
    .catch(function (error) {
      console.log('Request failure: ', error)
      error.concatenatedError.push('Preparing swish payment did not succeed')
      console.log('Return from swish!')
      throw error
    })
  return id
}

export function checkSwishStatus(bookingId) {
  return new Promise((resolve, reject) => {
    var swishCheck = window.setInterval(async function () {
      var order = await get_incomplete_booking(bookingId)

      var swish_status = order.swish_status

      console.log(swish_status)

      // var swish_status = await checkActiveSwish(order.swish_id)
      if (swish_status == 'PAID') {
        clearInterval(swishCheck)
        resolve(swish_status == 'PAID')
      } else if (['ERROR', 'DECLINED', 'FAILED'].includes(swish_status)) {
        clearInterval(swishCheck)
        resolve(swish_status == 'PAID')
      }
    }, 1000)
    setTimeout(() => {
      clearInterval(swishCheck)
      resolve(false)
    }, 120000)
  })
}

async function checkActiveSwish(SwishIdentifier) {
  var status
  var baseURL = backend_adress + '/'
  var url = baseURL + 'paymentrequests/' + SwishIdentifier
  var error = ''

  await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(function (response) {
      return response.json()
    })
    .then(function (json) {
      if (json.status == 'PAID') {
        originalPaymentReference = json['paymentReference']
        console.log('Swish was paid correctly!')
        // Close dialog
        // Send user to confirmation page
        //          bookACPTicket().then((result) =>
        //            result
        //              ? history.push("/buy/sendof")
        //              : console.log("Tickets where not booked correctly!")
        //          );
        //history.push("/buy/sendof");
        status = true
      } else if (json.status == 'FAILED') {
        // TODO: Verify failure status
        // Alter error message for order to be stored in dB
        if (error) {
          error.concat(' | ')
        }
        error.concat('Swish payment failed.')
        // Show failure message
        status = false
      } else if (json.status == 'ERROR') {
        // TODO: Verify failure status
        // Alter error message for order to be stored in dB
        if (error) {
          error.concat(' | ')
        }
        error.concat('Swish payment encountered an error!')
        // Show failure message
        status = false
      } else if (json.status == 'DECLINED') {
        // TODO: Verify failure status
        // Alter error message for order to be stored in dB
        if (error) {
          error.concat(' | ')
        }
        error.concat('Swish payment was declined!')
        // Show failure message
        status = false
      }
      // Send order and swish_status to dB (COMMENTED THIS PART AWAY DUE TO MISMATCH IN DB STRUCTURE PLEASE RESOLVE //JENS)
      /* url = baseURL + "order2dB";
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swish_status: json.status,
          order: sanitizer(props.order),
          error: error,
        }),
      }); */
      console.log(
        'Payment(identifier: ' +
          SwishIdentifier +
          ', paymentReference: ' +
          originalPaymentReference +
          ') ' +
          json.status
      )
    })
    .catch(function (error) {
      console.log('Request failure: ', error)
    })
  return status
}

function updateIncOrder(orderid, swishid) {
  var status
  var baseURL = backend_adress + '/'
  var url = baseURL + 'update_inc_order'
  var error = ''

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: orderid,
      swish_id: swishid,
    }),
  })
}

function updateIncOrderToBooked(orderid) {
  var status
  var baseURL = backend_adress + '/'
  var url = baseURL + 'update_inc_order_is_booked'
  var error = ''

  console.log('asdasdasdasd', orderid)

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: orderid,
    }),
  })
}

function get_incomplete_booking(bookingid) {
  var baseURL = backend_adress + '/'
  var url = baseURL + 'getIncompleteOrderFromDB'
  var error = ''

  var res = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: bookingid,
    }),
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      return data.ans[0]
    })

  return res
}

function is_booking_bookable(bookingid) {
  var status
  var baseURL = backend_adress + '/'
  var url = baseURL + 'getIncompleteOrderFromDB'
  var error = ''

  console.log(bookingid)

  var res = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: bookingid,
    }),
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      console.log(data)
      if (data.ans[0].swish_status == 'PAID') {
        let order = JSON.parse(data.ans[0].order)
        //order.fromJSON(JSON.parse(data.ans[0].order))

        return order
      } else {
        return false
      }
    })

  return res
}
