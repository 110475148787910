import React from "react";
import {
	Grid,
	Box,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	Typography,
} from "@material-ui/core";
import { styles } from "styles";
import PackingObject from "./../assets/guider/PackingObject";
import TableComponent from "./tableComponent";
import { useEffect } from "react";

export default function BagSelector(props) {
	const classes = styles();

	const [environment, setEnvironment] = React.useState(null);
	const [duration, setDuration] = React.useState(null);
	const [stopDuration, setstopDuration] = React.useState(null);
	const [bagRecommendation, setBagRecommendation] = React.useState(null);

	// Struct containing all combinations in order: environment -> duration -> stopDuration
	const bagCombination = {
		landsbygd: {
			1: {
				short: "Liten ryggsäck",
				long: "Liten ryggsäck / resväska",
			},
			2: {
				short: "Medelstor ryggsäck",
				long: "Medelstor ryggsäck / resväska",
			},
			3: {
				short: "Större ryggsäck",
				long: "Större ryggsäck / resväska",
			},
		},
		mix: {
			1: {
				short: "Liten ryggsäck",
				long: "Liten ryggsäck / resväska",
			},
			2: {
				short: "Medelstor ryggsäck",
				long: "Medelstor ryggsäck / resväska",
			},
			3: {
				short: "Större ryggsäck",
				long: "Större ryggsäck / resväska",
			},
		},
		storstad: {
			1: {
				short: "Liten ryggsäck",
				long: "Liten resväska",
			},
			2: {
				short: "Medelstor ryggsäck",
				long: "Medelstor resväska",
			},
			3: {
				short: "Större ryggsäck / resväska",
				long: "Större resväska",
			},
		},
	};

	const handleEnvChange = (event) => {
		setEnvironment(event.target.value);
	};

	const handleDurationChange = (event) => {
		setDuration(event.target.value);
	};

	const handleStopChange = (event) => {
		setstopDuration(event.target.value);
	};

	function handleBagCalc() {
		try {
			setBagRecommendation(bagCombination[environment][duration][stopDuration]);
		} catch {
			setBagRecommendation(null);
		}
	}

	function allOptionsSet() {
		if (environment && duration && stopDuration) {
			console.log("All options are set");
			return true;
		} else {
			return false;
		}
	}

	function getHalfList(list, first) {
		const half = Math.ceil(list.length / 2);

		var cloneList = list.slice();

		const firstHalf = cloneList.splice(0, half);
		const secondHalf = cloneList.splice(-half);

		if (first) {
			return firstHalf;
		} else {
			return secondHalf;
		}
	}

	useEffect(() => {
		handleBagCalc();
	}, [environment, duration, stopDuration]);

	return (
		<div>
			<Grid container spacing={2} className={classes.contentborder}>
				<Typography className={classes.contentBoxTitle}>
					<b className={classes.backgroundColorWhite}> Bagageguiden </b>
				</Typography>
				<Box mt={2}></Box>
				<Grid item xs={12}>
					<FormControl component="fieldset" fullWidth={true}>
						<RadioGroup
							aria-label="destination environment"
							name="environment"
							value={environment}
							onChange={handleEnvChange}
						>
							<Grid container>
								<Grid item xs={12} sm={3} className={classes.verticalAlign}>
									<Typography>Terräng:</Typography>
								</Grid>
								<Grid item xs={12} sm={9}>
									<Grid container>
										<Grid item>
											<FormControlLabel
												value="storstad"
												control={<Radio />}
												label="Storstad"
											/>
										</Grid>
										<Grid item>
											<FormControlLabel
												value="mix"
												control={<Radio />}
												label="Mixat"
											/>
										</Grid>
										<Grid item>
											<FormControlLabel
												value="landsbygd"
												control={<Radio />}
												label="Landsbygd"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl component="fieldset" fullWidth={true}>
						<RadioGroup
							aria-label="duration of trip"
							name="duration"
							value={duration}
							onChange={handleDurationChange}
						>
							<Grid container>
								<Grid item xs={12} sm={3} className={classes.verticalAlign}>
									<Typography>Reselängd:</Typography>
								</Grid>
								<Grid item xs={12} sm={9}>
									<Grid container>
										<Grid item>
											<FormControlLabel
												value="1"
												control={<Radio />}
												label="<1 vecka"
											/>
										</Grid>
										<Grid item>
											<FormControlLabel
												value="2"
												control={<Radio />}
												label="1-3 veckor"
											/>
										</Grid>
										<Grid item>
											<FormControlLabel
												value="3"
												control={<Radio />}
												label=">3 veckor"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl component="fieldset" fullWidth={true}>
						<RadioGroup
							aria-label="Duration for each stop"
							name="stopDuration"
							value={stopDuration}
							onChange={handleStopChange}
						>
							<Grid container>
								<Grid item xs={12} sm={3} className={classes.verticalAlign}>
									<Typography>Resemängd:</Typography>
								</Grid>
								<Grid item xs={12} sm={9}>
									<Grid container>
										<Grid item>
											<FormControlLabel
												value="short"
												control={<Radio />}
												label="Mycket resande, korta stopp"
											/>
										</Grid>
										<Grid item>
											<FormControlLabel
												value="long"
												control={<Radio />}
												label="Lite resande, långa stopp"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12} className={classes.alignCenter}>
					{bagRecommendation ? (
						<Box mt={2}>
							<Typography>Vår rekommendation av bagage är en: </Typography>
							<Typography>
								<b>{bagRecommendation}</b>
							</Typography>
						</Box>
					) : (
						<div></div>
					)}
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Grid container spacing={2}>
								{getHalfList(PackingObject, true).map((list, index) => {
									var packingItems = [];
									list.items.forEach((element) => {
										if (
											environment == null &&
											duration == null &&
											stopDuration == null
										) {
											packingItems.push(element.item);
										} else if (
											element.tags.enviornment.includes(environment) &&
											element.tags.duration.includes(duration) &&
											element.tags.stopDuration.includes(stopDuration)
										) {
											packingItems.push(element.item);
										}
									});

									return (
										<Grid item xs={12}>
											<TableComponent
												title={list.title}
												listitems={packingItems}
											/>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Grid container spacing={2}>
								{getHalfList(PackingObject, false).map((list, index) => {
									var packingItems = [];
									list.items.forEach((element) => {
										if (
											environment == null &&
											duration == null &&
											stopDuration == null
										) {
											packingItems.push(element.item);
										} else if (
											element.tags.enviornment.includes(environment) &&
											element.tags.duration.includes(duration) &&
											element.tags.stopDuration.includes(stopDuration)
										) {
											packingItems.push(element.item);
										}
									});

									return (
										<Grid item xs={12}>
											<TableComponent
												title={list.title}
												listitems={packingItems}
											/>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
