import React from 'react'
import { Email, Item, A, Box, Image, Span } from 'react-html-email'
import EmailFooter from './../EmailFooter'

export default function ReturnEmailTemplate({
  name,
  ordernummer,
  biljetter,
  payback,
}) {
  const emailHeadCSS = `
	body {
	  background-color: #F5F8FA;
	}
  `.trim()

  const subject = 'Bokningsbekräftelse'

  const backgroundStyle = {
    WebkitBoxShadow: '6px 6px 40px 3px rgba(140, 152, 164, 0.2)',
    backgroundColor: '#FFF',
    borderRadius: 7,
    boxShadow: '6px 6px 40px 3px rgba(140, 152, 164, 0.2)',
    margin: '0 auto',
    width: '100%',
    padding: '0 32px',
  }

  const containerStyle = {
    backgroundColor: '#F5F8FA',
    width: '100%',
  }

  const linkStyle = {
    color: 'white',
    display: 'block',
    paddingBottom: '13px',
    paddingTop: '13px',
    textDecoration: 'none',
    width: '100%',
  }

  const fluidItemStyle = {
    backgroundColor: 'rgb(59, 139, 128)',
    borderRadius: 4,
    cursor: 'pointer',
    height: 48,
    textAlign: 'center',
    textDecoration: 'none',
  }

  return (
    <Box align="center" style={containerStyle}>
      <Email align="center" headCSS={emailHeadCSS} title={subject}>
        <Item style={{ height: 45 }} />
        <Item>
          <Image
            height="auto"
            src="https://tagluffaieuropa.se/pictures_for_external_use/tågluffaieuropa_logo.png"
            style={{ margin: '0 auto ' }}
            width={280}
          />
        </Item>
        <Item style={{ height: 30 }} />
        <Item align="center">
          <Box style={backgroundStyle}>
            <Item style={{ height: 40 }} />
            <Item>
              <Span fontSize={22} fontWeight="bold">
                Din retur är inlagd!&nbsp;
              </Span>
            </Item>
            <Item style={{ height: 25 }} />
            <Item>
              Hej {name}, vi kan meddela att din retur är oss tillhanda!
            </Item>
            <Item style={{ height: 25 }} />
            <Item>
              Din bokning med ordernummer {ordernummer} har uppdaterats.
              Följande biljetter har lagts in för returnering:
            </Item>
            <Item style={{ height: 25 }} />
            {biljetter.map((biljett) => (
              <Box width="100%">
                <Box align="left">
                  <Span fontSize={18} fontWeight="bold">
                    {biljett.get_first_name()} {biljett.get_surname()}&nbsp;
                  </Span>
                </Box>
                <Box align="right">
                  <Span fontSize={18} fontWeight="bold">
                    {biljett.get_name()}&nbsp;
                  </Span>
                </Box>
              </Box>
            ))}
            <Item style={{ height: 25 }} />
            <Item>
              Nu kommer vi att processera din retur och när den är behandlad
              kommer dina pengar att återbetalas. Återbetalningen kommer att
              omfatta {payback} kr. Det kan ta några arbetsdagar tills dess
              pengarna syns på ditt konto.
            </Item>
            <Item style={{ height: 35 }} />
            <Item>Trevlig resa från oss på,</Item>
            <Item style={{ height: 25 }} />
            <Item>Tågluffa i Europa</Item>
            <Item style={{ height: 35 }} />
          </Box>
          <EmailFooter />
        </Item>
      </Email>
    </Box>
  )
}
