import React from "react";
import { Email, Item, A, Box, Image, Span } from "react-html-email";

export default function InlineLink({
	name,
	ärendenummer,
	mail,
	telefon,
	errors,
}) {
	if (typeof errors === "object") {
		errors = errors.join(" > ");
	}
	return (
		<Email title="Felnotifikation">
			<Box
				align="center"
				style={{
					paddingTop: "20px",
					paddingBottom: "20px",
				}}
			>
				<Image
					alt="tågluffa-i-europa-logo"
					src="https://tagluffaieuropa.se/static/media/logo1_cropped.873b3af7.png"
					height={116}
					width={362}
				></Image>
			</Box>
			<Box style={{ backgroundColor: "#f9f9f9" }}>
				<Box height="20px"></Box>
				<Box
					align="center"
					cellSpacing={20}
					width="80%"
					style={{
						border: "1px solid black",
						backgroundColor: "#ffffff",
						paddingTop: "20px",
						paddingBottom: "20px",
					}}
				>
					<Item align="center">
						<h2>Ett fel har inträffat</h2>
						<p>Ärendenummer: {ärendenummer}</p>
						<p>Ett fel har precis inträffat för {name}.</p>
						<p>
							Kunden i fråga har betalat oss för sina biljetter, men bokningen
							har inte gått igenom hos Eurail.
						</p>
						<p>Följande felkoder har erhållits:</p>
						<p>Error: {errors}.</p>
						<p>
							Se till att felsöka ärendet snabbast möjligen och kontakta kunden
							på:
						</p>
						<p>Mail: {mail}.</p>
						<p>Telefon: {telefon}.</p>
						<Span fontWeight="bold">Vänliga hälsningar,</Span>
						<p>Tågluffa i Europa</p>
					</Item>
				</Box>
				<Box height="20px"></Box>
			</Box>
		</Email>
	);
}
