import React from 'react'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

function Summary(props) {
  const classes = useStyles()

  props.change_stepper('Summering')

  var tickets = []
  var additions = []
  var products = []

  var arraylength = null
  var name = null
  var price = null
  var age = null
  var comfort = null
  var first_name = null
  var surname = null
  //var passport_number = null;
  var date_of_birth = null
  //var first_travel_date = null;

  const ages = {
    CHD: 'Barn',
    YTH: 'Ungdom',
    ADT: 'Vuxen',
    SEN: 'Senior',
    99: 'Superold Jensboy',
  }

  const comforts = {
    FIRST: 'Första klass',
    STANDARD: 'Andra klass',
    third: 'Plankning',
    '1stclass': 'Första klass',
    '2ndclass': 'Andra klass',
  }

  function item_sorter(ticket) {
    if (ticket.get_type() === 'ticket') {
      arraylength = tickets.length + 1
      name = ticket.get_name()
      price = ticket.get_price()
      age = ages[ticket.get_age()]
      comfort = comforts[ticket.get_comfort()]
      first_name = ticket.get_first_name()
      surname = ticket.get_surname()
      //passport_number = ticket.get_passport_number();
      date_of_birth = ticket.get_date_of_birth()
      //first_travel_date = ticket.get_first_travel_date();
      tickets.push({
        arraylength,
        name,
        comfort,
        age,
        first_name,
        surname,
        date_of_birth,
        //passport_number,
        //first_travel_date,
        price,
      })
    } else if (ticket.get_type() === 'addition') {
      name = ticket.get_name()
      price = ticket.get_price()
      additions.push({ name, price })
    } else if (ticket.get_type() === 'product') {
      name = ticket.get_name()
      price = ticket.get_price()
      products.push({ name, price })
    }
  }
  props.ticket.map((actual_ticket) => item_sorter(actual_ticket))

  function addition_printer() {
    if (additions.length > 0) {
      return (
        <div>
          <Typography>Tillval</Typography>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="summary of additions in shopping cart"
            >
              <TableBody>
                {additions.map((addition) => (
                  <TableRow key={addition.name}>
                    <TableCell component="th" scope="row">
                      {addition.name}
                    </TableCell>
                    <TableCell align="right">{addition.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
    } else {
      return
    }
  }

  function product_printer() {
    if (products.length > 0) {
      return (
        <div>
          <Typography>Produkter</Typography>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="summary of products in shopping cart"
            >
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell component="th" scope="row">
                      {product.name}
                    </TableCell>
                    <TableCell align="right">{product.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
    } else {
      return
    }
  }

  function ticket_printer() {
    return (
      <div>
        <Typography align={'left'}>Interrailkort</Typography>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-label="summary of interrail passes in shopping cart"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Nr.</TableCell>
                <TableCell>Pass</TableCell>
                <TableCell>Komfort</TableCell>
                <TableCell>Resenär</TableCell>
                <TableCell>Förnamn</TableCell>
                <TableCell>Födelsedatum</TableCell>
                <TableCell align="right">Pris</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((ticket, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {ticket.arraylength}
                  </TableCell>
                  <TableCell>{ticket.name}</TableCell>
                  <TableCell>{ticket.comfort}</TableCell>
                  <TableCell>{ticket.age}</TableCell>
                  <TableCell>
                    {ticket.first_name + ' ' + ticket.surname}
                  </TableCell>
                  <TableCell>
                    {ticket.date_of_birth.getFullYear() +
                      '-' +
                      ('0' + (ticket.date_of_birth.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + ticket.date_of_birth.getDate()).slice(-2)}
                  </TableCell>
                  <TableCell align="right">{ticket.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  function condensed_ticket_printer() {
    return (
      <div>
        <Typography>Interrailkort</Typography>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-label="summary of interrail passes in shopping cart"
            style={{ minWidth: '0px' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Pass</TableCell>
                <TableCell align="right">Pris</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((ticket, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {ticket.name} <br />{' '}
                    {'(' + ticket.comfort + ', ' + ticket.age + ') '} <br />{' '}
                    {ticket.first_name +
                      ' ' +
                      ticket.surname +
                      ', ' +
                      ticket.date_of_birth.getFullYear() +
                      '-' +
                      ('0' + (ticket.date_of_birth.getMonth() + 1)).slice(-2) +
                      '-' +
                      ('0' + ticket.date_of_birth.getDate()).slice(-2)}{' '}
                  </TableCell>
                  <TableCell align="right">{ticket.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  const theme = useTheme()
  const screenIsSmall = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid container>
      <Grid item xs={12}>
        {screenIsSmall ? ticket_printer() : condensed_ticket_printer()}
        {addition_printer()}
        {product_printer()}
      </Grid>
    </Grid>
  )
}

export default Summary
