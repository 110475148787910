import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

var notFoundPage = [
	{
		title: "Denna sida verkar inte finnas",
		picPath: "",
		body: (
			<Box textAlign="center">
				<ErrorIcon style={{ fontSize: 80 }} />
			</Box>
		),
	},
	{
		title: "",
		picPath: "",
		body: "Tyvärr kunde vi inte hitta sidan du sökte. Det kan bero på att sidan på tagluffaieuropa.se har fått ny adress eller upphört att existera. Testa att gå till vår startsida för att komma vidare..",
	},
	{
		title: "",
		picPath: "",
		body: (
			<Box textAlign="center">
				<Button variant="contained" color="primary" component={Link} to="./">
					Till startsidan
				</Button>
			</Box>
		),
	},
];

export default notFoundPage;
