import React from "react";
import calculateAge from "./calculateAge";

const xml2js = require("xml2js");

//////////////////////////////////////////////////////////////////////////
//
// Function loop through shopping cart in search of tickets, categorizing
// passengers and creating Request message based on it.
//
//////////////////////////////////////////////////////////////////////////

export default function generateinterrailAvailReq(shopping_cart) {
	var result = []; //What to return

	var interrailAvailReqTemplate = {
		message: {
			ACP_RailPassAvailRQ: {
				$: {
					xmlns: "http://www.acprailinternational.com/API/R2",
					SequenceNmbr: "1",
					Version: "1.002",
					EchoToken: "7656",
				},
				POS: {
					RequestorID: "", //RequestorID is added in back-end
				},
				RailPassAvailInfo: {
					$: {
						TravelDate: "",
						Node: "Interrail Global Passes",
						WithProtectionPlan: "false",
					},
					PassengerSpecifications: {
						PassengerType: [],
					},
				},
			},
		},
		IDs: [],
		passModel: "",
		duration: "",
		comfort: "",
	};

	//Find number of passengers in the different age categories and create Req message based on it.
	var dates = {};
	var templateStruct = {
		UniquePassangers: {},
		UniqueIDs: [],
	};
	var age_category,
		protectionplan = false,
		date,
		age,
		passModel,
		duration,
		comfort;
	// Loop through shopping cart to search for tickets
	shopping_cart.map((ticket) => {
		// Check if avbeställningsskydd
		if (ticket.get_type() == "addition") {
			if (ticket.get_name() == "Avbeställningskydd") {
				protectionplan = true;
			}
		} else {
			// Otherwise ticket and then check age and date for current ticket
			age_category = ticket.get_age();
			age = calculateAge(ticket);
			date = ticket
				.get_first_travel_date()
				.toISOString()
				.split("T")[0]
				.concat("T00:00:00");
			passModel = ticket.get_pass_model();
			duration = ticket.get_duration();
			comfort = ticket.get_comfort();
			// Add info to request struct
			if (!dates.hasOwnProperty(date)) {
				dates[date] = {};
			}
			if (!dates[date].hasOwnProperty(passModel)) {
				dates[date][passModel] = {};
			}
			if (!dates[date][passModel].hasOwnProperty(duration)) {
				dates[date][passModel][duration] = {};
			}
			if (!dates[date][passModel][duration].hasOwnProperty(comfort)) {
				dates[date][passModel][duration][comfort] = {
					UniquePassangers: {},
					UniqueIDs: [],
				};
			}
			if (
				dates[date][passModel][duration][
					comfort
				].UniquePassangers.hasOwnProperty(age)
			) {
				dates[date][passModel][duration][comfort].UniquePassangers[age] =
					dates[date][passModel][duration][comfort].UniquePassangers[age] + 1;
			} else {
				dates[date][passModel][duration][comfort].UniquePassangers[age] = 1;
			}
			dates[date][passModel][duration][comfort].UniqueIDs.push(ticket.get_id());
		}
	});

	// Create one unique railPassAvail message for every unique combination of date/comfort/duration/passModel
	// TODO: Currently several xmlMessages are never created and results in malfunctional function...
	var datelist = Object.keys(dates);
	var index = 0;
	for (var i = 0; i < datelist.length; i++) {
		var passmodellist = Object.keys(dates[datelist[i]]);
		for (var j = 0; j < passmodellist.length; j++) {
			var durationlist = Object.keys(dates[datelist[i]][passmodellist[j]]);
			for (var k = 0; k < durationlist.length; k++) {
				var comfortlist = Object.keys(
					dates[datelist[i]][passmodellist[j]][durationlist[k]]
				);
				for (var l = 0; l < comfortlist.length; l++) {
					result[index] = JSON.parse(JSON.stringify(interrailAvailReqTemplate)); // Create a deep copy of the template
					result[
						index
					].message.ACP_RailPassAvailRQ.RailPassAvailInfo.$.TravelDate =
						datelist[i];
					result[index].passModel = passmodellist[j];
					result[index].duration = durationlist[k];
					result[index].comfort = comfortlist[l];
					result[index].IDs =
						dates[datelist[i]][passmodellist[j]][durationlist[k]][
							comfortlist[l]
						].UniqueIDs;

					// Add all PassangerTypes of interest.
					var passanger_types = Object.keys(
						dates[datelist[i]][passmodellist[j]][durationlist[k]][
							comfortlist[l]
						].UniquePassangers
					);
					for (var m = 0; m < passanger_types.length; m++) {
						// Add new struct to PassengerType array for every age.
						result[
							index
						].message.ACP_RailPassAvailRQ.RailPassAvailInfo.PassengerSpecifications.PassengerType.push(
							{
								$: {
									Quantity:
										dates[datelist[i]][passmodellist[j]][durationlist[k]][
											comfortlist[l]
										].UniquePassangers[passanger_types[m]],
									Age: passanger_types[m],
								},
							}
						);
					}
					index = index + 1;
				}
			}
		}
	}
	return result;
}
