import { styles } from "styles";
import React from "react";
import {
  Typography,
  Button,
  List,
  ListItem,
  Divider,
  IconButton,
  ListItemText,
  Grid,
} from "@material-ui/core";
import { Edit, Close } from "@material-ui/icons";
import ShoppingCartTicket from "./shopping_cart_ticket";
import Drawer from "material-ui/Drawer";
import { Link, useHistory } from "react-router-dom";

function ShoppingCartDrawer(props) {
  const classes = styles();
  const history = useHistory();

  const total_price = props.summarize_price();
  let counted_tickets = props.count_types();

  function ticketFilter(ticket, index, filter) {
    if (ticket.ticket.get_type() === filter) {
      return (
        <div key={index}>
          <ShoppingCartTicket
            ticket={ticket.ticket}
            ticket_count={ticket.count}
            ids={ticket.id}
            remove_from_shopping_cart={props.remove_from_shopping_cart}
            add_to_shopping_cart={props.add_to_shopping_cart}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  function handle_continue_shopping() {
    props.change_tab(false);
    console.log(props.are_tickets_valid());
    if (props.are_tickets_valid()) {
      history.push("/buy/additions");
      props.toggle_drawer();
    } else {
      history.push("/buy/travelers");
      props.toggle_drawer();
    }
  }

  return (
    <>
      <Drawer
        onEscapeKeyDown={props.toggle_drawer}
        onBackdropClick={props.toggle_drawer}
        containerstyle={{ transform: "none" }}
        anchor="right"
        open={props.open}
        className={classes.shopping_cart_drawer}
        style={{
          width: "50%",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            overflow: "auto",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
            }}
            className={classes.shopping_cart_sticky_bottom}
          >
            <ListItem divider={true}>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography> Kundvagn </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => props.toggle_drawer()}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          </div>

          <List className={classes.shopping_cart_list}>
            <ListItem className={classes.first_shopping_cart} />
            <ListItem>
              <ListItemText>
                <Typography variant="overline">Biljetter</Typography>
              </ListItemText>
              <IconButton
                edge="end"
                aria-label="comments"
                component={Link}
                to="/buy/travelers"
                onClick={() => props.toggle_drawer()}
              >
                <Edit />
              </IconButton>
            </ListItem>
            <Divider component="li" />
            {counted_tickets.map((ticket, index) =>
              ticketFilter(ticket, index, "ticket")
            )}
            <ListItem />
            <ListItem>
              <ListItemText>
                <Typography variant="overline">Tillval</Typography>
              </ListItemText>
            </ListItem>
            <Divider component="li" />
            {counted_tickets.map((ticket, index) =>
              ticketFilter(ticket, index, "addition")
            )}
            <ListItem className={classes.last_shopping_cart} />
          </List>
          <div
            className={classes.shopping_cart_sticky_bottom}
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <ListItem>
              <Grid container direction="row" justify="space-between">
                <Grid item></Grid>
                <Grid item>
                  <ListItemText>
                    <Typography variant="h6">{total_price + " SEK"}</Typography>
                  </ListItemText>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container direction="row" justify="space-between">
                <Grid item></Grid>
                <Grid item>
                  <Button onClick={() => handle_continue_shopping()}>
                    Gå till kassa
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ShoppingCartDrawer;
