import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'

function Login(props) {
  const [token, setToken] = useState()
  const [name, setName] = useState()
  const [pasword, setPasword] = useState()
  const [errorMsg, setErrorMsg] = useState('')

  function handleNameChange(input) {
    if (errorMsg) {
      setErrorMsg('')
    }
    setName(input.target.value)
  }

  function handlePaswordChange(input) {
    if (errorMsg) {
      setErrorMsg('')
    }
    setPasword(input.target.value)
  }

  function handleLogin(input) {
    var baseURL = props.backend_adress + '/'
    var url = baseURL + 'login'
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        password: pasword,
      }),
    })
      .then((resp) => resp.json())
      .then(function (response) {
        if (response.ans === 'No data') {
          setErrorMsg('Invalid username or password')
        } else {
          props.setToken(response.ans)
        }
      })
    input.preventDefault()
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: '100vh', width: '100vw' }}
    >
      <form
        style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}
      >
        <label>
          <p>Username</p>
          <input type="text" onChange={handleNameChange} />
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={handlePaswordChange} />
        </label>
        <div>
          <button type="submit" onClick={(e) => handleLogin(e)}>
            Submit
          </button>
        </div>
        <Typography color="error">{errorMsg}</Typography>
      </form>
    </Grid>
  )
}

export default Login
