import React from 'react'
import { styles } from 'styles'
import {
  Grid,
  Typography,
  Box,
  Button,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  Card,
  Fade,
  duration,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Fader from './effects/Fader'

function GuideCard(props) {
  const classes = styles()
  const history = useHistory()
  const content = props.content
  const path = props.path

  const [picLoaded, setPicLoaded] = React.useState(false)
  return (
    <Fader in={picLoaded}>
      <Card
        className={classes.guides}
        onClick={() => {
          history.push(path + content.sitePath)
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            alt={content.img_title}
            height="240"
            image={require('assets/' + content.img_path + '')} //"/static/images/cards/contemplative-reptile.jpg"
            title={content.img_title}
            onLoad={() => setPicLoaded(true)}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h1" align={'left'}>
              {content.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align={'left'}
            >
              {content.header}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Fader>
  )
}

export default GuideCard
