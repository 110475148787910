import React from 'react'
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  IconButton,
  Badge,
  Tabs,
  Tab,
  Button,
  Hidden,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MenuIcon from '@material-ui/icons/Menu'
import { styles } from 'styles'
import mainLogo from '../tagluffaieuropa_logo.svg'

function Header(props) {
  const classes = styles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (item) => {
    setAnchorEl(null)
    if (item === '/buy/ticketView') {
      props.gtag('event', 'view_item_list')
    }
    history.push(item)
  }

  let shopping_cart_button
  if (props.shopping_cart.length > 0) {
    shopping_cart_button = (
      <IconButton
        className={`${classes.shoppingCartButton} ${
          props.just_added_to_shopping_cart ? classes.animatedItem : ''
        }`}
        size="small"
        aria-label="cart"
        onClick={() => props.toggle_drawer()}
      >
        <Badge badgeContent={props.shopping_cart.length} color="secondary">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    )
  } else {
    shopping_cart_button = <div />
  }

  return (
    <div>
      <AppBar className={classes.header} position="fixed">
        <Toolbar variant="dense" disableGutters={false}>
          <Grid container alignItems="center" style={{ width: '100%' }}>
            <Hidden smUp>
              <Grid item xs={2}>
                <Box display={{ xs: 'block', sm: 'none' }}>
                  <IconButton edge="start" color="inherit" aria-label="menu">
                    <MenuIcon onClick={(e) => handleClick(e)} />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleClose('/')}>Hem</MenuItem>
                    <MenuItem onClick={() => handleClose('/buy/ticketView')}>
                      Boka
                    </MenuItem>
                    <MenuItem onClick={() => handleClose('/interrailkort')}>
                      Interrailkort
                    </MenuItem>
                    <MenuItem onClick={() => handleClose('/guider')}>
                      Guider
                    </MenuItem>
                    <MenuItem onClick={() => handleClose('/support')}>
                      Support
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            </Hidden>
            <Grid item xs={8} sm={2} style={{ padding: '6px' }}>
              <Box
                fontWeight="fontWeightLight"
                component={Link}
                to="/"
                onClick={() => props.change_tab(false)}
                justifyContent="center"
              >
                <img src={mainLogo} height="64px" alt="LOGO" />
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Box display={{ xs: 'none', sm: 'block' }}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  value={props.current_tab}
                  onChange={(event, newTab) => props.change_tab(newTab)}
                >
                  <Tab
                    wrapped
                    className={classes.pagetabs}
                    label="Interrailkort"
                    component={Link}
                    to="/interrailkort"
                  />
                  <Tab
                    wrapped
                    className={classes.pagetabs}
                    label="Guider"
                    component={Link}
                    to="/guider"
                  />
                  <Tab
                    wrapped
                    className={classes.pagetabs}
                    label="Support"
                    component={Link}
                    to="/support"
                  />

                  {/* <Tab label="KORTVY" component={Link} to="/ticketView" /> */}
                  {/*<Tab label="STEPPER" component={Link} to="/stepper" /> */}
                  {/*<Tab label="TRAVELLERS" component={Link} to="/travellers" /> */}
                  {/*<Tab label="ADDITIONS" component={Link} to="/additions" /> */}
                  {/*<Tab label="CHECKOUT" component={Link} to="/checkout" /> */}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Hidden smDown>
                  <Grid item>
                    <Button
                      className={classes.bookingButton}
                      size="small"
                      component={Link}
                      to="/buy/ticketView"
                      onClick={() => {
                        props.change_tab(false)
                        props.gtag('event', 'view_item_list')
                      }}
                    >
                      BOKA HÄR
                    </Button>
                  </Grid>
                </Hidden>
                <Grid item>{shopping_cart_button}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
